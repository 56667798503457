import { driverAction } from "../actionType";

const initialState = {
  loader: false,
  vehicleInformationList: [],
  vehicleDropdownList: [],
  countryList: [],
  stateList: [],
  driverList: [],
  driverdDetailData: {},
};
export default function driver(state = initialState, { type, payload }) {
  switch (type) {
    case driverAction.VEHICLE_INFORMATION_INITIATE: {
      return {
        ...state,
        loader: true,
        vehicleInformationList: [],
      };
    }
    case driverAction.VEHICLE_INFORMATION_SUCCESS: {
      return {
        ...state,
        loader: false,
        vehicleInformationList: payload,
      };
    }
    case driverAction.VEHICLE_INFORMATION_FAILURE: {
      return {
        ...state,
        loader: false,
        vehicleInformationList: [],
      };
    }
    case driverAction.VEHICLE_DROPDOWN_INITIATE: {
      return {
        ...state,
        loader: true,
        vehicleDropdownList: [],
      };
    }
    case driverAction.VEHICLE_DROPDOWN_SUCCESS: {
      return {
        ...state,
        loader: false,
        vehicleDropdownList: payload,
      };
    }
    case driverAction.VEHICLE_DROPDOWNFAILURE: {
      return {
        ...state,
        loader: false,
        vehicleDropdownList: [],
      };
    }
    case driverAction.GET_COUNTRY_INITIATE: {
      return {
        ...state,
        loader: true,
        countryList: [],
      };
    }
    case driverAction.GET_COUNTRY_SUCCESS: {
      return {
        ...state,
        loader: false,
        countryList: payload,
      };
    }
    case driverAction.GET_COUNTRY_FAILURE: {
      return {
        ...state,
        loader: false,
        countryList: [],
      };
    }

    case driverAction.GET_STATE_INITIATE: {
      return {
        ...state,
        loader: true,
        stateList: [],
      };
    }
    case driverAction.GET_STATE_SUCCESS: {
      return {
        ...state,
        loader: false,
        stateList: payload,
      };
    }
    case driverAction.GET_STATE_FAILURE: {
      return {
        ...state,
        loader: false,
        stateList: [],
      };
    }
    case driverAction.DRIVER_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        driverList: [],
      };
    }
    case driverAction.DRIVER_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        driverList: payload,
      };
    }
    case driverAction.DRIVER_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        driverList: [],
      };
    }

    case driverAction.DRIVER_DETAIL_INITIATE: {
      return {
        ...state,
        loader: true,
        driverdDetailData: {},
      };
    }
    case driverAction.DRIVER_DETAIL_SUCCESS: {
      return {
        ...state,
        loader: false,
        driverdDetailData: payload,
      };
    }
    case driverAction.DRIVER_DETAIL_FAILURE: {
      return {
        ...state,
        loader: false,
        driverdDetailData: {},
      };
    }

    default:
      return state;
  }
}
