/* eslint-disable */

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import SchoolMenu from "../../component/SchoolMenu";
import SchoolTopRight from "../../component/SchoolTopRight";
import Button from "react-bootstrap/Button";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import BusBooking from "../../component/BusBooking";
import { useDispatch, useSelector } from "react-redux";
import { schoolVendorDetails } from "../../redux/action/schoolAction";
import ReactPaginate from "react-paginate";
// import Vehicle from "../../assets/images/vehicle-img.jpg";
// import { HiOutlineMail } from "react-icons/hi";
// import { FiPhoneCall } from "react-icons/fi";
// import { toast } from "react-toastify";

const BookVendor = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [rate, setRate] = useState("");
  // const [selectedPage, setSelectedPage] = useState(1)
  const [perPage, SetcurrentPage] = useState(10);

  const dispatch = useDispatch();
  const detailData = useSelector((state) => state.school);
  const { schoolVendorDetail, loader } = detailData;

  let vendorid = localStorage.getItem("vendorid");

  useEffect(() => {
    const getProfileData = async () => {
      try {
        await dispatch(schoolVendorDetails(vendorid, 1));
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getProfileData();
    // eslint-disable-next-line
  }, [schoolVendorDetails]);


  const handleShow = (id, rate) => {
    setRate(rate);
    setShow(true);
    localStorage.setItem("driver_vehicle_id", id);
  };

  const handlePageChange = async ({ selected }) => {
    let selectedPage = selected + 1;
    SetcurrentPage(10 * Number(selectedPage));
    await dispatch(schoolVendorDetails(vendorid, selectedPage));
  };
  return (
    <>
      <div className="dbCon">
        <SchoolMenu />
        <div className="dbRight">
          {/* <header className="dbHeader d-flex align-items-center justify-content-between">
            <div className="dbHd">
              <NavLink to="/vendors" className="me-1">
                <IoMdArrowRoundBack />
              </NavLink>{" "}
              Vendors
            </div>
            <SchoolTopRight />
          </header> */}
          <div>
            <div className="dbSubHd mb-4">{schoolVendorDetail?.data?.length > 0 ?schoolVendorDetail?.data[0]?.user?.full_name : ""}</div>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="mb-2 total">Total Vehicles: {schoolVendorDetail?.pagination_data?.total}</div>
              <div className="mb-2 vandorInfo">
                {/* <ul className="d-flex m-0 p-0">
                  <li>
                    <FiPhoneCall className="icon" /> 1800-213-222
                  </li>
                  <li>
                    <HiOutlineMail className="icon" /> contact@greybound.com
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="table m-0">
              <table>
                <tr>
                  <th>Sr.No.</th>
                  <th>Image</th>
                  <th>Vehicle Name</th>
                  <th>Vehicle License Plate</th>
                  <th>Seats</th>
                  <th>Availability</th>
                  <th>Fare Rate</th>
                  <th>Action</th>
                </tr>
                {loader !== false ?
                  <tr className="text-center">
                    <td colSpan="12">
                      <div class="spinner-border" role="status">
                        <span class="sr-only p-0"></span>
                      </div>
                    </td>
                  </tr>
                  : schoolVendorDetail?.data?.length > 0
                    ? schoolVendorDetail.data.filter((item) => item?.driver_vehicle[0]?.shift_startime)
                      .map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1 + (perPage - 10)}</td>
                          <td>
                            <img
                              src={`${process.env.REACT_APP_SERVER_URL}${item?.images[0]?.vehicle_image}`}
                              alt=""
                              className="vehicleImg"
                            />
                          </td>
                          <td>{item?.vechile_type?.type}</td>
                          <td className="text-nowrap">{item?.license_number}</td>
                          <td>{item?.seat}</td>
                          <td className="text-nowrap">
                            {`${item?.driver_vehicle[0]?.shift_startime} -
                            ${item?.driver_vehicle[0]?.shift_endtime}`}
                            {/* Mon - Fri l 10:00AM - 5:00PM */}
                          </td>
                          <td>
                            $
                            {item?.driver_vehicle[0]?.rate
                              ? item?.driver_vehicle[0]?.rate
                              : item?.fare_rate}
                            /hr
                          </td>
                          <td>
                            <Button
                              className="text-nowrap"
                              onClick={() =>
                                handleShow(
                                  item?.driver_vehicle[0]?.id,
                                  item?.driver_vehicle[0]?.rate ? item?.driver_vehicle[0]?.rate : item?.fare_rate
                                )
                              }
                            >
                              Book Now
                            </Button>
                          </td>
                        </tr>
                      ))
                    : <tr className="text-center">
                      <td colSpan="12">
                        <div>
                          <span>No vehicle found</span>
                        </div>
                      </td>
                    </tr>}
              </table>
            </div>

            <ReactPaginate
              pageCount={Math.ceil(
                schoolVendorDetail?.pagination_data?.total / 10
              )}
              onPageChange={handlePageChange}
              containerClassName={"paging m-0 p-0 pt-3 d-flex"}
              activeClassName={"active"}
              previousLabel={"Previous"}
              nextLabel={"Next"}
            />
          </div>
        </div>
      </div>

      <Modal show={show}>
        <Button className="closePopup" onClick={handleClose}>
          <IoCloseSharp />
        </Button>
        <BusBooking fareRate={rate} onClose={handleClose} />
      </Modal>
    </>
  );
};
export default BookVendor;
