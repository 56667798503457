import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import VendorMenu from "../component/VendorMenu";
import DashboardTopRight from "../component/DashboardTopRight";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import LatestTransactions from "../component/LatestTransactions";
import WithdrawTransactions from "../component/WithdrawTransactions";

const Transactions = () => {
  return (
    <>
      <div className="dbCon">
        <VendorMenu />
        <div className="dbRight">
          <div>
            {/* <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="LatestTransactions"
            >
              <Nav variant="pills" className="tabs mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="LatestTransactions">
                    Latest Transactions
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="WithdrawTransactions">
                    Withdraw Transactions
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="LatestTransactions">
                  <LatestTransactions />
                </Tab.Pane>
                <Tab.Pane eventKey="WithdrawTransactions">
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container> */}
                  <WithdrawTransactions />
          </div>
        </div>
      </div>
    </>
  );
};
export default Transactions;
