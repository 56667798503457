import React from 'react'
import { Navigate, useLocation } from "react-router-dom"
const ProtectedRouteSchool = ({ children, userType }) => {
    let location = useLocation();
    const token = localStorage.getItem("token")
    const localUserType = localStorage.getItem("user_type")
    if (token) {
        if (userType == localUserType) {
            return children
        }
        if (localUserType === "Vendor") {
            return <Navigate to="/dashboard" state={{ from: location }} replace />

        } else {

            return <Navigate to="/school-dashboard" state={{ from: location }} replace />
        }
    }
    return <Navigate to="/" state={{ from: location }} replace />

};

export default ProtectedRouteSchool;