import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import { addPayment } from "../redux/action/schoolAction";
import {toast} from "react-toastify"
const PaymentModal = ({ onClose }) => {
  const [paymentValue, setPaymentValue] = useState(0)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const detailData = useSelector((state) => state.school);
  const dispatch = useDispatch()
  const { paymentStatus, totalRate, pendingBalance, id } = detailData.schoolPaymentHistoryStatusData
  const pending = pendingBalance !== null ? pendingBalance : totalRate
  const addPaymentValue = (value) => {
    if (value > pending || value<0) {
      setPaymentValue(0)
      return
    }
    setPaymentValue(value)
  }
  const handleAddBtn = async () => {
    setIsSubmitLoading(true)
    const final = paymentStatus + parseInt(paymentValue)
    const payload = {
      payment_status: final,
      balance_payment: pending - paymentValue
    }
    try {
      const res = await dispatch(addPayment(payload, id))
      if (res) {
        setIsSubmitLoading(false)
        onClose()
      }
    } catch (err) {
      setIsSubmitLoading(false)
      toast.error("Something went worng")
    }
  }
  return (
    <>
      <div className="popupBox p-3 p-md-5">
        <div>
          <h2 className="text-center fpHd mb-3">Payment Status</h2>
          <ul className="form p-0 m-0 d-flex flex-wrap">
            <li className='col-12'>Status : ${paymentStatus}</li>
            <li className='col-12 col-sm-6'>Total Amount <br /> <span className="filedHd">${totalRate}</span></li>
            <li className='col-12 col-sm-6'>Pending Amount <br /> <span className="filedHd">${pendingBalance !== null ? pendingBalance - paymentValue : totalRate - paymentValue}</span></li>
            <li className='col-12'>
              <label className="filedHd">Paid Amount</label>
              <input value={paymentValue} type="number" placeholder="enter paid amount" onChange={(e) => addPaymentValue(e.target.value)} className="formInput" />
            </li>
            <li className='col-12'>
              <div className="pt-2">
                <Button onClick={handleAddBtn} className="smallBtn full">
                  {isSubmitLoading ? <div class="spinner-border" role="status">
                    <span class="sr-only p-0"></span>
                  </div> : "Add"}
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default PaymentModal;
