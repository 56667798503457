import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { BiSolidError } from "react-icons/bi";

const DeletePopup = ({ onClose }) => {
  const handleModalClose = () => {
    onClose();
  };

  return (
    <>
      <div className="popupBox p-3 p-md-5">
        <div>
          <div className="text-center mb-3 errorIcon">
            <BiSolidError />
          </div>
          <div className="text-center mb-4 deleteHd">
            Are you sure you want to delete?
          </div>
          <div className="text-center">
            <Button className="smallBtn cancel me-3" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button className="smallBtn auto">Delete</Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeletePopup;
