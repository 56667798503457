import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { IoMdCheckboxOutline } from "react-icons/io";

const DriverAdded = ({ onClose }) => {
    const handleModalClose = () => {
        onClose();
    };
    return (
        <>
            <div className='popupBox p-3 p-md-5'>
                <div>
                    <div className='text-center mb-3 checkIcon'><IoMdCheckboxOutline /></div>
                    <div className='text-center mb-3 deleteHd'>Driver Added Successfully!</div>
                    <div className='text-center mb-4'>New driver details have been added.</div>
                    <div className='text-center'>
                        <Button className='smallBtn cancel me-3' onClick={handleModalClose}>Close</Button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DriverAdded;