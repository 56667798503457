import { vehicleAction } from "../actionType";

const initialState = {
  loader: false,
  vehicleYearList: [],
  vehicleMakeList: [],
  vehicleModelList: [],
  vehicleTypeList: [],
  vehicleColorList: [],
  vehicleList: [],
  vehicledDetailData: {},
  bookingVehicleList: [],
  allBookingVehicleList:{}
};
export default function vehicle(state = initialState, { type, payload }) {
  switch (type) {
    case vehicleAction.VEHICLE_YEAR_INITIATE: {
      return {
        ...state,
        loader: true,
        vehicleYearList: [],
      };
    }
    case vehicleAction.VEHICLE_YEAR_SUCCESS: {
      return {
        ...state,
        loader: false,
        vehicleYearList: payload,
      };
    }
    case vehicleAction.VEHICLE_YEAR_FAILURE: {
      return {
        ...state,
        loader: false,
        vehicleYearList: [],
      };
    }

    case vehicleAction.VEHICLE_MAKE_INITIATE: {
      return {
        ...state,
        loader: true,
        vehicleMakeList: [],
      };
    }
    case vehicleAction.VEHICLE_MAKE_SUCCESS: {
      return {
        ...state,
        loader: false,
        vehicleMakeList: payload,
      };
    }
    case vehicleAction.VEHICLE_MAKE_FAILURE: {
      return {
        ...state,
        loader: false,
        vehicleMakeList: [],
      };
    }

    case vehicleAction.VEHICLE_MODEL_INITIATE: {
      return {
        ...state,
        loader: true,
        vehicleModelList: [],
      };
    }
    case vehicleAction.VEHICLE_MODEL_SUCCESS: {
      return {
        ...state,
        loader: false,
        vehicleModelList: payload,
      };
    }
    case vehicleAction.VEHICLE_MODEL_FAILURE: {
      return {
        ...state,
        loader: false,
        vehicleModelList: [],
      };
    }

    case vehicleAction.VEHICLE_TYPE_INITIATE: {
      return {
        ...state,
        loader: true,
        vehicleTypeList: [],
      };
    }
    case vehicleAction.VEHICLE_TYPE_SUCCESS: {
      return {
        ...state,
        loader: false,
        vehicleTypeList: payload,
      };
    }
    case vehicleAction.VEHICLE_TYPE_FAILURE: {
      return {
        ...state,
        loader: false,
        vehicleTypeList: [],
      };
    }

    case vehicleAction.VEHICLE_COLOR_INITIATE: {
      return {
        ...state,
        loader: true,
        vehicleColorList: [],
      };
    }
    case vehicleAction.VEHICLE_COLOR_SUCCESS: {
      return {
        ...state,
        loader: false,
        vehicleColorList: payload,
      };
    }
    case vehicleAction.VEHICLE_COLOR_FAILURE: {
      return {
        ...state,
        loader: false,
        vehicleColorList: [],
      };
    }

    case vehicleAction.VEHICLE_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        vehicleList: [],
      };
    }
    case vehicleAction.VEHICLE_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        vehicleList: payload,
      };
    }
    case vehicleAction.VEHICLE_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        vehicleList: [],
      };
    }

    case vehicleAction.VEHICLE_DETAIL_INITIATE: {
      return {
        ...state,
        loader: true,
        vehicledDetailData: {},
      };
    }
    case vehicleAction.VEHICLE_DETAIL_SUCCESS: {
      return {
        ...state,
        loader: false,
        vehicledDetailData: payload,
      };
    }
    case vehicleAction.VEHICLE_DETAIL_FAILURE: {
      return {
        ...state,
        loader: false,
        vehicledDetailData: {},
      };
    }

    case vehicleAction.BOOKING_VEHICLE_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        bookingVehicleList: [],
      };
    }
    case vehicleAction.BOOKING_VEHICLE_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        bookingVehicleList: payload,
      };
    }
    case vehicleAction.BOOKING_VEHICLE_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        bookingVehicleList: [],
      };
    }

    case vehicleAction.ALL_BOOKING_VEHICLE_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        allBookingVehicleList: {},
      };
    }
    case vehicleAction.ALL_BOOKING_VEHICLE_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        allBookingVehicleList: payload,
      };
    }
    case vehicleAction.ALL_BOOKING_VEHICLE_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        allBookingVehicleList: {},
      };
    }

    default:
      return state;
  }
}
