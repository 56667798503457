import React from 'react'
import DashboardTopRight from './DashboardTopRight'
import SchoolTopRight from "./SchoolTopRight"
import { useLocation } from 'react-router-dom';
const Header = () => {
    const location = useLocation();
    let path = location.pathname.split("/")[1].replace("/", "").replace("-", " ").replace("-", " ")
    if(location.pathname === "/my-school-reservations"){
        path="Reservations"
    }
    if (location.pathname === "/" || location.pathname === "/reset-password") {
        return ""
    } else {
        if (localStorage.getItem("user_type") === "Vendor") {
            return (
                <header className="dbHeader d-flex align-items-center justify-content-between">
                    <div className="dbHd text-capitalize">{path}</div>
                    <DashboardTopRight />
                </header>
            )
        }

        if (localStorage.getItem("user_type") === "School") {
            return (
                <header className="dbHeader d-flex align-items-center justify-content-between">
                    <div className="dbHd text-capitalize">{path}</div>
                    <SchoolTopRight />
                </header>
            )
        }
    }
}

export default Header