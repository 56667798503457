import { driverAction } from "../actionType";
import config from "../../config/config";
import { multiPartData } from "../../utils";
import axios from "axios";

//-----------------------------------Get Country ---------------------------------------//

export const getCountryInitiate = () => ({
  type: driverAction.GET_COUNTRY_INITIATE,
});

export const getCountrySuccess = (data) => ({
  type: driverAction.GET_COUNTRY_SUCCESS,
  payload: data,
});

export const getCountryFailure = () => ({
  type: driverAction.GET_COUNTRY_FAILURE,
});

export function getCountrys() {
  return (dispatch) => {
    dispatch(getCountryInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}vendor/get-country`, {
          headers: {},
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(getCountrySuccess(data));
          } else {
            dispatch(getCountryFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(getCountryFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Get State ---------------------------------------//


export const getStateInitiate = () => ({
  type: driverAction.GET_STATE_INITIATE,
});

export const getStateSuccess = (data) => ({
  type: driverAction.GET_STATE_SUCCESS,
  payload: data,
});

export const getStateFailure = () => ({
  type: driverAction.GET_STATE_FAILURE,
});

export function getStates(id) {
  return (dispatch) => {
    dispatch(getStateInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}vendor/get-state/${id}`, {
          headers: {},
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(getStateSuccess(data));
          } else {
            dispatch(getStateFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(getStateFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------vehicle Information ---------------------------------------//

export const vehicleInformationInitiate = () => ({
  type: driverAction.VEHICLE_INFORMATION_INITIATE,
});

export const vehicleInformationSuccess = (data) => ({
  type: driverAction.VEHICLE_INFORMATION_SUCCESS,
  payload: data,
});

export const vehicleInformationFailure = () => ({
  type: driverAction.VEHICLE_INFORMATION_FAILURE,
});

export function vehicleInformation(vehicle_status) {
  return (dispatch) => {
    dispatch(vehicleInformationInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(
          `${config.Url}vendor/vehicle-information?vehicle_status=${vehicle_status}&page=1`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(vehicleInformationSuccess(data));
          } else {
            dispatch(vehicleInformationFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(vehicleInformationFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------vehicle Information ---------------------------------------//

export const vehicleDropDownInitiate = () => ({
  type: driverAction.VEHICLE_DROPDOWN_INITIATE,
});

export const vehicleDropDownSuccess = (data) => ({
  type: driverAction.VEHICLE_DROPDOWN_SUCCESS,
  payload: data,
});

export const vehicleDropDownFailure = () => ({
  type: driverAction.VEHICLE_DROPDOWN_FAILURE,
});

export function vehicleDropDown(vehicle_status) {
  return (dispatch) => {
    dispatch(vehicleDropDownInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(
          `${config.Url}vendor/vehicle-dropdown-data?vehicle_status=${vehicle_status}`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(vehicleDropDownSuccess(data));
          } else {
            dispatch(vehicleDropDownFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(vehicleDropDownFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Add Driver---------------------------------------//

export const addDriverInitiate = () => ({
  type: driverAction.ADD_DRIVER_INITIATE,
});

export const addDriverSuccess = (data) => ({
  type: driverAction.ADD_DRIVER_SUCCESS,
  payload: data,
});

export const addDriverFailure = () => ({
  type: driverAction.ADD_DRIVER_FAILURE,
});

export function addDrivers(payload) {
  return (dispatch) => {
    dispatch(addDriverInitiate());
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}vendor/get-create-driver`, payload, {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(addDriverSuccess(data));
          } else {
            dispatch(addDriverFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(addDriverFailure(err));
          reject(err);
        })
    );
  };
}

//----------------------------------- Driver List ---------------------------------------//

export const driverListInitiate = () => ({
  type: driverAction.DRIVER_LIST_INITIATE,
});

export const driverListSuccess = (data) => ({
  type: driverAction.DRIVER_LIST_SUCCESS,
  payload: data,
});

export const driverListFailure = () => ({
  type: driverAction.DRIVER_LIST_FAILURE,
});

export function driverLists(payload) {
  return (dispatch) => {
    dispatch(driverListInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(
          `${config.Url}vendor/get-create-driver?search=${payload.search ? payload.search : ""
          }&shift_type=${payload.shift_type ? payload.shift_type : ""
          }&duty_status=${payload.duty_status ? payload.duty_status : ""
          }&page=${payload.page ? payload.page : 1}`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(driverListSuccess(data));
          } else {
            dispatch(driverListFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(driverListFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Driver Details ---------------------------------------//

export const driverDetailInitiate = () => ({
  type: driverAction.DRIVER_DETAIL_INITIATE,
});

export const driverDetailSuccess = (data) => ({
  type: driverAction.DRIVER_DETAIL_SUCCESS,
  payload: data,
});

export const driverDetailFailure = () => ({
  type: driverAction.DRIVER_DETAIL_FAILURE,
});

export function driverDetails(id) {
  return (dispatch) => {
    dispatch(driverDetailInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}vendor/driver-detail/${id}`, {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            // localStorage.setItem("assignedDriverId",data?.driver_assigned[0]?.id
            // )
            dispatch(driverDetailSuccess(data));
          } else {
            dispatch(driverDetailFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(driverDetailFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Edit Driver---------------------------------------//

export const editDriverInitiate = () => ({
  type: driverAction.EDIT_DRIVER_INITIATE,
});

export const editDriverSuccess = (data) => ({
  type: driverAction.EDIT_DRIVER_SUCCESS,
  payload: data,
});

export const editDriverFailure = () => ({
  type: driverAction.EDIT_DRIVER_FAILURE,
});

export function editDrivers(payload) {
  return (dispatch) => {
    dispatch(editDriverInitiate());
    return new Promise((resolve, reject) =>
      axios
        .patch(`${config.Url}vendor/driver-detail/${payload?.id}`, payload, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(editDriverSuccess(data));
          } else {
            dispatch(editDriverFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(editDriverFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Delete Driver---------------------------------------//

export const deleteDriverInitiate = () => ({
  type: driverAction.DELETE_DRIVER_INITIATE,
});

export const deleteDriverSuccess = (data) => ({
  type: driverAction.DELETE_DRIVER_SUCCESS,
  payload: data,
});

export const deleteDriverFailure = () => ({
  type: driverAction.DELETE_DRIVER_FAILURE,
});

export function deleteDrivers(id) {
  return (dispatch) => {
    dispatch(deleteDriverInitiate());
    return new Promise((resolve, reject) =>
      axios
        .delete(`${config.Url}vendor/driver-detail/${id}`, {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(deleteDriverSuccess(data));
          } else {
            dispatch(deleteDriverFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(deleteDriverFailure(err));
          reject(err);
        })
    );
  };
}
