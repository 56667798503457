import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SchoolMenu from "../../component/SchoolMenu";
import SchoolTopRight from "../../component/SchoolTopRight";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DeletePopup from "../../component/DeletePopup";
import { IoCloseSharp } from "react-icons/io5";
import { IoSearchSharp } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Fill } from "react-icons/ri";

const RegisteredVehicles = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="dbCon">
        <SchoolMenu />
        <div className="dbRight">
          {/* <header className="dbHeader d-flex align-items-center justify-content-between">
            <div className="dbHd">Registered Vehicles</div>
            <SchoolTopRight />
          </header> */}
          <div>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="dbSubHd mb-2">Total Vehicles: 20</div>
              <div className="mb-2 filterBox">
                <ul className="d-flex flex-wrap align-items-center p-0 transactionsFilter">
                  <li>
                    <div className="searchBox">
                      <input
                        type="text"
                        className="input"
                        placeholder="Search.."
                      />{" "}
                      <Button className="searchBtn">
                        <IoSearchSharp />
                      </Button>
                    </div>
                  </li>
                  <li>
                    <select className="smallSelect">
                      <option>Type</option>
                    </select>
                  </li>
                  <li>
                    <select className="smallSelect">
                      <option>Status</option>
                    </select>
                  </li>
                </ul>
              </div>
            </div>
            <div className="table m-0">
              <table>
                <tr>
                  <th>Sr.No.</th>
                  <th>Vehicles</th>
                  <th>Vehicle License Plate</th>
                  <th>Registration Date</th>
                  <th>Type</th>
                  <th>Assigned To</th>
                  <th>Capacity</th>
                  <th>Insurance</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>School Bus</td>
                  <td className="text-nowrap">EUS 687</td>
                  <td className="text-nowrap">01/01/2024</td>
                  <td>A/C</td>
                  <td>Mathew Norton</td>
                  <td>45</td>
                  <td>Yes</td>
                  <td>
                    <span className="status idle">Idle</span>
                  </td>
                  <td>
                    <div className="actionBtn d-flex">
                      <Button
                        title="Assign Driver"
                        className="rounded-circle iconBtn me-2"
                      >
                        <svg
                          id="Group_3073"
                          data-name="Group 3073"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.592"
                          height="22.592"
                          viewBox="0 0 22.592 22.592"
                        >
                          <path
                            id="calendar-check"
                            d="M17.886,1.883h-.941V.941a.941.941,0,0,0-1.883,0v.941H7.531V.941a.941.941,0,1,0-1.883,0v.941H4.707A4.712,4.712,0,0,0,0,6.589v11.3a4.712,4.712,0,0,0,4.707,4.707H17.886a4.712,4.712,0,0,0,4.707-4.707V6.589A4.712,4.712,0,0,0,17.886,1.883ZM4.707,3.765H17.886A2.827,2.827,0,0,1,20.71,6.589v.941H1.883V6.589A2.827,2.827,0,0,1,4.707,3.765ZM17.886,20.71H4.707a2.827,2.827,0,0,1-2.824-2.824V9.413H20.71v8.472A2.827,2.827,0,0,1,17.886,20.71Z"
                            fill="#727782"
                          />
                          <g id="refresh" transform="translate(6.776 10.498)">
                            <path
                              id="Path_1637"
                              data-name="Path 1637"
                              d="M3.591,4.063a3.425,3.425,0,0,1,5.615-2.1L8.764,2.4a.379.379,0,0,0,.268.647H10.77a.379.379,0,0,0,.379-.379V.931A.379.379,0,0,0,10.5.663l-.493.493A4.557,4.557,0,0,0,2.469,3.9a.567.567,0,1,0,1.122.166Z"
                              transform="translate(-2.461 0)"
                              fill="#727782"
                            />
                            <path
                              id="Path_1638"
                              data-name="Path 1638"
                              d="M30.356,256.891a.574.574,0,0,0-.563.5,3.425,3.425,0,0,1-5.615,2.1l.441-.441a.379.379,0,0,0-.268-.647H22.614a.379.379,0,0,0-.379.379v1.738a.379.379,0,0,0,.647.268l.493-.493a4.556,4.556,0,0,0,7.541-2.742.574.574,0,0,0-.474-.659A.555.555,0,0,0,30.356,256.891Z"
                              transform="translate(-21.884 -252.328)"
                              fill="#727782"
                            />
                          </g>
                        </svg>
                      </Button>
                      <Button title="Edit" className="rounded-circle me-2">
                        <FiEdit />
                      </Button>
                      <Button
                        title="Delete"
                        className="rounded-circle"
                        onClick={handleShow}
                      >
                        <RiDeleteBin6Fill />
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>School Bus</td>
                  <td className="text-nowrap">6TR J244</td>
                  <td className="text-nowrap">01/01/2024</td>
                  <td>Non-A/C</td>
                  <td>Maxwell Bartlett</td>
                  <td>50</td>
                  <td>No</td>
                  <td>
                    <span className="status running">Running</span>
                  </td>
                  <td>
                    <div className="actionBtn d-flex">
                      <Button
                        title="Assign Driver"
                        className="rounded-circle iconBtn me-2"
                      >
                        <svg
                          id="Group_3073"
                          data-name="Group 3073"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.592"
                          height="22.592"
                          viewBox="0 0 22.592 22.592"
                        >
                          <path
                            id="calendar-check"
                            d="M17.886,1.883h-.941V.941a.941.941,0,0,0-1.883,0v.941H7.531V.941a.941.941,0,1,0-1.883,0v.941H4.707A4.712,4.712,0,0,0,0,6.589v11.3a4.712,4.712,0,0,0,4.707,4.707H17.886a4.712,4.712,0,0,0,4.707-4.707V6.589A4.712,4.712,0,0,0,17.886,1.883ZM4.707,3.765H17.886A2.827,2.827,0,0,1,20.71,6.589v.941H1.883V6.589A2.827,2.827,0,0,1,4.707,3.765ZM17.886,20.71H4.707a2.827,2.827,0,0,1-2.824-2.824V9.413H20.71v8.472A2.827,2.827,0,0,1,17.886,20.71Z"
                            fill="#727782"
                          />
                          <g id="refresh" transform="translate(6.776 10.498)">
                            <path
                              id="Path_1637"
                              data-name="Path 1637"
                              d="M3.591,4.063a3.425,3.425,0,0,1,5.615-2.1L8.764,2.4a.379.379,0,0,0,.268.647H10.77a.379.379,0,0,0,.379-.379V.931A.379.379,0,0,0,10.5.663l-.493.493A4.557,4.557,0,0,0,2.469,3.9a.567.567,0,1,0,1.122.166Z"
                              transform="translate(-2.461 0)"
                              fill="#727782"
                            />
                            <path
                              id="Path_1638"
                              data-name="Path 1638"
                              d="M30.356,256.891a.574.574,0,0,0-.563.5,3.425,3.425,0,0,1-5.615,2.1l.441-.441a.379.379,0,0,0-.268-.647H22.614a.379.379,0,0,0-.379.379v1.738a.379.379,0,0,0,.647.268l.493-.493a4.556,4.556,0,0,0,7.541-2.742.574.574,0,0,0-.474-.659A.555.555,0,0,0,30.356,256.891Z"
                              transform="translate(-21.884 -252.328)"
                              fill="#727782"
                            />
                          </g>
                        </svg>
                      </Button>
                      <Button title="Edit" className="rounded-circle me-2">
                        <FiEdit />
                      </Button>
                      <Button
                        title="Delete"
                        className="rounded-circle"
                        onClick={handleShow}
                      >
                        <RiDeleteBin6Fill />
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>School Bus</td>
                  <td className="text-nowrap">ADL D21</td>
                  <td className="text-nowrap">01/01/2024</td>
                  <td>A/C</td>
                  <td>Julie Woodward</td>
                  <td>45</td>
                  <td>Yes</td>
                  <td>
                    <span className="status running">Running</span>
                  </td>
                  <td>
                    <div className="actionBtn d-flex">
                      <Button
                        title="Assign Driver"
                        className="rounded-circle iconBtn me-2"
                      >
                        <svg
                          id="Group_3073"
                          data-name="Group 3073"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.592"
                          height="22.592"
                          viewBox="0 0 22.592 22.592"
                        >
                          <path
                            id="calendar-check"
                            d="M17.886,1.883h-.941V.941a.941.941,0,0,0-1.883,0v.941H7.531V.941a.941.941,0,1,0-1.883,0v.941H4.707A4.712,4.712,0,0,0,0,6.589v11.3a4.712,4.712,0,0,0,4.707,4.707H17.886a4.712,4.712,0,0,0,4.707-4.707V6.589A4.712,4.712,0,0,0,17.886,1.883ZM4.707,3.765H17.886A2.827,2.827,0,0,1,20.71,6.589v.941H1.883V6.589A2.827,2.827,0,0,1,4.707,3.765ZM17.886,20.71H4.707a2.827,2.827,0,0,1-2.824-2.824V9.413H20.71v8.472A2.827,2.827,0,0,1,17.886,20.71Z"
                            fill="#727782"
                          />
                          <g id="refresh" transform="translate(6.776 10.498)">
                            <path
                              id="Path_1637"
                              data-name="Path 1637"
                              d="M3.591,4.063a3.425,3.425,0,0,1,5.615-2.1L8.764,2.4a.379.379,0,0,0,.268.647H10.77a.379.379,0,0,0,.379-.379V.931A.379.379,0,0,0,10.5.663l-.493.493A4.557,4.557,0,0,0,2.469,3.9a.567.567,0,1,0,1.122.166Z"
                              transform="translate(-2.461 0)"
                              fill="#727782"
                            />
                            <path
                              id="Path_1638"
                              data-name="Path 1638"
                              d="M30.356,256.891a.574.574,0,0,0-.563.5,3.425,3.425,0,0,1-5.615,2.1l.441-.441a.379.379,0,0,0-.268-.647H22.614a.379.379,0,0,0-.379.379v1.738a.379.379,0,0,0,.647.268l.493-.493a4.556,4.556,0,0,0,7.541-2.742.574.574,0,0,0-.474-.659A.555.555,0,0,0,30.356,256.891Z"
                              transform="translate(-21.884 -252.328)"
                              fill="#727782"
                            />
                          </g>
                        </svg>
                      </Button>
                      <Button title="Edit" className="rounded-circle me-2">
                        <FiEdit />
                      </Button>
                      <Button
                        title="Delete"
                        className="rounded-circle"
                        onClick={handleShow}
                      >
                        <RiDeleteBin6Fill />
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>School Bus</td>
                  <td className="text-nowrap">7AAXSB</td>
                  <td className="text-nowrap">01/01/2024</td>
                  <td>Non-A/C</td>
                  <td>Mariela Strong</td>
                  <td>50</td>
                  <td>Yes</td>
                  <td>
                    <span className="status idle">Idle</span>
                  </td>
                  <td>
                    <div className="actionBtn d-flex">
                      <Button
                        title="Assign Driver"
                        className="rounded-circle iconBtn me-2"
                      >
                        <svg
                          id="Group_3073"
                          data-name="Group 3073"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.592"
                          height="22.592"
                          viewBox="0 0 22.592 22.592"
                        >
                          <path
                            id="calendar-check"
                            d="M17.886,1.883h-.941V.941a.941.941,0,0,0-1.883,0v.941H7.531V.941a.941.941,0,1,0-1.883,0v.941H4.707A4.712,4.712,0,0,0,0,6.589v11.3a4.712,4.712,0,0,0,4.707,4.707H17.886a4.712,4.712,0,0,0,4.707-4.707V6.589A4.712,4.712,0,0,0,17.886,1.883ZM4.707,3.765H17.886A2.827,2.827,0,0,1,20.71,6.589v.941H1.883V6.589A2.827,2.827,0,0,1,4.707,3.765ZM17.886,20.71H4.707a2.827,2.827,0,0,1-2.824-2.824V9.413H20.71v8.472A2.827,2.827,0,0,1,17.886,20.71Z"
                            fill="#727782"
                          />
                          <g id="refresh" transform="translate(6.776 10.498)">
                            <path
                              id="Path_1637"
                              data-name="Path 1637"
                              d="M3.591,4.063a3.425,3.425,0,0,1,5.615-2.1L8.764,2.4a.379.379,0,0,0,.268.647H10.77a.379.379,0,0,0,.379-.379V.931A.379.379,0,0,0,10.5.663l-.493.493A4.557,4.557,0,0,0,2.469,3.9a.567.567,0,1,0,1.122.166Z"
                              transform="translate(-2.461 0)"
                              fill="#727782"
                            />
                            <path
                              id="Path_1638"
                              data-name="Path 1638"
                              d="M30.356,256.891a.574.574,0,0,0-.563.5,3.425,3.425,0,0,1-5.615,2.1l.441-.441a.379.379,0,0,0-.268-.647H22.614a.379.379,0,0,0-.379.379v1.738a.379.379,0,0,0,.647.268l.493-.493a4.556,4.556,0,0,0,7.541-2.742.574.574,0,0,0-.474-.659A.555.555,0,0,0,30.356,256.891Z"
                              transform="translate(-21.884 -252.328)"
                              fill="#727782"
                            />
                          </g>
                        </svg>
                      </Button>
                      <Button title="Edit" className="rounded-circle me-2">
                        <FiEdit />
                      </Button>
                      <Button
                        title="Delete"
                        className="rounded-circle"
                        onClick={handleShow}
                      >
                        <RiDeleteBin6Fill />
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>School Bus</td>
                  <td className="text-nowrap">EX 3333</td>
                  <td className="text-nowrap">01/01/2024</td>
                  <td>Non-A/C</td>
                  <td>Zander Dominguez</td>
                  <td>45</td>
                  <td>Yes</td>
                  <td>
                    <span className="status running">Running</span>
                  </td>
                  <td>
                    <div className="actionBtn d-flex">
                      <Button
                        title="Assign Driver"
                        className="rounded-circle iconBtn me-2"
                      >
                        <svg
                          id="Group_3073"
                          data-name="Group 3073"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.592"
                          height="22.592"
                          viewBox="0 0 22.592 22.592"
                        >
                          <path
                            id="calendar-check"
                            d="M17.886,1.883h-.941V.941a.941.941,0,0,0-1.883,0v.941H7.531V.941a.941.941,0,1,0-1.883,0v.941H4.707A4.712,4.712,0,0,0,0,6.589v11.3a4.712,4.712,0,0,0,4.707,4.707H17.886a4.712,4.712,0,0,0,4.707-4.707V6.589A4.712,4.712,0,0,0,17.886,1.883ZM4.707,3.765H17.886A2.827,2.827,0,0,1,20.71,6.589v.941H1.883V6.589A2.827,2.827,0,0,1,4.707,3.765ZM17.886,20.71H4.707a2.827,2.827,0,0,1-2.824-2.824V9.413H20.71v8.472A2.827,2.827,0,0,1,17.886,20.71Z"
                            fill="#727782"
                          />
                          <g id="refresh" transform="translate(6.776 10.498)">
                            <path
                              id="Path_1637"
                              data-name="Path 1637"
                              d="M3.591,4.063a3.425,3.425,0,0,1,5.615-2.1L8.764,2.4a.379.379,0,0,0,.268.647H10.77a.379.379,0,0,0,.379-.379V.931A.379.379,0,0,0,10.5.663l-.493.493A4.557,4.557,0,0,0,2.469,3.9a.567.567,0,1,0,1.122.166Z"
                              transform="translate(-2.461 0)"
                              fill="#727782"
                            />
                            <path
                              id="Path_1638"
                              data-name="Path 1638"
                              d="M30.356,256.891a.574.574,0,0,0-.563.5,3.425,3.425,0,0,1-5.615,2.1l.441-.441a.379.379,0,0,0-.268-.647H22.614a.379.379,0,0,0-.379.379v1.738a.379.379,0,0,0,.647.268l.493-.493a4.556,4.556,0,0,0,7.541-2.742.574.574,0,0,0-.474-.659A.555.555,0,0,0,30.356,256.891Z"
                              transform="translate(-21.884 -252.328)"
                              fill="#727782"
                            />
                          </g>
                        </svg>
                      </Button>
                      <Button title="Edit" className="rounded-circle me-2">
                        <FiEdit />
                      </Button>
                      <Button
                        title="Delete"
                        className="rounded-circle"
                        onClick={handleShow}
                      >
                        <RiDeleteBin6Fill />
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>School Bus</td>
                  <td className="text-nowrap">CGL 1288</td>
                  <td className="text-nowrap">01/01/2024</td>
                  <td>A/C</td>
                  <td>Caroline Cunningham</td>
                  <td>50</td>
                  <td>Yes</td>
                  <td>
                    <span className="status running">Running</span>
                  </td>
                  <td>
                    <div className="actionBtn d-flex">
                      <Button
                        title="Assign Driver"
                        className="rounded-circle iconBtn me-2"
                      >
                        <svg
                          id="Group_3073"
                          data-name="Group 3073"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.592"
                          height="22.592"
                          viewBox="0 0 22.592 22.592"
                        >
                          <path
                            id="calendar-check"
                            d="M17.886,1.883h-.941V.941a.941.941,0,0,0-1.883,0v.941H7.531V.941a.941.941,0,1,0-1.883,0v.941H4.707A4.712,4.712,0,0,0,0,6.589v11.3a4.712,4.712,0,0,0,4.707,4.707H17.886a4.712,4.712,0,0,0,4.707-4.707V6.589A4.712,4.712,0,0,0,17.886,1.883ZM4.707,3.765H17.886A2.827,2.827,0,0,1,20.71,6.589v.941H1.883V6.589A2.827,2.827,0,0,1,4.707,3.765ZM17.886,20.71H4.707a2.827,2.827,0,0,1-2.824-2.824V9.413H20.71v8.472A2.827,2.827,0,0,1,17.886,20.71Z"
                            fill="#727782"
                          />
                          <g id="refresh" transform="translate(6.776 10.498)">
                            <path
                              id="Path_1637"
                              data-name="Path 1637"
                              d="M3.591,4.063a3.425,3.425,0,0,1,5.615-2.1L8.764,2.4a.379.379,0,0,0,.268.647H10.77a.379.379,0,0,0,.379-.379V.931A.379.379,0,0,0,10.5.663l-.493.493A4.557,4.557,0,0,0,2.469,3.9a.567.567,0,1,0,1.122.166Z"
                              transform="translate(-2.461 0)"
                              fill="#727782"
                            />
                            <path
                              id="Path_1638"
                              data-name="Path 1638"
                              d="M30.356,256.891a.574.574,0,0,0-.563.5,3.425,3.425,0,0,1-5.615,2.1l.441-.441a.379.379,0,0,0-.268-.647H22.614a.379.379,0,0,0-.379.379v1.738a.379.379,0,0,0,.647.268l.493-.493a4.556,4.556,0,0,0,7.541-2.742.574.574,0,0,0-.474-.659A.555.555,0,0,0,30.356,256.891Z"
                              transform="translate(-21.884 -252.328)"
                              fill="#727782"
                            />
                          </g>
                        </svg>
                      </Button>
                      <Button title="Edit" className="rounded-circle me-2">
                        <FiEdit />
                      </Button>
                      <Button
                        title="Delete"
                        className="rounded-circle"
                        onClick={handleShow}
                      >
                        <RiDeleteBin6Fill />
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>School Bus</td>
                  <td className="text-nowrap">CDD 7066</td>
                  <td className="text-nowrap">01/01/2024</td>
                  <td>A/C</td>
                  <td>Matthew Petersen</td>
                  <td>45</td>
                  <td>Yes</td>
                  <td>
                    <span className="status running">Running</span>
                  </td>
                  <td>
                    <div className="actionBtn d-flex">
                      <Button
                        title="Assign Driver"
                        className="rounded-circle iconBtn me-2"
                      >
                        <svg
                          id="Group_3073"
                          data-name="Group 3073"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.592"
                          height="22.592"
                          viewBox="0 0 22.592 22.592"
                        >
                          <path
                            id="calendar-check"
                            d="M17.886,1.883h-.941V.941a.941.941,0,0,0-1.883,0v.941H7.531V.941a.941.941,0,1,0-1.883,0v.941H4.707A4.712,4.712,0,0,0,0,6.589v11.3a4.712,4.712,0,0,0,4.707,4.707H17.886a4.712,4.712,0,0,0,4.707-4.707V6.589A4.712,4.712,0,0,0,17.886,1.883ZM4.707,3.765H17.886A2.827,2.827,0,0,1,20.71,6.589v.941H1.883V6.589A2.827,2.827,0,0,1,4.707,3.765ZM17.886,20.71H4.707a2.827,2.827,0,0,1-2.824-2.824V9.413H20.71v8.472A2.827,2.827,0,0,1,17.886,20.71Z"
                            fill="#727782"
                          />
                          <g id="refresh" transform="translate(6.776 10.498)">
                            <path
                              id="Path_1637"
                              data-name="Path 1637"
                              d="M3.591,4.063a3.425,3.425,0,0,1,5.615-2.1L8.764,2.4a.379.379,0,0,0,.268.647H10.77a.379.379,0,0,0,.379-.379V.931A.379.379,0,0,0,10.5.663l-.493.493A4.557,4.557,0,0,0,2.469,3.9a.567.567,0,1,0,1.122.166Z"
                              transform="translate(-2.461 0)"
                              fill="#727782"
                            />
                            <path
                              id="Path_1638"
                              data-name="Path 1638"
                              d="M30.356,256.891a.574.574,0,0,0-.563.5,3.425,3.425,0,0,1-5.615,2.1l.441-.441a.379.379,0,0,0-.268-.647H22.614a.379.379,0,0,0-.379.379v1.738a.379.379,0,0,0,.647.268l.493-.493a4.556,4.556,0,0,0,7.541-2.742.574.574,0,0,0-.474-.659A.555.555,0,0,0,30.356,256.891Z"
                              transform="translate(-21.884 -252.328)"
                              fill="#727782"
                            />
                          </g>
                        </svg>
                      </Button>
                      <Button title="Edit" className="rounded-circle me-2">
                        <FiEdit />
                      </Button>
                      <Button
                        title="Delete"
                        className="rounded-circle"
                        onClick={handleShow}
                      >
                        <RiDeleteBin6Fill />
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>School Bus</td>
                  <td className="text-nowrap">MNG 728</td>
                  <td className="text-nowrap">01/01/2024</td>
                  <td>A/C</td>
                  <td>Skylar Yoder</td>
                  <td>50</td>
                  <td>No</td>
                  <td>
                    <span className="status idle">Idle</span>
                  </td>
                  <td>
                    <div className="actionBtn d-flex">
                      <Button
                        title="Assign Driver"
                        className="rounded-circle iconBtn me-2"
                      >
                        <svg
                          id="Group_3073"
                          data-name="Group 3073"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.592"
                          height="22.592"
                          viewBox="0 0 22.592 22.592"
                        >
                          <path
                            id="calendar-check"
                            d="M17.886,1.883h-.941V.941a.941.941,0,0,0-1.883,0v.941H7.531V.941a.941.941,0,1,0-1.883,0v.941H4.707A4.712,4.712,0,0,0,0,6.589v11.3a4.712,4.712,0,0,0,4.707,4.707H17.886a4.712,4.712,0,0,0,4.707-4.707V6.589A4.712,4.712,0,0,0,17.886,1.883ZM4.707,3.765H17.886A2.827,2.827,0,0,1,20.71,6.589v.941H1.883V6.589A2.827,2.827,0,0,1,4.707,3.765ZM17.886,20.71H4.707a2.827,2.827,0,0,1-2.824-2.824V9.413H20.71v8.472A2.827,2.827,0,0,1,17.886,20.71Z"
                            fill="#727782"
                          />
                          <g id="refresh" transform="translate(6.776 10.498)">
                            <path
                              id="Path_1637"
                              data-name="Path 1637"
                              d="M3.591,4.063a3.425,3.425,0,0,1,5.615-2.1L8.764,2.4a.379.379,0,0,0,.268.647H10.77a.379.379,0,0,0,.379-.379V.931A.379.379,0,0,0,10.5.663l-.493.493A4.557,4.557,0,0,0,2.469,3.9a.567.567,0,1,0,1.122.166Z"
                              transform="translate(-2.461 0)"
                              fill="#727782"
                            />
                            <path
                              id="Path_1638"
                              data-name="Path 1638"
                              d="M30.356,256.891a.574.574,0,0,0-.563.5,3.425,3.425,0,0,1-5.615,2.1l.441-.441a.379.379,0,0,0-.268-.647H22.614a.379.379,0,0,0-.379.379v1.738a.379.379,0,0,0,.647.268l.493-.493a4.556,4.556,0,0,0,7.541-2.742.574.574,0,0,0-.474-.659A.555.555,0,0,0,30.356,256.891Z"
                              transform="translate(-21.884 -252.328)"
                              fill="#727782"
                            />
                          </g>
                        </svg>
                      </Button>
                      <Button title="Edit" className="rounded-circle me-2">
                        <FiEdit />
                      </Button>
                      <Button
                        title="Delete"
                        className="rounded-circle"
                        onClick={handleShow}
                      >
                        <RiDeleteBin6Fill />
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>School Bus</td>
                  <td className="text-nowrap">CDD 7066</td>
                  <td className="text-nowrap">01/01/2024</td>
                  <td>A/C</td>
                  <td>Mckayla Pearson</td>
                  <td>45</td>
                  <td>No</td>
                  <td>
                    <span className="status running">Running</span>
                  </td>
                  <td>
                    <div className="actionBtn d-flex">
                      <Button
                        title="Assign Driver"
                        className="rounded-circle iconBtn me-2"
                      >
                        <svg
                          id="Group_3073"
                          data-name="Group 3073"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.592"
                          height="22.592"
                          viewBox="0 0 22.592 22.592"
                        >
                          <path
                            id="calendar-check"
                            d="M17.886,1.883h-.941V.941a.941.941,0,0,0-1.883,0v.941H7.531V.941a.941.941,0,1,0-1.883,0v.941H4.707A4.712,4.712,0,0,0,0,6.589v11.3a4.712,4.712,0,0,0,4.707,4.707H17.886a4.712,4.712,0,0,0,4.707-4.707V6.589A4.712,4.712,0,0,0,17.886,1.883ZM4.707,3.765H17.886A2.827,2.827,0,0,1,20.71,6.589v.941H1.883V6.589A2.827,2.827,0,0,1,4.707,3.765ZM17.886,20.71H4.707a2.827,2.827,0,0,1-2.824-2.824V9.413H20.71v8.472A2.827,2.827,0,0,1,17.886,20.71Z"
                            fill="#727782"
                          />
                          <g id="refresh" transform="translate(6.776 10.498)">
                            <path
                              id="Path_1637"
                              data-name="Path 1637"
                              d="M3.591,4.063a3.425,3.425,0,0,1,5.615-2.1L8.764,2.4a.379.379,0,0,0,.268.647H10.77a.379.379,0,0,0,.379-.379V.931A.379.379,0,0,0,10.5.663l-.493.493A4.557,4.557,0,0,0,2.469,3.9a.567.567,0,1,0,1.122.166Z"
                              transform="translate(-2.461 0)"
                              fill="#727782"
                            />
                            <path
                              id="Path_1638"
                              data-name="Path 1638"
                              d="M30.356,256.891a.574.574,0,0,0-.563.5,3.425,3.425,0,0,1-5.615,2.1l.441-.441a.379.379,0,0,0-.268-.647H22.614a.379.379,0,0,0-.379.379v1.738a.379.379,0,0,0,.647.268l.493-.493a4.556,4.556,0,0,0,7.541-2.742.574.574,0,0,0-.474-.659A.555.555,0,0,0,30.356,256.891Z"
                              transform="translate(-21.884 -252.328)"
                              fill="#727782"
                            />
                          </g>
                        </svg>
                      </Button>
                      <Button title="Edit" className="rounded-circle me-2">
                        <FiEdit />
                      </Button>
                      <Button
                        title="Delete"
                        className="rounded-circle"
                        onClick={handleShow}
                      >
                        <RiDeleteBin6Fill />
                      </Button>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <ul className="paging m-0 p-0 pt-3 d-flex">
              <li>
                <a>Pre</a>
              </li>
              <li className="active">
                <a>1</a>
              </li>
              <li>
                <a>2</a>
              </li>
              <li>
                <a>3</a>
              </li>
              <li>
                <a>Next</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal show={show}>
        <Button className="closePopup" onClick={handleClose}>
          <IoCloseSharp />
        </Button>
        <DeletePopup onClose={handleClose} />
      </Modal>
    </>
  );
};
export default RegisteredVehicles;
