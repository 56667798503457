import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import SchoolMenu from "../../component/SchoolMenu";
import SchoolTopRight from "../../component/SchoolTopRight";
import Button from "react-bootstrap/Button";
import VendorLogo1 from "../../assets/images/vendor-logo1.png";
import Dropdown from "react-bootstrap/Dropdown";
import { FiPhoneCall } from "react-icons/fi";
import { IoMailOutline } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import DeletePopup from "../../component/DeletePopup";
import EditVendor from "../../component/EditVendor";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { schoolvenodrLists } from "../../redux/action/schoolAction";
import { IoSearchSharp } from "react-icons/io5";
import { useDebounce } from 'use-debounce';
import ReactPaginate from "react-paginate";


const initialState = {
  page: 1,
  search: "",
}

const Vendors = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const dispatch = useDispatch();
  const detailData = useSelector((state) => state.school);
  const { schoolVendorList } = detailData;

  // const [search, setSearch] = useState('')
  const [perPage, SetcurrentPage] = useState(8);
  const [paramState, setParamState] = useState(initialState);
  const { page, search } = paramState;
  const [value] = useDebounce(search, 300);


  useEffect(() => {
    console.log(search)
    dispatch(schoolvenodrLists(paramState))
  }, [value])

  useEffect(() => {
    const getProfileData = async () => {
      try {
        await dispatch(schoolvenodrLists(paramState));
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getProfileData();
  }, [dispatch, schoolvenodrLists]);

  const schoolVendorDetail = (vendorid) => {
    localStorage.setItem("vendorid", vendorid);
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value
    setParamState({
      page,
      search: searchValue,
    });

  }



  const handlePageChange = async ({ selected }) => {
    let selectedPage = selected + 1;
    SetcurrentPage(8 * Number(selectedPage));
    let obj = { page: selectedPage, search: search };
    await dispatch(schoolvenodrLists(obj));
    setParamState({
      page: selectedPage,
      search
    })
  };

  return (
    <>
      <div className="dbCon">
        <SchoolMenu />
        <div className="dbRight">
          {/* <header className="dbHeader d-flex align-items-center justify-content-between">
            <div className="dbHd">Vendors</div>
            <SchoolTopRight />
          </header> */}

          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mb-2 total">
              Total Vendors: {schoolVendorList?.data?.length}
            </div>
            <div className="mb-2 filterBox">
              <ul className="d-flex flex-wrap align-items-center p-0 vehilcleFilter">
                <li>
                  <div className="searchBox">
                    <input
                      type="text"
                      className="input"
                      placeholder="Search by Name, State or City"
                      name="search"
                      onChange={handleSearch}
                    />{" "}
                    <Button className="searchBtn">
                      <IoSearchSharp />
                    </Button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <ul className="d-flex flex-wrap p-0 vendorsList">
              {schoolVendorList?.data?.length > 0
                ? schoolVendorList?.data?.map((item, i) => (
                  <li>
                    <div className="inner ps-4 pe-4">
                      <div className="logo d-flex align-items-center justify-content-between">
                        <img
                          src={
                            item.profile_image
                              ? `${process.env.REACT_APP_SERVER_URL}/${item.profile_image}`
                              : VendorLogo1
                          }
                          alt=""
                        />
                        {/* <Dropdown className="position-relative">
                            <Dropdown.Toggle className="ms-3 more">
                              <BsThreeDotsVertical />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <NavLink onClick={handleShow2}>Edit</NavLink>
                              <NavLink onClick={handleShow}>Delete</NavLink>
                            </Dropdown.Menu>
                          </Dropdown> */}
                      </div>
                      <div className="det pt-3 pb-3">
                        <div className="name">{`${item?.full_name}`}</div>
                        <div className="mt-3 d-flex align-items-center info">
                          <FiPhoneCall className="icon me-3" />
                          {item?.contact_number}
                        </div>
                        <div className="mt-3 d-flex align-items-center info">
                          <IoMailOutline className="icon me-3" />{" "}
                          {item?.email}
                        </div>
                        <div className="mt-3">
                          <NavLink
                            className="bookBtn"
                            to="/vendors/book-vendor"
                            onClick={() => schoolVendorDetail(item.id)}
                          >
                            View Details
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
                : ""}
            </ul>

            <ReactPaginate
              pageCount={Math.ceil(
                schoolVendorList?.pagination_data?.total / 10
              )}
              onPageChange={handlePageChange}
              containerClassName={"paging m-0 p-0 pt-3 d-flex"}
              activeClassName={"active"}
              previousLabel={"Previous"}
              nextLabel={"Next"}
            />

          </div>
        </div>
      </div>
      <div> </div>



      <Modal show={show}>
        <Button className="closePopup" onClick={handleClose}>
          <IoCloseSharp />
        </Button>
        <DeletePopup onClose={handleClose} />
      </Modal>
      <Modal show={show2}>
        <Button className="closePopup" onClick={handleClose2}>
          <IoCloseSharp />
        </Button>
        <EditVendor onClose={handleClose2} />
      </Modal>
    </>
  );
};
export default Vendors;
