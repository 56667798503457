import React, { useState, useEffect } from "react";
import SchoolMenu from "../../component/SchoolMenu";
import Button from "react-bootstrap/Button";
import SchoolTopRight from "../../component/SchoolTopRight";
import EditProfile from "../../component/EditProfile";
import EditBio from "../../component/EditBio";
import { IoCloseSharp } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import SchoolImg from "../../assets/images/school-img.jpg";
import { FaCamera } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import { IoMailOutline } from "react-icons/io5";
import { IoMdCheckboxOutline } from "react-icons/io";
import _ from "lodash";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  userProfileDetails,
  updateUserProfiles,
} from "../../redux/action/schoolAction";

const initialState = {
  full_name: "",
  company_name: "",
  profile_image: "",
  profile_image_review: "",
  bio: "",
  address: "",
  contact_number: "",
  email: '',
  errors: {},
};

const SchoolProfile = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const [visible, setVisible] = React.useState(false);
  const [visiblebio, setVisiblebio] = React.useState(false);
  const dispatch = useDispatch();
  const [isEditProfileLoading, setIsEditProfileLoading] = useState(false)
  const [isEditBioLoading, setIsEditBioLoading] = useState(false)
  const [iState, updateState] = useState(initialState);
  const detailData = useSelector((state) => state.school);
  const { schoolProfileDetailData } = detailData;
  const {
    full_name,
    company_name,
    profile_image,
    profile_image_review,
    bio,
    address,
    contact_number,
    email,
    errors,
  } = iState;

  const handleClose = () => {
    setShow(false);
    setVisiblebio(false)
  };
  const handleClose2 = () => {
    setShow2(false)
    setVisiblebio(false)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const fileHandler = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
      ) {
        previewsFile(file);
      } else {
        toast.error("Only formats are allowed: jpeg, jpg, png files", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const previewsFile = (file) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        updateState({
          ...iState,
          profile_image_review: reader.result,
          profile_image: file,
        });
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const getProfileData = async () => {
      try {
        await dispatch(userProfileDetails());
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getProfileData();
  }, [dispatch, userProfileDetails]);

  useEffect(() => {
    if (!_.isEmpty(schoolProfileDetailData)) {
      const updateData = _.cloneDeep(iState);
      updateData.profile_image_review =
        (schoolProfileDetailData &&
          schoolProfileDetailData[0]?.profile_image) ||
        "";
      updateData.full_name =
        (schoolProfileDetailData && schoolProfileDetailData[0]?.full_name) ||
        "";

      updateData.company_name =
        (schoolProfileDetailData && schoolProfileDetailData[0]?.company_name) ||
        "";

      updateData.email =
        (schoolProfileDetailData && schoolProfileDetailData[0]?.user?.email) ||
        "";
      updateData.bio =
        (schoolProfileDetailData && schoolProfileDetailData[0]?.bio) || "";
      updateData.address =
        (schoolProfileDetailData && schoolProfileDetailData[0]?.address) || "";
      updateData.contact_number =
        (schoolProfileDetailData &&
          schoolProfileDetailData[0]?.contact_number) ||
        "";
      updateState(updateData);
    }
  }, [schoolProfileDetailData]);

  let handleValidation = () => {
    let full_nameError = "";
    let company_nameError = "";
    let bioError = "";
    let addressError = "";
    let contact_numberError = "";
    let formIsValid = true;

    if (!full_name) {
      full_nameError = "Please enter name.";
      formIsValid = false;
    }
    if (!company_name) {
      company_nameError = "Please enter company name.";
      formIsValid = false;
    }
    // if (!bio) {
    //   bioError = "Please enter bio.";
    //   formIsValid = false;
    // }
    if (!address) {
      addressError = "Please enter address.";
      formIsValid = false;
    }

    if (!contact_number) {
      contact_numberError = "Please enter contact number.";
      formIsValid = false;
    }
    updateState({
      ...iState,
      errors: {
        full_nameError,
        company_nameError,
        bioError,
        addressError,
        contact_numberError,
      },
    });
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      setIsEditProfileLoading(true)
      const data = {};
      if (profile_image) {
        data.profile_image = profile_image;
      }
      data.full_name = full_name;
      data.company_name = company_name;
      data.address = address;
      data.contact_number = contact_number;
      try {
        const res = await dispatch(updateUserProfiles(data));
        if (res) {
          setIsEditProfileLoading(false)
          setVisible(!visible);
          dispatch(userProfileDetails());
        }
        updateState({
          ...iState,
          emailIdError: "",
          passwordError: "",
        });
      } catch (err) {
        setIsEditBioLoading(false)
        if (err.response && err.response.status === 400) {
          toast.error(err?.response?.data?.messsage, {
            position: toast?.POSITION?.TOP_RIGHT,
          });
        } else {
          console.log(`error>>>> ${err}`);
        }
      }
    }
  };

  const handleSubmitBio = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      setIsEditBioLoading(true)
      const data = {
        bio,
      };
      try {
        const res = await dispatch(updateUserProfiles(data));
        if (res) {
          setIsEditBioLoading(false)
          setVisiblebio(true);
        }
        updateState({
          ...iState,
          emailIdError: "",
          passwordError: "",
        });
      } catch (err) {
        setIsEditBioLoading(false)

        if (err.response && err.response.status === 400) {
          toast.error(err?.response?.data?.bio?.[0], {
            position: toast?.POSITION?.TOP_RIGHT,
          });
        } else {
          console.log(`error>>>> ${err}`);
        }
      }
    }
  };

  return (
    <>
      <div className="dbCon">
        <SchoolMenu />
        <div className="dbRight">
          <div className="d-flex flex-wrap align-items-start justify-content-between">
            <div className="whtBox p-3 profileLeft">
              <div className="profileImg schoolImg">
                <img
                  src={
                    profile_image
                      ? profile_image_review
                      : `${process.env.REACT_APP_SERVER_URL}${profile_image_review}`
                  }
                  alt=""
                />{" "}
                <span className="uploadPhoto">
                  <FaCamera />
                  <input
                    type="file"
                    className="file"
                    onChange={fileHandler}
                    onClick={(e) => e.target.value == null}
                    accept=".png, .jpg, .jpeg"
                  />
                </span>
              </div>
              <div className="dbSubHd pt-3 pb-3">{`${full_name}`}</div>
              <ul className="m-0 p-0 userInfo">
                <li className="mb-2 d-flex align-items-center">
                  <IoLocationOutline className="icon me-3" />
                  {address}
                </li>
                <li className="mb-2 d-flex align-items-center">
                  <FiPhoneCall className="icon me-3" /> {contact_number}
                </li>
                <li className="mb-2 d-flex align-items-center">
                  <IoMailOutline className="icon me-3" />{" "}
                  {email}
                </li>
              </ul>
              <div className="pt-3">
                <Button className="smallBtn small" onClick={handleShow}>
                  Edit Profile
                </Button>
              </div>
            </div>
            <div className="whtBox p-3 profileRight">
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="dbSubHd pb-3">Bio</div>
                <div className="pb-3">
                  <Button className="smallBtn small" onClick={handleShow2}>
                    Edit Bio
                  </Button>
                </div>
              </div>
              <div className="contentBox">
                <p>{bio}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show}>
        <Button className="closePopup" onClick={handleClose}>
          <IoCloseSharp />
        </Button>
        <div className="popupBox p-3 p-md-5">
          <div style={{ display: visible ? "none" : "block" }}>
            <h2 className="text-center fpHd mb-3">Edit Profile</h2>
            <ul className="form p-0 m-0">
              <li>
                <label className="filedHd">Full Name</label>
                <input
                  type="text"
                  className="formInput"
                  name="full_name"
                  value={full_name}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors.full_nameError}
                </span>
              </li>
              <li>
                <label className="filedHd">School Name</label>
                <input
                  type="text"
                  className="formInput"
                  name="company_name"
                  value={company_name}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors.company_nameError}
                </span>
              </li>
              <li>
                <label className="filedHd">Address</label>
                <input
                  type="text"
                  className="formInput"
                  name="address"
                  value={address}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors.addressError}
                </span>
              </li>
              <li>
                <label className="filedHd">Phone Number</label>
                <input
                  type="text"
                  className="formInput"
                  name="contact_number"
                  value={contact_number}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors.contact_numberError}
                </span>
              </li>
              <li>
                <label className="filedHd">Email</label>
                <input
                  type="text"
                  className="formInput"
                  name="contact_number"
                  value={email}
                  readOnly
                />
              </li>
              <li className="d-flex col-12">
                <div className="col-6 pe-2 pt-2">
                  <Button
                    className="smallBtn full cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="col-6 ps-2 pt-2">
                  <Button className="smallBtn full" onClick={handleSubmit}>
                    {isEditProfileLoading ? <div class="spinner-border" role="status">
                      <span class="sr-only p-0"></span>
                    </div> : "Update"}
                  </Button>
                </div>
              </li>
            </ul>
          </div>
          <div style={{ display: visible ? "block" : "none" }}>
            <div className="text-center mb-3 checkIcon">
              <IoMdCheckboxOutline />
            </div>
            <div className="text-center mb-3 deleteHd">
              Profile has been updated successfully!
            </div>
            <div className="text-center">
              <Button className="smallBtn cancel me-3" onClick={handleClose}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={show2}>
        <Button className="closePopup" onClick={handleClose2}>
          <IoCloseSharp />
        </Button>
        <div className="popupBox p-3 p-md-5">
          <div style={{ display: visiblebio ? "none" : "block" }}>
            <h2 className="text-center fpHd mb-3">EditBio</h2>
            <ul className="form p-0 m-0">
              <li>
                <textarea
                  rows="8"
                  cols="8"
                  className="formInput"
                  name="bio"
                  value={bio}
                  onChange={handleChange}
                ></textarea>
              </li>
              <li className="d-flex col-12">
                <div className="col-6 pe-2 pt-2">
                  <Button
                    className="smallBtn full cancel"
                    onClick={handleClose2}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="col-6 ps-2 pt-2">
                  <Button className="smallBtn full" onClick={handleSubmitBio}>
                    {isEditBioLoading ? <div class="spinner-border" role="status">
                      <span class="sr-only p-0"></span>
                    </div> : "Update"}
                  </Button>
                </div>
              </li>
            </ul>
          </div>
          <div style={{ display: visiblebio ? "block" : "none" }}>
            <div className="text-center mb-3 checkIcon">
              <IoMdCheckboxOutline />
            </div>
            <div className="text-center mb-3 deleteHd">
              Bio has been updated successfully!
            </div>
            <div className="text-center">
              <Button className="smallBtn cancel me-3" onClick={handleClose2}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default SchoolProfile;
