export const loginAction = {
  LOGIN_INITIATE: "LOGIN_INITIATE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  FORGET_PASSWORD_INITIATE: "FORGET_PASSWORD_INITIATE",
  FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
  FORGET_PASSWORD_FAILURE: "FORGET_PASSWORD_FAILURE",
  RESENDOTP_INITIATE: "RESENDOTP_INITIATE",
  RESENDOTP_SUCCESS: "RESENDOTP_SUCCESS",
  RESENDOTP_FAILURE: "RESENDOTP_FAILURE",
  RESET_PASSWORD_INITIATE: "RESET_PASSWORD_INITIATE",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  CHANGE_PASSWORD_INITIATE: "CHANGE_PASSWORD_INITIATE",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  CHANGE_PASSWORD_INITIATE: "CHANGE_PASSWORD_INITIATE",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  LOGOUT_INITIATE: "LOGOUT_INITIATE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
};

export const vendorAction = {
  GET_VENDOR_PROFILE_INITIATE: "GET_VENDOR_PROFILE_INITIATE",
  GET_VENDOR_PROFILE_SUCCESS: "GET_VENDOR_PROFILE_SUCCESS",
  GET_VENDOR_PROFILE_FAILURE: "GET_VENDOR_PROFILE_FAILURE",
  UPDATE_VENDOR_PROFILE_INITIATE: "UPDATE_VENDOR_PROFILE_INITIATE",
  UPDATE_VENDOR_PROFILE_SUCCESS: "UPDATE_VENDOR_PROFILE_SUCCESS",
  UPDATE_VENDOR_PROFILE_FAILURE: "UPDATE_VENDOR_PROFILE_FAILURE",
  GET_VENDOR_DASHBOARD_DATA_INITIATE: "GET_VENDOR_DASHBOARD_DATA_INITIATE",
  GET_VENDOR_DASHBOARD_DATA_SUCCESS: "GET_VENDOR_DASHBOARD_DATA_SUCCESS",
  GET_VENDOR_DASHBOARD_DATA_FAILURE: "GET_VENDOR_DASHBOARD_DATA_FAILURE",

  GET_VENDOR_NOTIFICATION_DATA_INITIATE: "GET_VENDOR_NOTIFICATION_DATA_INITIATE",
  GET_VENDOR_NOTIFICATION_DATA_SUCCESS: "GET_VENDOR_NOTIFICATION_DATA_SUCCESS",
  GET_VENDOR_NOTIFICATION_DATA_FAILURE: "GET_VENDOR_NOTIFICATION_DATA_FAILURE",
  VENDOR_BOOKING_VEHICLE_LIST_INITIATE: "VENDOR_BOOKING_VEHICLE_LIST_INITIATE",
  VENDOR_BOOKING_VEHICLE_LIST_SUCCESS: "VENDOR_BOOKING_VEHICLE_LIST_SUCCESS",
  VENDOR_BOOKING_VEHICLE_LIST_FAILURE: "VENDOR_BOOKING_VEHICLE_LIST_FAILURE",
  GET_VENDOR_PAYMENT_HISTORY_DATA_INITIATE:"GET_VENDOR_PAYMENT_HISTORY_DATA_INITIATE",
  GET_VENDOR_PAYMENT_HISTORY_DATA_SUCCESS:"GET_VENDOR_PAYMENT_HISTORY_DATA_SUCCESS",
  GET_VENDOR_PAYMENT_HISTORY_DATA_FAILURE:"GET_VENDOR_PAYMENT_HISTORY_DATA_FAILURE",
};

export const vehicleAction = {
  VEHICLE_YEAR_INITIATE: "VEHICLE_YEAR_INITIATE",
  VEHICLE_YEAR_SUCCESS: "VEHICLE_YEAR_SUCCESS",
  VEHICLE_YEAR_FAILURE: "VEHICLE_YEAR_FAILURE",
  VEHICLE_MAKE_INITIATE: " VEHICLE_MAKE_INITIATE",
  VEHICLE_MAKE_SUCCESS: " VEHICLE_MAKE_SUCCESS",
  VEHICLE_MAKE_FAILURE: " VEHICLE_MAKE_FAILURE",
  VEHICLE_MODEL_INITIATE: "VEHICLE_MODEL_INITIATE",
  VEHICLE_MODEL_SUCCESS: "VEHICLE_MODEL_SUCCESS",
  VEHICLE_MODEL_FAILURE: "VEHICLE_MODEL_FAILURE",
  VEHICLE_TYPE_INITIATE: "VEHICLE_TYPE_INITIATE",
  VEHICLE_TYPE_SUCCESS: "VEHICLE_TYPE_SUCCESS",
  VEHICLE_TYPE_FAILURE: "VEHICLE_TYPE_FAILURE",
  VEHICLE_COLOR_INITIATE: "VEHICLE_COLOR_INITIATE",
  VEHICLE_COLOR_SUCCESS: "VEHICLE_COLOR_SUCCESS",
  VEHICLE_COLOR_FAILURE: "VEHICLE_COLOR_FAILURE",
  ADD_VEHICLE_INITIATE: "ADD_VEHICLE_INITIATE",
  ADD_VEHICLE_SUCCESS: "ADD_VEHICLE_SUCCESS",
  ADD_VEHICLE_FAILURE: "ADD_VEHICLE_FAILURE",
  VEHICLE_LIST_INITIATE: "VEHICLE_LIST_INITIATE",
  VEHICLE_LIST_SUCCESS: "VEHICLE_LIST_SUCCESS",
  VEHICLE_LIST_FAILURE: "VEHICLE_LIST_FAILURE",
  VEHICLE_DETAIL_INITIATE: "VEHICLE_DETAIL_INITIATE",
  VEHICLE_DETAIL_SUCCESS: "VEHICLE_DETAIL_SUCCESS",
  VEHICLE_DETAIL_FAILURE: "VEHICLE_DETAIL_FAILURE",
  EDIT_VEHICLE_INITIATE: "EDIT_VEHICLE_INITIATE",
  EDIT_VEHICLE_SUCCESS: "EDIT_VEHICLE_SUCCESS",
  EDIT_VEHICLE_FAILURE: "EDIT_VEHICLE_FAILURE",
  DELETE_VEHICLE_INITIATE: "DELETE_VEHICLE_INITIATE",
  DELETE_VEHICLE_SUCCESS: "DELETE_VEHICLE_SUCCESS",
  DELETE_VEHICLE_FAILURE: "DELETE_VEHICLE_FAILURE",
  DELETE_VEHICLE_IMAGE_INITIATE: "DELETE_VEHICLE_IMAGE_INITIATE",
  DELETE_VEHICLE_IMAGE_SUCCESS: "DELETE_VEHICLE_IMAGE_SUCCESS",
  DELETE_VEHICLE_IMAGE_FAILURE: "DELETE_VEHICLE_IMAGE_FAILURE",
  BOOKING_VEHICLE_LIST_INITIATE: "BOOKING_VEHICLE_LIST_INITIATE",
  BOOKING_VEHICLE_LIST_SUCCESS: "BOOKING_VEHICLE_LIST_SUCCESS",
  BOOKING_VEHICLE_LIST_FAILURE: "BOOKING_VEHICLE_LIST_FAILURE",
  ALL_BOOKING_VEHICLE_LIST_INITIATE:"ALL_BOOKING_VEHICLE_LIST_INITIATE",
  ALL_BOOKING_VEHICLE_LIST_SUCCESS:"ALL_BOOKING_VEHICLE_LIST_SUCCESS",
  ALL_BOOKING_VEHICLE_LIST_FAILURE:"ALL_BOOKING_VEHICLE_LIST_FAILURE",
};

export const SchoolAction = {
  GET_USER_PROFILE_INITIATE: "GET_USER_PROFILE_INITIATE",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_FAILURE: "GET_USER_PROFILE_FAILURE",
  UPDATE_USER_PROFILE_INITIATE: "UPDATE_USER_PROFILE_INITIATE",
  UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_FAILURE: "UPDATE_USER_PROFILE_FAILURE",
  VENDOR_LIST_INITIATE: "VENDOR_LIST_INITIATE",
  VENDOR_LIST_SUCCESS: "VENDOR_LIST_SUCCESS",
  VENDOR_LIST_FAILURE: "VENDOR_LIST_FAILURE",
  SCHOOL_VENDOR_DETAIL_INITIATE: "SCHOOL_VENDOR_DETAIL_INITIATE",
  SCHOOL_VENDOR_DETAIL_SUCCESS: "SCHOOL_VENDOR_DETAIL_SUCCESS",
  SCHOOL_VENDOR_DETAIL_FAILURE: "SCHOOL_VENDOR_DETAIL_FAILURE",
  BOOK_VEHICLE_INITIATE: "BOOK_VEHICLE_INITIATE",
  BOOK_VEHICLE_SUCCESS: "BOOK_VEHICLE_SUCCESS",
  BOOK_VEHICLE_FAILURE: "BOOK_VEHICLE_FAILURE",
  RECURRING_BOOK_VEHICLE_INITIATE: "RECURRING_BOOK_VEHICLE_INITIATE",
  RECURRING_BOOK_VEHICLE_SUCCESS: "RECURRING_BOOK_VEHICLE_SUCCESS",
  RECURRING_BOOK_VEHICLE_FAILURE: "RECURRING_BOOK_VEHICLE_FAILURE",
  BOOKING_DETAIL_INITIATE: "BOOKING_DETAIL_INITIATE",
  BOOKING_DETAIL_SUCCESS: "BOOKING_DETAIL_SUCCESS",
  BOOKING_DETAIL_FAILURE: "BOOKING_DETAIL_FAILURE",
  BOOKING_VEHICLE_LIST_INITIATE: "BOOKING_VEHICLE_LIST_INITIATE",
  BOOKING_VEHICLE_LIST_SUCCESS: "BOOKING_VEHICLE_LIST_SUCCESS",
  BOOKING_VEHICLE_LIST_FAILURE: "BOOKING_VEHICLE_LIST_FAILURE",
  DELETE_BOOKING_VEHICLE_INITIATE: "DELETE_BOOKING_VEHICLE_INITIATE",
  DELETE_BOOKING_VEHICLE_SUCCESS: "DELETE_BOOKING_VEHICLE_SUCCESS",
  DELETE_BOOKING_VEHICLE_FAILURE: "DELETE_BOOKING_VEHICLE_FAILURE",
  GET_SCHOOL_DASHBOARD_DATA_INITIATE: "GET_SCHOOL_DASHBOARD_DATA_INITIATE",
  GET_SCHOOL_DASHBOARD_DATA_SUCCESS: "GET_SCHOOL_DASHBOARD_DATA_SUCCESS",
  GET_SCHOOL_DASHBOARD_DATA_FAILURE: "GET_SCHOOL_DASHBOARD_DATA_FAILURE",
  GET_SCHOOL_NOTIFICATION_DATA_INITIATE: "GET_SCHOOL_NOTIFICATION_INITIATE",
  GET_SCHOOL_NOTIFICATION_DATA_SUCCESS: "GET_SCHOOL_NOTIFICATION_SUCCESS",
  GET_SCHOOL_NOTIFICATION_DATA_FAILURE: "GET_SCHOOL_NOTIFICATION_FAILURE",
  GET_SCHOOL_PAYMENT_HISTORY_DATA_INITIATE: "GET_SCHOOL_PAYMENT_HISTORY_DATA_INITIATE",
  GET_SCHOOL_PAYMENT_HISTORY_DATA_SUCCESS: "GET_SCHOOL_PAYMENT_HISTORY_DATA_SUCCESS",
  GET_SCHOOL_PAYMENT_HISTORY_DATA_FAILURE: "GET_SCHOOL_PAYMENT_HISTORY_DATA_FAILURE",
  SET_SCHOOL_PAYMENT_HISTORY_DATA: "SET_SCHOOL_PAYMENT_HISTORY_DATA",
  ADD_PAYMENT_INITIATE: "ADD_PAYMENT_INITIATE",
  ADD_PAYMENT_SUCCESS: "ADD_PAYMENT_SUCCESS",
  ADD_PAYMENT_FAILURE: "ADD_PAYMENT_FAILURE",
};

export const driverAction = {
  GET_COUNTRY_INITIATE: "GET_COUNTRY_INITIATE",
  GET_COUNTRY_SUCCESS: "GET_COUNTRY_SUCCESS",
  GET_COUNTRY_FAILURE: "GET_COUNTRY_FAILURE",
  GET_STATE_INITIATE: " GET_STATE_INITIATE",
  GET_STATE_SUCCESS: " GET_STATE_SUCCESS",
  GET_STATE_FAILURE: " GET_STATE_FAILURE",
  VEHICLE_INFORMATION_INITIATE: "VEHICLE_INFORMATION_INITIATE",
  VEHICLE_INFORMATION_SUCCESS: "VEHICLE_INFORMATION_SUCCESS",
  VEHICLE_INFORMATION_FAILURE: "VEHICLE_INFORMATION_FAILURE",
  VEHICLE_DROPDOWN_INITIATE: "VEHICLE_DROPDOWN_INITIATE",
  VEHICLE_DROPDOWN_SUCCESS: "VEHICLE_DROPDOWN_SUCCESS",
  VEHICLE_DROPDOWN_FAILURE: "VEHICLE_DROPDOWN_FAILURE",
  ADD_DRIVER_INITIATE: "ADD_DRIVER_INITIATE",
  ADD_DRIVER_SUCCESS: "ADD_DRIVER_SUCCESS",
  ADD_DRIVER_FAILURE: "ADD_DRIVER_FAILURE",
  DRIVER_LIST_INITIATE: "DRIVER_LIST_INITIATE",
  DRIVER_LIST_SUCCESS: "DRIVER_LIST_SUCCESS",
  DRIVER_LIST_FAILURE: "DRIVER_LIST_FAILURE",
  DRIVER_DETAIL_INITIATE: "DRIVER_DETAIL_INITIATE",
  DRIVER_DETAIL_SUCCESS: "DRIVER_DETAIL_SUCCESS",
  DRIVER_DETAIL_FAILURE: "DRIVER_DETAIL_FAILURE",
  EDIT_DRIVER_INITIATE: "EDIT_DRIVER_INITIATE",
  EDIT_DRIVER_SUCCESS: "EDIT_DRIVER_SUCCESS",
  EDIT_DRIVER_FAILURE: "EDIT_DRIVER_FAILURE",
  DELETE_DRIVER_INITIATE: "DELETE_DRIVER_INITIATE",
  DELETE_DRIVER_SUCCESS: "DELETE_DRIVER_SUCCESS",
  DELETE_DRIVER_FAILURE: "DELETE_DRIVER_FAILURE",
};
