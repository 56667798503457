import React, { useState, useRef, useEffect } from 'react';
import "./DashboardTopRight.scss";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { IoMdNotificationsOutline } from "react-icons/io";
import UserImg from "../assets/images/user-img.png";
import { IoIosArrowDown } from "react-icons/io";
import Logo from "../assets/images/logo-icon.png";
import Dropdown from "react-bootstrap/Dropdown";
import { FaRegEdit } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { CiLogout } from "react-icons/ci";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { vendorProfileDetails, getVendorNotificationData } from "../redux/action/vendorAction";
import { logout } from "../redux/action/loginAction";
import { notificationUpdate } from '../utils/notificationUpdate';
import ReactPaginate from "react-paginate";
import userImage from "../assets/images/user.jpg"
import config from '../config/config';

const OutsideClickHandler = ({ children, onOutsideClick }) => {
  const wrapperRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onOutsideClick();
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onOutsideClick]);
  return <div ref={wrapperRef}>{children}</div>;
};

const DashboardTopRight = () => {

  const [isVisible, setIsVisible] = useState(false);
  const [perPage, SetcurrentPage] = useState(5);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const handleOutsideClick = () => {
    setIsVisible(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  const detailData = useSelector((state) => state.vendor);
  const { vendorProfileDetailData, vendorNotificationData } = detailData;

  // get notification data
  useEffect(() => {
    const obj = {
      page: 1
    }
    const getVendorNotificationDatas = async () => {
      try {
        await dispatch(getVendorNotificationData(obj));
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getVendorNotificationDatas();
  }, [dispatch, getVendorNotificationData]);

  useEffect(() => {
    const getProfileData = async () => {
      try {
        await dispatch(vendorProfileDetails());
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getProfileData();
  }, [dispatch, vendorProfileDetails]);

  const handleLogout = async () => {
    navigate("/");
    localStorage.removeItem("token")
    localStorage.removeItem("user_type")
    try {
      await dispatch(logout());
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(err?.response?.data?.messsage, {
          position: toast?.POSITION?.TOP_RIGHT,
        });
      } else {
        console.log(`error>>>> ${err}`);
      }
    }
  };

  const handleUpdateNotification = () => {
    notificationUpdate()
  }

  const handlePageChange = async ({ selected }) => {
    let selectedPage = selected + 1;
    SetcurrentPage(5 * Number(selectedPage));
    const obj = {
      page: selectedPage
    }
    await dispatch(getVendorNotificationData(obj));
  };

  const profileimage = !!vendorProfileDetailData[0]?.profile_image ? `${process.env.REACT_APP_SERVER_URL}${vendorProfileDetailData[0]?.profile_image}` : userImage

  const firstname = !!vendorProfileDetailData[0]?.full_name ? vendorProfileDetailData[0]?.full_name : "No Name"

  return (
    <>
      <div className="d-flex align-items-center justify-content-between dbheaderRight">
        <div className="dbLogoMob">
          <NavLink to="/dashboard">
            <img src={Logo} alt="" />
          </NavLink>
        </div>
        <div className="d-flex align-items-center">
          {location.pathname === "/vehicles" ? <NavLink
            to="/vehicles/add-vehicles"
            className="addVehicle me-3 d-flex align-items-center"
          >
            Add Vehicle
          </NavLink> : ""}

          {location.pathname === "/drivers" ? <NavLink
            to="/drivers/add-driver"
            className="addVehicle me-3 d-flex align-items-center"
          >
            Add Driver
          </NavLink> : ""}

          <div className='notificationBox' onClick={handleUpdateNotification}>
            <OutsideClickHandler onOutsideClick={handleOutsideClick}>
              <NavLink onClick={toggleVisibility} className='notification me-3 d-flex align-items-center justify-content-center rounded-circle'><IoMdNotificationsOutline />
                {vendorNotificationData?.notification_count > 0 ? (<span className='badge rounded-circle d-flex align-items-center justify-content-center'>{vendorNotificationData.notification_count}</span>) : ""}

              </NavLink>
              {isVisible && (
                <div className='notificationPoup'>
                  <ul className='p-0 m-0'>
                    {vendorNotificationData?.data?.length > 0 ?
                      vendorNotificationData.data.map((data) => {
                        return (
                          <NavLink to={data.type === "vehicle-registration" ? "/vehicles" : data.type === "Driver-Assigned" ? "/drivers" : data.type === "Booking" ? "/reservations" : data.type === "Payment" ? "/transactions" : ""} >

                            <li><a><span>{data.type}</span>{data.message}</a></li>
                          </NavLink>
                        )
                      })
                      :
                      <div className=' p-4 d-flex justify-content-center align-item-center'>

                        no notification found
                      </div>
                    }
                  </ul>
                  <div className='d-flex align-items-center justify-content-center mb-3' >

                    <ReactPaginate
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={0}
                      pageCount={Math.ceil(
                        vendorNotificationData?.pagination_data?.total / 5
                      )}
                      onPageChange={handlePageChange}
                      containerClassName={"paging m-0 p-0 pt-3 d-flex"}
                      activeClassName={"active"}
                      previousLabel={"<"}
                      nextLabel={">"}
                    />
                  </div>

                </div>

              )}
            </OutsideClickHandler>
          </div>
          <Dropdown className="acLinkBox position-relative">
            <Dropdown.Toggle className="acLink">
              <img
                src={profileimage}
                alt=""
                className="rounded-circle me-2"
              />{" "}
              <span>
                {`${firstname}`}
                <IoIosArrowDown className="ms-1" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <NavLink to="/profile" className="d-flex align-items-center">
                <FaRegEdit className="me-2 icon" /> My Profile
              </NavLink>
              <NavLink to="/settings" className="d-flex align-items-center">
                <IoMdSettings className="me-2 icon" /> Settings
              </NavLink>
              <NavLink
                className="d-flex align-items-center"
                onClick={handleLogout}
              >
                <CiLogout className="me-2 icon" /> Logout
              </NavLink>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};
export default DashboardTopRight;
