import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import VendorMenu from "../component/VendorMenu";
import DashboardTopRight from "../component/DashboardTopRight";
import Button from "react-bootstrap/Button";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoSearchSharp } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import DeletePopup from "../component/DeletePopup";
import { IoCloseSharp } from "react-icons/io5";
import { BiSolidError } from "react-icons/bi";
import DriverInfo from "../component/DriverInfo";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { driverLists, deleteDrivers } from "../redux/action/driverAction";
import { useDebounce } from 'use-debounce';

const initialState = {
  shift_type: "",
  duty_status: "",
  search: "",
  page: 1,
};

const Drivers = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [show2, setShow2] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const handleClose2 = () => setShow2(false);
  const [perPage, SetcurrentPage] = useState(10);
  const [iState, updateState] = useState(initialState);
  const { shift_type, duty_status, search, page } = iState;
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const listsData = useSelector((state) => state.driver);
  const { driverList, loader } = listsData;
  const [value] = useDebounce(search, 300);

  useEffect(() => {
    let obj = {
      shift_type: shift_type,
      duty_status: duty_status,
      search: search,
      page: page,
    };
    dispatch(driverLists(obj));
  }, [value])

  const handleSearch = (e) => {
    const { name, value } = e.target;
    updateState({
      search: value,
    });
  };

  const handleFilterShifttype = (e) => {
    const { name, value } = e.target;
    let obj = {
      shift_type: value,
      duty_status: duty_status,
      search: search,
      page: 1,
    };
    updateState({
      shift_type: value,
    });
    dispatch(driverLists(obj));
  };

  const handleFilterDutystatus = (e) => {
    const { name, value } = e.target;
    let obj = {
      shift_type: shift_type,
      duty_status: value,
      search: search,
      page: page,
    };
    updateState({
      duty_status: value,
    });
    dispatch(driverLists(obj));
  };

  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };

  const handleShow2 = (id) => {
    setShow2(true);
    setId(id);
  };

  useEffect(() => {
    const getCountryData = async () => {
      try {
        let obj = {
          shift_type: shift_type,
          duty_status: duty_status,
          search: search,
          page: page,
        };
        await dispatch(driverLists(obj));
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getCountryData();
  }, [dispatch, driverLists]);


  const handleDelte = async () => {
    setIsDeleteLoading(true)
    try {
      const res = await dispatch(deleteDrivers(id));
      if (res) {
        setIsDeleteLoading(false)

        toast.success("Driver Deleted Successfully!", {
          position: toast?.POSITION?.TOP_RIGHT,
        });
        setShow(false);
        let obj = {
          shift_type: shift_type,
          duty_status: duty_status,
          search: search,
          page: page,
        };
        dispatch(driverLists(obj));
      }
    } catch (err) {
      setIsDeleteLoading(false)

      if (err.response && err.response.status === 400) {
        toast.error(err?.response?.data?.messsage, {
          position: toast?.POSITION?.TOP_RIGHT,
        });
      } else {
        console.log(`error>>>> ${err}`);
      }
    }
  };

  const editDrivers = (item) => {
    localStorage.setItem("assignedDriverId",item?.driver_assigned[0]?.id)
    localStorage.setItem("driverId", item.id);
  };

  const handlePageChange = async ({ selected }) => {
    let selectedPage = selected + 1;
    SetcurrentPage(10 * Number(selectedPage));
    let obj = {
      shift_type: shift_type,
      duty_status: duty_status,
      search: search,
      page: selected + 1,
    };
    await dispatch(driverLists(obj));
    updateState({
      page: selectedPage,
    });
  };

  return (
    <>
      <div className="dbCon">
        <VendorMenu />
        <div className="dbRight">
          <div>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="mb-2 total">
                Total Drivers: {driverList?.pagination_data?.total}
              </div>
              <div className="mb-2 filterBox">
                <ul className="d-flex flex-wrap align-items-center p-0 vehilcleFilter">
                  <li>
                    <div className="searchBox">
                      <input
                        type="text"
                        className="input"
                        placeholder="Search.."
                        name="search"
                        value={search}
                        onChange={handleSearch}
                      />{" "}
                      <Button className="searchBtn">
                        <IoSearchSharp />
                      </Button>
                    </div>
                  </li>
                  <li>
                    <select
                      className="smallSelect"
                      name="shift_type"
                      value={shift_type}
                      onChange={handleFilterShifttype}
                    >
                      <option value="">Shift Type</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Biweekly">Biweekly</option>
                      <option value="Weekly">Weekly</option>
                      <option value="Daily">Daily</option>
                    </select>
                  </li>
                  <li>
                    <select
                      className="smallSelect"
                      name="shift_type"
                      value={duty_status}
                      onChange={handleFilterDutystatus}
                    >
                      <option value="">Duty Status</option>
                      <option value="On Route">On Route</option>
                      <option value="Available">Available</option>
                    </select>
                  </li>

                </ul>
              </div>
            </div>
            <div className="table m-0">
              <table>
                <tr>
                  <th>Sr.No.</th>
                  <th>Driver Name</th>
                  <th>Vehicle Type</th>
                  <th>Vehicle License Plate</th>
                  <th>Shift Time</th>
                  <th>Shift Type</th>
                  <th>Drive Time</th>
                  <th>Duty Status</th>
                  <th>Action</th>
                </tr>
                {loader !== false ?
                  <tr className="text-center">
                    <td colSpan="12">
                      <div class="spinner-border" role="status">
                        <span class="sr-only p-0"></span>
                      </div>
                    </td>
                  </tr>
                  : driverList?.data?.length > 0
                    ? driverList?.data?.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1 + (perPage - 10)}</td>
                        <td className="text-nowrap">
                          <NavLink onClick={() => handleShow2(item.id)}>
                            {item.name}
                          </NavLink>
                          <div className="mt-1 call">
                            Call: <span>+{item.phone}</span>
                          </div>
                        </td>
                        <td>
                          {
                            item?.driver_assigned[0]?.vehicle?.vechile_type
                              ?.type
                          }
                        </td>
                        <td>
                          {item?.driver_assigned[0]?.vehicle?.license_number}
                        </td>
                        <td className="text-nowrap">
                          {item?.driver_assigned[0]?.shift_startime}-{" "}
                          {item?.driver_assigned[0]?.shift_endtime}
                        </td>
                        <td>{item?.driver_assigned[0]?.shift_type}</td>
                        <td>{item?.driver_assigned[0]?.drive_time}</td>
                        <td>
                          <span
                            className={
                              item?.driver_assigned[0]?.duty_status ===
                                "On Route"
                                ? "status pickupCom"
                                : item?.driver_assigned[0]?.duty_status ===
                                  "Off Duty"
                                  ? "status offDuty"
                                  : "status unavailable"
                            }
                          >
                            {item?.driver_assigned[0]?.duty_status}
                          </span>
                        </td>
                        <td>
                          <div className="actionBtn d-flex">
                            <Button
                              title="Driver Info"
                              className="rounded-circle iconBtn me-2"
                              onClick={() => handleShow2(item.id)}
                            >
                              <svg
                                id="Group_3073"
                                data-name="Group 3073"
                                xmlns="http://www.w3.org/2000/svg"
                                width="22.592"
                                height="22.592"
                                viewBox="0 0 22.592 22.592"
                              >
                                <path
                                  id="calendar-check"
                                  d="M17.886,1.883h-.941V.941a.941.941,0,0,0-1.883,0v.941H7.531V.941a.941.941,0,1,0-1.883,0v.941H4.707A4.712,4.712,0,0,0,0,6.589v11.3a4.712,4.712,0,0,0,4.707,4.707H17.886a4.712,4.712,0,0,0,4.707-4.707V6.589A4.712,4.712,0,0,0,17.886,1.883ZM4.707,3.765H17.886A2.827,2.827,0,0,1,20.71,6.589v.941H1.883V6.589A2.827,2.827,0,0,1,4.707,3.765ZM17.886,20.71H4.707a2.827,2.827,0,0,1-2.824-2.824V9.413H20.71v8.472A2.827,2.827,0,0,1,17.886,20.71Z"
                                  fill="#727782"
                                />
                                <g
                                  id="refresh"
                                  transform="translate(6.776 10.498)"
                                >
                                  <path
                                    id="Path_1637"
                                    data-name="Path 1637"
                                    d="M3.591,4.063a3.425,3.425,0,0,1,5.615-2.1L8.764,2.4a.379.379,0,0,0,.268.647H10.77a.379.379,0,0,0,.379-.379V.931A.379.379,0,0,0,10.5.663l-.493.493A4.557,4.557,0,0,0,2.469,3.9a.567.567,0,1,0,1.122.166Z"
                                    transform="translate(-2.461 0)"
                                    fill="#727782"
                                  />
                                  <path
                                    id="Path_1638"
                                    data-name="Path 1638"
                                    d="M30.356,256.891a.574.574,0,0,0-.563.5,3.425,3.425,0,0,1-5.615,2.1l.441-.441a.379.379,0,0,0-.268-.647H22.614a.379.379,0,0,0-.379.379v1.738a.379.379,0,0,0,.647.268l.493-.493a4.556,4.556,0,0,0,7.541-2.742.574.574,0,0,0-.474-.659A.555.555,0,0,0,30.356,256.891Z"
                                    transform="translate(-21.884 -252.328)"
                                    fill="#727782"
                                  />
                                </g>
                              </svg>
                            </Button>
                            <NavLink to="/drivers/edit-driver" className="me-2">
                              <Button
                                title="Edit"
                                className="rounded-circle"
                                onClick={() => editDrivers(item)}
                              >
                                <FiEdit />
                              </Button>
                            </NavLink>
                            <Button
                              title="Delete"
                              className="rounded-circle"
                              onClick={() => handleShow(item.id)}
                            >
                              <RiDeleteBin6Fill />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))
                    : <tr className="text-center">
                      <td colSpan="12">
                        <div>
                          <span>No driver found</span>
                        </div>
                      </td>
                    </tr>}
              </table>
            </div>
            <ul className="paging m-0 p-0 pt-3 d-flex">
              <li>
                <ReactPaginate
                  pageCount={Math.ceil(driverList?.pagination_data?.total / 10)}
                  onPageChange={handlePageChange}
                  containerClassName={"paging m-0 p-0 pt-3 d-flex"}
                  // marginPagesDisplayed={2}
                  // pageRangeDisplayed={5}
                  activeClassName={"active"}
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                />
              </li>
              {/* <li className="active">
                <a>1</a>
              </li>
              <li>
                <a>2</a>
              </li>
              <li>
                <a>3</a>
              </li>
              <li>
                <a>Next</a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <Modal show={show}>
        <Button className="closePopup" onClick={handleClose}>
          <IoCloseSharp />
        </Button>
        <div className="popupBox p-3 p-md-5">
          <div>
            <div className="text-center mb-3 errorIcon">
              <BiSolidError />
            </div>
            <div className="text-center mb-4 deleteHd">
              Are you sure you want to delete?
            </div>
            <div className="text-center">
              <Button className="smallBtn cancel me-3" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="smallBtn auto" onClick={handleDelte}>
                {isDeleteLoading ? <div class="spinner-border" role="status">
                  <span class="sr-only p-0"></span>
                </div> : "Delete"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal className="driverInfoPopup" onClick={handleClose} show={show2}>
        <Button className="closePopup" onClick={handleClose2}>
          <IoCloseSharp />
        </Button>
        <DriverInfo id={id} />
      </Modal>
    </>
  );
};
export default Drivers;
