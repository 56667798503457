import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { IoMdCheckboxOutline } from "react-icons/io";

const AddBankDetails = ({ onClose }) => {
  const handleModalClose = () => {
    onClose();
  };
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <div className="popupBox p-3 p-md-5">
        <div style={{ display: visible ? "none" : "block" }}>
          <h2 className="text-center fpHd mb-3">Add Bank Details</h2>
          <ul className="form p-0 m-0 d-flex flex-wrap">
            <li className="col-12 col-sm-6">
              <label className="filedHd">Bank</label>
              <select className="formSelect">
                <option>Select</option>
              </select>
            </li>
            <li className="col-12 col-sm-6">
              <label className="filedHd">Account Holder Name</label>
              <input type="text" className="formInput" />
            </li>
            <li className="col-12 col-sm-6">
              <label className="filedHd">Routing Number (opt)</label>
              <input type="text" className="formInput" />
            </li>
            <li className="col-12 col-sm-6">
              <label className="filedHd">
                Bank/Branch Code (Transit Number)
              </label>
              <input type="text" className="formInput" />
            </li>
            <li className="col-12">
              <label className="filedHd">Account Number</label>
              <input type="text" className="formInput" />
            </li>
            <li className="d-flex col-12">
              <div className="col-6 pe-2 pt-2">
                <Button
                  className="smallBtn full cancel"
                  onClick={handleModalClose}
                >
                  Cancel
                </Button>
              </div>
              <div className="col-6 ps-2 pt-2">
                <Button
                  className="smallBtn full"
                  onClick={() => setVisible(!visible)}
                >
                  Submit
                </Button>
              </div>
            </li>
          </ul>
        </div>
        <div style={{ display: visible ? "block" : "none" }}>
          <div className="text-center mb-3 checkIcon">
            <IoMdCheckboxOutline />
          </div>
          <div className="text-center mb-3 deleteHd">
            Request Received Successfully!
          </div>
          <div className="text-center mb-4">
            Bank will be added to your Bank List within 24 hours.
          </div>
          <div className="text-center">
            <Button className="smallBtn cancel me-3" onClick={handleModalClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddBankDetails;
