import { SchoolAction } from "../actionType";
import config from "../../config/config";
import axios from "axios";
import { multiPartData } from "../../utils";
import moment from "moment";


//-----------------------------------Get School user Profile Details ---------------------------------------//

export const userProfileDetailInitiate = () => ({
  type: SchoolAction.GET_USER_PROFILE_INITIATE,
});

export const userProfileDetailSuccess = (data) => ({
  type: SchoolAction.GET_USER_PROFILE_SUCCESS,
  payload: data,
});

export const userProfileDetailFailure = () => ({
  type: SchoolAction.GET_USER_PROFILE_FAILURE,
});

export function userProfileDetails(id) {
  return (dispatch) => {
    dispatch(userProfileDetailInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}school/get-school-profile`, {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(userProfileDetailSuccess(data));
          } else {
            dispatch(userProfileDetailFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(userProfileDetailFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Update schoole User Profile---------------------------------------//

export const updateUserProfileInitiate = () => ({
  type: SchoolAction.UPDATE_USER_PROFILE_INITIATE,
});

export const updateUserProfileSuccess = (data) => ({
  type: SchoolAction.UPDATE_USER_PROFILE_SUCCESS,
  payload: data,
});

export const updateUserProfileFailure = () => ({
  type: SchoolAction.UPDATE_USER_PROFILE_FAILURE,
});

export function updateUserProfiles(payload) {
  const formData = multiPartData(payload);
  return (dispatch) => {
    dispatch(updateUserProfileInitiate());
    return new Promise((resolve, reject) =>
      axios
        .patch(`${config.Url}school/get-school-profile`, formData, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(updateUserProfileSuccess(data));
          } else {
            dispatch(updateUserProfileFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(updateUserProfileFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------School Vendors List ---------------------------------------//

export const venodrListInitiate = () => ({
  type: SchoolAction.VENDOR_LIST_INITIATE,
});

export const venodrListSuccess = (data) => ({
  type: SchoolAction.VENDOR_LIST_SUCCESS,
  payload: data,
});

export const venodrListFailure = () => ({
  type: SchoolAction.VENDOR_LIST_FAILURE,
});

export function schoolvenodrLists(obj) {
  const {search, page} = obj
  return (dispatch) => {
    dispatch(venodrListInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}vendor/school-vendor-profiles?search=${search.trim()}&page=${page}`, {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(venodrListSuccess(data));
          } else {
            dispatch(venodrListFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(venodrListFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------School Vendor Details ---------------------------------------//

export const schoolVendorDetailInitiate = () => ({
  type: SchoolAction.SCHOOL_VENDOR_DETAIL_INITIATE,
});

export const schoolVendorDetailSuccess = (data) => ({
  type: SchoolAction.SCHOOL_VENDOR_DETAIL_SUCCESS,
  payload: data,
});

export const schoolVendorDetailFailure = () => ({
  type: SchoolAction.SCHOOL_VENDOR_DETAIL_FAILURE,
});

export function schoolVendorDetails(id, page) {
  return (dispatch) => {
    dispatch(schoolVendorDetailInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}vendor/school-vendor-detail/${id}?page=${page}`, {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(schoolVendorDetailSuccess(data));
          } else {
            dispatch(schoolVendorDetailFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(schoolVendorDetailFailure(err));
          reject(err);
        })
    );
  };
}

//----------------------------------- BOOK VEHICLE---------------------------------------//

export const bookVehicleInitiate = () => ({
  type: SchoolAction.BOOK_VEHICLE_INITIATE,
});

export const bookVehicleSuccess = (data) => ({
  type: SchoolAction.BOOK_VEHICLE_SUCCESS,
  payload: data,
});

export const bookVehicleFailure = () => ({
  type: SchoolAction.BOOK_VEHICLE_FAILURE,
});

export function bookVehicles(payload) {
  const formData = new FormData();
  Object.keys(payload).map((item) => {
    let value = payload[item];
    if (item === "start_date") {
      const parsedDate = moment(value).format("YYYY-MM-DD")
      formData.append(item, parsedDate);
    } else {
      formData.append(item, value);
    }
  });

  return (dispatch) => {
    dispatch(bookVehicleInitiate());
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}school/book-vehicle`, formData, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(bookVehicleSuccess(data));
          } else {
            dispatch(bookVehicleFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(bookVehicleFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------RECURRING BOOK VEHICLE---------------------------------------//

export const recurringBookVehicleInitiate = () => ({
  type: SchoolAction.RECURRING_BOOK_VEHICLE_INITIATE,
});

export const recurringBookVehicleSuccess = (data) => ({
  type: SchoolAction.RECURRING_BOOK_VEHICLE_SUCCESS,
  payload: data,
});

export const recurringBookVehicleFailure = () => ({
  type: SchoolAction.RECURRING_BOOK_VEHICLE_FAILURE,
});

export function recurringBookVehicles(payload) {
  const formData = new FormData();
  Object.keys(payload).map((item) => {
    let value = payload[item];
    if (item === "start_date" || item === "end_date") {
      const parsedDate = moment(value).format("YYYY-MM-DD")
      formData.append(item, parsedDate);
    } else if (item === "weeks") {
      if (value.length > 0) {
        const arrayToString = value.join(',');
        formData.append(item, arrayToString);
      }
    } else {
      formData.append(item, value);
    }
  });
  return (dispatch) => {
    dispatch(recurringBookVehicleInitiate());
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}school/book-vehicle`, formData, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(recurringBookVehicleSuccess(data));
          } else {
            dispatch(recurringBookVehicleFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(recurringBookVehicleFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------GET BOOKING DETAILS---------------------------------------//

export const bookingDetailInitiate = () => ({
  type: SchoolAction.BOOKING_DETAIL_INITIATE,
});

export const bookingDetailSuccess = (data) => ({
  type: SchoolAction.BOOKING_DETAIL_SUCCESS,
  payload: data,
});

export const bookingDetailFailure = () => ({
  type: SchoolAction.BOOKING_DETAIL_FAILURE,
});

export function bookingDetails(id) {
  return (dispatch) => {
    dispatch(bookingDetailInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}school/get-booking-detail/${id}`, {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          const data = response?.data;
          if (response.status && response.status == 200) {
            dispatch(bookingDetailSuccess(data));
          } else {
            dispatch(bookingDetailFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(bookingDetailFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Booking vehicle List ---------------------------------------//

export const bookingVehicleListInitiate = () => ({
  type: SchoolAction.BOOKING_VEHICLE_LIST_INITIATE,
});

export const bookingVehicleListSuccess = (data) => ({
  type: SchoolAction.BOOKING_VEHICLE_LIST_SUCCESS,
  payload: data,
});

export const bookingVehicleListFailure = () => ({
  type: SchoolAction.BOOKING_VEHICLE_LIST_FAILURE,
});

export function bookingVehicleLists(payload) {
  return (dispatch) => {
    dispatch(bookingVehicleListInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(
          `${config.Url}school/book-vehicle?page=${payload.page ? payload.page : 1
          }&search=${payload.search ? payload.search : ""}&book_date=${payload.date ? payload.date : ""}`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(bookingVehicleListSuccess(data));
          } else {
            dispatch(bookingVehicleListFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(bookingVehicleListFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Delete Booking vehicle---------------------------------------//

export const deleteBookingVehicleInitiate = () => ({
  type: SchoolAction.DELETE_BOOKING_VEHICLE_INITIATE,
});

export const deleteBookingVehicleSuccess = (data) => ({
  type: SchoolAction.DELETE_BOOKING_VEHICLE_SUCCESS,
  payload: data,
});

export const deleteBookingVehicleFailure = () => ({
  type: SchoolAction.DELETE_BOOKING_VEHICLE_FAILURE,
});

export function deleteBookingVehicles(id) {
  return (dispatch) => {
    dispatch(deleteBookingVehicleInitiate());
    return new Promise((resolve, reject) =>
      axios
        .delete(`${config.Url}school/get-booking-detail/${id}`, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(deleteBookingVehicleSuccess(data));
          } else {
            dispatch(deleteBookingVehicleFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(deleteBookingVehicleFailure(err));
          reject(err);
        })
    );
  };
}


//-----------------------------------School Dashoard Data---------------------------------------//

export const getSchoolDashboardDataInitiate = () => ({
  type: SchoolAction.GET_SCHOOL_DASHBOARD_DATA_INITIATE,
});

export const getSchoolDashboardDataSuccess = (data) => ({
  type: SchoolAction.GET_SCHOOL_DASHBOARD_DATA_SUCCESS,
  payload: data,
});

export const getSchoolDashboardDataFailure = () => ({
  type: SchoolAction.GET_SCHOOL_DASHBOARD_DATA_FAILURE,
});

export function getSchoolDashboardData(obj) {
  const { page, search, status, year } = obj
  const month = obj.month < 10 ? `0${obj.month}` : obj.month.toString();
  // const day = obj.day=='' ? '' : (obj.day < 10 ? `0${obj.day}` : obj.day.toString());
  const url = `${config.Url}school/dashboard?page=${page}&search=${search}&status=${status}`
  const urlWithYearAndMonth = `${config.Url}school/dashboard?page=${page}&search=${search}&status=${status}&year=${year.toString()}&month=${month}`

  const urlParse = () => (year !== "" && month !== "" ) ? urlWithYearAndMonth : url
  return (dispatch) => {
    dispatch(getSchoolDashboardDataInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(urlParse(), {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(getSchoolDashboardDataSuccess(data));
          } else {
            dispatch(getSchoolDashboardDataFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(getSchoolDashboardDataFailure(err));
          reject(err);
        })
    );
  };
}


//-----------------------------------Notification Data---------------------------------------//

export const getSchoolNotificationDataInitiate = () => ({
  type: SchoolAction.GET_SCHOOL_NOTIFICATION_DATA_INITIATE,
});

export const getSchoolNotificationDataSuccess = (data) => ({
  type: SchoolAction.GET_SCHOOL_NOTIFICATION_DATA_SUCCESS,
  payload: data,
});

export const getSchoolNotificationDataFailure = () => ({
  type: SchoolAction.GET_SCHOOL_NOTIFICATION_DATA_FAILURE,
});

export function getSchoolNotificationData(obj) {
  const { page } = obj
  return (dispatch) => {
    dispatch(getSchoolNotificationDataInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}school/notification?page=${page}`, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(getSchoolNotificationDataSuccess(data));
          } else {
            dispatch(getSchoolNotificationDataFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(getSchoolNotificationDataFailure(err));
          reject(err);
        })
    );
  };
}



//-----------------------------------Payment History Data---------------------------------------//

export const getSchoolPaymentHistoryDataInitiate = () => ({
  type: SchoolAction.GET_SCHOOL_PAYMENT_HISTORY_DATA_INITIATE,
});

export const getSchoolPaymentHistoryDataSuccess = (data) => ({
  type: SchoolAction.GET_SCHOOL_PAYMENT_HISTORY_DATA_SUCCESS,
  payload: data,
});

export const getSchoolPaymentHistoryDataFailure = () => ({
  type: SchoolAction.GET_SCHOOL_PAYMENT_HISTORY_DATA_FAILURE,
});

export function getSchoolPaymentHistoryData(obj) {
  const { page } = obj
  return (dispatch) => {
    dispatch(getSchoolPaymentHistoryDataInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}school/payment-history?page=${page}`, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(getSchoolPaymentHistoryDataSuccess(data));
          } else {
            dispatch(getSchoolPaymentHistoryDataFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(getSchoolPaymentHistoryDataFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------set payment history Data---------------------------------------//
export const schoolPaymentHistoryData = (data) => ({
  type: SchoolAction.SET_SCHOOL_PAYMENT_HISTORY_DATA,
  payload: data,
});

export function setSchoolPaymentHistoryData(obj) {
  const { paymentStatus, pendingBalance, totalRate } = obj
  return (dispatch) => {
    dispatch(schoolPaymentHistoryData(obj));
  };
}

//-----------------------------------RECURRING BOOK VEHICLE---------------------------------------//

export const addPaymentInitiate = () => ({
  type: SchoolAction.ADD_PAYMENT_INITIATE,
});

export const addPaymentSuccess = (data) => ({
  type: SchoolAction.ADD_PAYMENT_SUCCESS,
  payload: data,
});

export const addPaymentFailure = () => ({
  type: SchoolAction.ADD_PAYMENT_FAILURE,
});

export function addPayment(payload, id) {
  return (dispatch) => {
    dispatch(addPaymentInitiate());
    return new Promise((resolve, reject) =>
      axios
        .patch(`${config.Url}school/add-payment-detail/${id}`, payload, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(addPaymentSuccess(data));
          } else {
            dispatch(recurringBookVehicleFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(addPaymentFailure(err));
          reject(err);
        })
    );
  };
}