import { vehicleAction } from "../actionType";
import config from "../../config/config";
import { multiPartData } from "../../utils";
import axios from "axios";

//-----------------------------------vehicle Year ---------------------------------------//

export const vehicleYearInitiate = () => ({
  type: vehicleAction.VEHICLE_YEAR_INITIATE,
});

export const vehicleYearSuccess = (data) => ({
  type: vehicleAction.VEHICLE_YEAR_SUCCESS,
  payload: data,
});

export const vehicleYearFailure = () => ({
  type: vehicleAction.VEHICLE_YEAR_FAILURE,
});

export function vehicleYear() {
  return (dispatch) => {
    dispatch(vehicleYearInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}vendor/vehicle-year`, {
          headers: {},
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(vehicleYearSuccess(data));
          } else {
            dispatch(vehicleYearFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(vehicleYearFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------vehicle Make ---------------------------------------//

export const vehicleMakeInitiate = () => ({
  type: vehicleAction.VEHICLE_MAKE_INITIATE,
});

export const vehicleMakeSuccess = (data) => ({
  type: vehicleAction.VEHICLE_MAKE_SUCCESS,
  payload: data,
});

export const vehicleMakeFailure = () => ({
  type: vehicleAction.VEHICLE_MAKE_FAILURE,
});

export function vehicleMake() {
  return (dispatch) => {
    dispatch(vehicleMakeInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}vendor/vehicle-make`, {
          headers: {},
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(vehicleMakeSuccess(data));
          } else {
            dispatch(vehicleMakeFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(vehicleMakeFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------vehicle Model ---------------------------------------//

export const vehicleModelInitiate = () => ({
  type: vehicleAction.VEHICLE_MODEL_INITIATE,
});

export const vehicleModelSuccess = (data) => ({
  type: vehicleAction.VEHICLE_MODEL_SUCCESS,
  payload: data,
});

export const vehicleModelFailure = () => ({
  type: vehicleAction.VEHICLE_MODEL_FAILURE,
});

export function vehicleModel(id) {
  return (dispatch) => {
    dispatch(vehicleModelInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}vendor/vehicle-model/${id}`, {
          headers: {},
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(vehicleModelSuccess(data));
          } else {
            dispatch(vehicleModelFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(vehicleModelFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------vehicle Type ---------------------------------------//

export const vehicleTypeInitiate = () => ({
  type: vehicleAction.VEHICLE_TYPE_INITIATE,
});

export const vehicleTypeSuccess = (data) => ({
  type: vehicleAction.VEHICLE_TYPE_SUCCESS,
  payload: data,
});

export const vehicleTypeFailure = () => ({
  type: vehicleAction.VEHICLE_TYPE_FAILURE,
});

export function vehicleType() {
  return (dispatch) => {
    dispatch(vehicleTypeInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}vendor/vehicle-type`, {
          headers: {},
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(vehicleTypeSuccess(data));
          } else {
            dispatch(vehicleTypeFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(vehicleTypeFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------vehicle color ---------------------------------------//

export const vehicleColorInitiate = () => ({
  type: vehicleAction.VEHICLE_COLOR_INITIATE,
});

export const vehicleColorSuccess = (data) => ({
  type: vehicleAction.VEHICLE_COLOR_SUCCESS,
  payload: data,
});

export const vehicleColorFailure = () => ({
  type: vehicleAction.VEHICLE_COLOR_FAILURE,
});

export function vehicleColor() {
  return (dispatch) => {
    dispatch(vehicleColorInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}vendor/vehicle-color`, {
          headers: {},
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(vehicleColorSuccess(data));
          } else {
            dispatch(vehicleColorFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(vehicleColorFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Add Vehicle---------------------------------------//

export const addVehicleInitiate = () => ({
  type: vehicleAction.ADD_VEHICLE_INITIATE,
});

export const addVehicleSuccess = (data) => ({
  type: vehicleAction.ADD_VEHICLE_SUCCESS,
  payload: data,
});

export const addVehicleFailure = () => ({
  type: vehicleAction.ADD_VEHICLE_FAILURE,
});

export function addVehicles(payload) {
  const formData = new FormData();
  Object.keys(payload).map((item) => {
    let value = payload[item];
    if (item == "images") {
      for (let i = 0; i < value.length; i++) {
        formData.append("images", value[i]);
      }
    } else {
      formData.append(item, value);
    }
  });
  return (dispatch) => {
    dispatch(addVehicleInitiate());
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}vendor/vehicle-information`, formData, {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(addVehicleSuccess(data));
          } else {
            dispatch(addVehicleFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(addVehicleFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------vehicle List ---------------------------------------//

export const vehicleListInitiate = () => ({
  type: vehicleAction.VEHICLE_LIST_INITIATE,
});

export const vehicleListSuccess = (data) => ({
  type: vehicleAction.VEHICLE_LIST_SUCCESS,
  payload: data,
});

export const vehicleListFailure = () => ({
  type: vehicleAction.VEHICLE_LIST_FAILURE,
});

export function vehicleLists(payload) {
  return (dispatch) => {
    dispatch(vehicleListInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(
          `${config.Url}vendor/vehicle-information?search=${payload.search ? payload.search : ""
          }&make=${payload.make ? payload.make : ""}&color=${payload.color ? payload.color : ""
          }&page=${payload.page ? payload.page : 1}`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(vehicleListSuccess(data));
          } else {
            dispatch(vehicleListFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(vehicleListFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------vehicle Details ---------------------------------------//

export const vehicleDetailInitiate = () => ({
  type: vehicleAction.VEHICLE_DETAIL_INITIATE,
});

export const vehicleDetailSuccess = (data) => ({
  type: vehicleAction.VEHICLE_DETAIL_SUCCESS,
  payload: data,
});

export const vehicleDetailFailure = () => ({
  type: vehicleAction.VEHICLE_DETAIL_FAILURE,
});

export function vehicleDetails(id) {
  return (dispatch) => {
    dispatch(vehicleDetailInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}vendor/get-vehicle-data/${id}`, {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(vehicleDetailSuccess(data));
          } else {
            dispatch(vehicleDetailFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(vehicleDetailFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Edit Vehicle---------------------------------------//

export const editVehicleInitiate = () => ({
  type: vehicleAction.EDIT_VEHICLE_INITIATE,
});

export const editVehicleSuccess = (data) => ({
  type: vehicleAction.EDIT_VEHICLE_SUCCESS,
  payload: data,
});

export const editVehicleFailure = () => ({
  type: vehicleAction.EDIT_VEHICLE_FAILURE,
});

export function editVehicles(payload, vehicleId) {
  const formData = new FormData();
  Object.keys(payload).map((item) => {
    let value = payload[item];
    if (item == "new_images") {
      for (let i = 0; i < value.length; i++) {
        formData.append("new_images", value[i]);
      }
    } else {
      formData.append(item, value);
    }
  });
  return (dispatch) => {
    dispatch(editVehicleInitiate());
    return new Promise((resolve, reject) =>
      axios
        .patch(
          `${config.Url}vendor/get-vehicle-data/${vehicleId}`,
          formData,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(editVehicleSuccess(data));
          } else {
            dispatch(editVehicleFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(editVehicleFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Delete Vehicle---------------------------------------//

export const deleteVehicleInitiate = () => ({
  type: vehicleAction.DELETE_VEHICLE_INITIATE,
});

export const deleteVehicleSuccess = (data) => ({
  type: vehicleAction.DELETE_VEHICLE_SUCCESS,
  payload: data,
});

export const deleteVehicleFailure = () => ({
  type: vehicleAction.DELETE_VEHICLE_FAILURE,
});

export function deleteVehicles(id) {
  return (dispatch) => {
    dispatch(deleteVehicleInitiate());
    return new Promise((resolve, reject) =>
      axios
        .delete(`${config.Url}vendor/get-vehicle-data/${id}`, {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(deleteVehicleSuccess(data));
          } else {
            dispatch(deleteVehicleFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(deleteVehicleFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Delete Vehicle Images---------------------------------------//

export const deleteVehicleImageInitiate = () => ({
  type: vehicleAction.DELETE_VEHICLE_IMAGE_INITIATE,
});

export const deleteVehicleImageSuccess = (data) => ({
  type: vehicleAction.DELETE_VEHICLE_IMAGE_SUCCESS,
  payload: data,
});

export const deleteVehicleImageFailure = () => ({
  type: vehicleAction.DELETE_VEHICLE_IMAGE_FAILURE,
});

export function deleteVehicleImages(id) {
  return (dispatch) => {
    dispatch(deleteVehicleImageInitiate());
    return new Promise((resolve, reject) =>
      axios
        .delete(`${config.Url}vendor/delete-image/${id}`, {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(deleteVehicleImageSuccess(data));
          } else {
            dispatch(deleteVehicleImageFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(deleteVehicleImageFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Booking vehicle List ---------------------------------------//

export const bookingVehicleListInitiate = () => ({
  type: vehicleAction.BOOKING_VEHICLE_LIST_INITIATE,
});

export const bookingVehicleListSuccess = (data) => ({
  type: vehicleAction.BOOKING_VEHICLE_LIST_SUCCESS,
  payload: data,
});

export const bookingVehicleListFailure = () => ({
  type: vehicleAction.BOOKING_VEHICLE_LIST_FAILURE,
});

export function bookingVehicleLists(payload) {
  return (dispatch) => {
    dispatch(bookingVehicleListInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(
          `${config.Url}vendor/get-bookings?page=${payload.page ? payload.page : 1
          }&search=${payload.search ? payload.search : ""}`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(bookingVehicleListSuccess(data));
          } else {
            dispatch(bookingVehicleListFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(bookingVehicleListFailure(err));
          reject(err);
        })
    );
  };
}


//-----------------------------------All vehicle List ---------------------------------------//

export const allBookingVehicleListInitiate = () => ({
  type: vehicleAction.ALL_BOOKING_VEHICLE_LIST_INITIATE,
});

export const allBookingVehicleListSuccess = (data) => ({
  type: vehicleAction.ALL_BOOKING_VEHICLE_LIST_SUCCESS,
  payload: data,
});

export const allBookingVehicleListFailure = () => ({
  type: vehicleAction.ALL_BOOKING_VEHICLE_LIST_FAILURE,
});

export function allBookingVehicleLists(payload) {
  return (dispatch) => {
    dispatch(allBookingVehicleListInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(
          `${config.Url}vendor/update-vehicle-dropdown-data/${payload}`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(allBookingVehicleListSuccess(data));
          } else {
            dispatch(allBookingVehicleListFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(allBookingVehicleListFailure(err));
          reject(err);
        })
    );
  };
}
