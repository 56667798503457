import { vendorAction } from "../actionType";
const initialState = {
  loader: false,
  vendorProfileDetailData: [],
  vendorDashboardData: {},
  vendorNotificationData: {},
  vendorBookingVehicleList: [],
  vendorPaymentHistoryData: {}
};
export default function vendor(state = initialState, { type, payload }) {
  switch (type) {
    case vendorAction.GET_VENDOR_PROFILE_INITIATE: {
      return {
        ...state,
        loader: true,
        vendorProfileDetailData: [],
      };
    }
    case vendorAction.GET_VENDOR_PROFILE_SUCCESS: {
      return {
        ...state,
        loader: false,
        vendorProfileDetailData: payload,
      };
    }
    case vendorAction.GET_VENDOR_PROFILE_FAILURE: {
      return {
        ...state,
        loader: false,
        vendorProfileDetailData: [],
      };
    }
    case vendorAction.GET_VENDOR_DASHBOARD_DATA_INITIATE: {
      return {
        ...state,
        loader: true,
        vendorDashboardData: {}
      }
    }
    case vendorAction.GET_VENDOR_DASHBOARD_DATA_SUCCESS: {
      return {
        ...state,
        loader: false,
        vendorDashboardData: payload
      }
    }
    case vendorAction.GET_VENDOR_DASHBOARD_DATA_FAILURE: {
      return {
        ...state,
        loader: false,
        vendorDashboardData: {}
      }
    }
    case vendorAction.GET_VENDOR_NOTIFICATION_DATA_INITIATE: {
      return {
        ...state,
        loader: true,
        vendorNotificationData: {},
      };
    }
    case vendorAction.GET_VENDOR_NOTIFICATION_DATA_SUCCESS: {
      return {
        ...state,
        loader: false,
        vendorNotificationData: payload,
      };
    }
    case vendorAction.GET_VENDOR_NOTIFICATION_DATA_FAILURE: {
      return {
        ...state,
        loader: false,
        vendorNotificationData: {},
      };
    }
    case vendorAction.VENDOR_BOOKING_VEHICLE_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        vendorBookingVehicleList: [],
      };
    }
    case vendorAction.VENDOR_BOOKING_VEHICLE_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        vendorBookingVehicleList: payload,
      };
    }
    case vendorAction.VENDOR_BOOKING_VEHICLE_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        vendorBookingVehicleList: [],
      };
    }
    case vendorAction.GET_VENDOR_PAYMENT_HISTORY_DATA_INITIATE: {
      return {
        ...state,
        loader: true,
        vendorPaymentHistoryData: {},
      };
    }
    case vendorAction.GET_VENDOR_PAYMENT_HISTORY_DATA_SUCCESS: {
      return {
        ...state,
        loader: false,
        vendorPaymentHistoryData: payload,
      };
    }
    case vendorAction.GET_VENDOR_PAYMENT_HISTORY_DATA_FAILURE: {
      return {
        ...state,
        loader: false,
        vendorPaymentHistoryData: {},
      };
    }

    default:
      return state;
  }
}
