import React, { useState, useEffect, useRef } from "react";
import "./DashboardTopRight.scss";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { IoMdNotificationsOutline } from "react-icons/io";
import { toast } from "react-toastify";
import { IoIosArrowDown } from "react-icons/io";
import Logo from "../assets/images/logo-icon.png";
import Dropdown from "react-bootstrap/Dropdown";
import { FaRegEdit } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { CiLogout } from "react-icons/ci";
import Modal from "react-bootstrap/Modal";
import AddVendor from "../component/AddVendor";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { userProfileDetails, getSchoolNotificationData } from "../redux/action/schoolAction";
import { logout } from "../redux/action/loginAction";
import { notificationUpdate } from '../utils/notificationUpdate';
import ReactPaginate from "react-paginate";
import userImage from "../assets/images/user.jpg"

const OutsideClickHandler = ({ children, onOutsideClick }) => {
  const wrapperRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onOutsideClick();
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onOutsideClick]);
  return <div ref={wrapperRef}>{children}</div>;
};


const DashboardTopRight = () => {
  const [show, setShow] = useState(false);
  const [perPage, SetcurrentPage] = useState(5);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const detailData = useSelector((state) => state.school);
  const { schoolProfileDetailData, schoolNotificationData } = detailData;
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation()
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const handleOutsideClick = () => {
    setIsVisible(false);
  };


  // get notification data
  useEffect(() => {
    const obj = {
      page: 1
    }
    const getSchoolNotificationDatas = async () => {
      try {
        await dispatch(getSchoolNotificationData(obj));
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getSchoolNotificationDatas();
  }, [dispatch, getSchoolNotificationData]);


  useEffect(() => {
    const getProfileData = async () => {
      try {
        await dispatch(userProfileDetails());
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getProfileData();
  }, [dispatch, userProfileDetails]);

  const handleLogout = async () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user_type")
    navigate("/");
    try {
      await dispatch(logout());
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(err?.response?.data?.messsage, {
          position: toast?.POSITION?.TOP_RIGHT,
        });
      } else {
        console.log(`error>>>> ${err}`);
      }
    }
  };

  const handleUpdateNotification = () => {
    notificationUpdate()
  }

  const handlePageChange = async ({ selected }) => {
    let selectedPage = selected + 1;
    SetcurrentPage(5 * Number(selectedPage));
    const obj = {
      page: selectedPage
    }
    await dispatch(getSchoolNotificationData(obj));
  };

  const profileimage = !!schoolProfileDetailData[0]?.profile_image ? `${process.env.REACT_APP_SERVER_URL}${schoolProfileDetailData[0]?.profile_image}` : userImage

  const firstname = !!schoolProfileDetailData[0]?.full_name ? schoolProfileDetailData[0]?.full_name : "No Name"

  return (
    <>
      <div className="d-flex align-items-center justify-content-between dbheaderRight">
        <div className="dbLogoMob">
          <NavLink to="/school-dashboard">
            <img src={Logo} alt="" />
          </NavLink>
        </div>

        <div className="d-flex align-items-center">
          {location.pathname === "/my-school-reservations" ? <NavLink
            to="/vendors"
            className="addVehicle me-3 d-flex align-items-center"
          >
            Reserve now
          </NavLink> : ""}

          <div className='notificationBox' onClick={handleUpdateNotification}>
            <OutsideClickHandler onOutsideClick={handleOutsideClick}>
              <NavLink onClick={toggleVisibility} className='notification me-3 d-flex align-items-center justify-content-center rounded-circle'><IoMdNotificationsOutline />
                {schoolNotificationData?.notification_count > 0 ? (<span className='badge rounded-circle d-flex align-items-center justify-content-center'>{schoolNotificationData.notification_count}</span>) : ""}

              </NavLink>
              {isVisible && (
                <div className='notificationPoup'>
                  <ul className='p-0 m-0'>
                    {schoolNotificationData?.data?.length > 0 ?
                      schoolNotificationData.data.map((data) => {
                        return (
                          <NavLink to={`${data.type === "Booking" ? "/my-school-reservations" : data.type === "Payment" ? "/payment-history" : ""}`}>
                            <li><a><span>{data.type}</span>{data.message}</a></li>
                          </NavLink>
                        )
                      })
                      :
                      <div className=' p-4 d-flex justify-content-center align-item-center'>

                        no notification found
                      </div>
                    }
                  </ul>
                  <div className='d-flex align-items-center justify-content-center mb-3' >

                    <ReactPaginate
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={0}
                      pageCount={Math.ceil(
                        schoolNotificationData?.pagination_data?.total / 5
                      )}
                      onPageChange={handlePageChange}
                      containerClassName={"paging m-0 p-0 pt-3 d-flex"}
                      activeClassName={"active"}
                      previousLabel={"<"}
                      nextLabel={">"}
                    />
                  </div>
                </div>
              )}
            </OutsideClickHandler>
          </div>


          <Dropdown className="acLinkBox position-relative">
            <Dropdown.Toggle className="acLink">
              <img
                src={profileimage}
                alt=""
                className="rounded-circle me-2"
              />{" "}
              <span>
                {`${firstname}`}
                <IoIosArrowDown className="ms-1" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <NavLink
                to="/school-profile"
                className="d-flex align-items-center"
              >
                <FaRegEdit className="me-2 icon" /> My Profile
              </NavLink>
              <NavLink
                to="/school-settings"
                className="d-flex align-items-center"
              >
                <IoMdSettings className="me-2 icon" /> Settings
              </NavLink>
              <NavLink
                className="d-flex align-items-center"
                onClick={handleLogout}
              >
                <CiLogout className="me-2 icon" /> Logout
              </NavLink>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <Modal show={show}>
        <Button className="closePopup" onClick={handleClose}>
          <IoCloseSharp />
        </Button>
        <AddVendor onClose={handleClose} />
      </Modal>
    </>
  );
};
export default DashboardTopRight;
