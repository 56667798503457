import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { IoMdCheckboxOutline } from "react-icons/io";
import { FaCamera } from "react-icons/fa";

const AddVendor = ({ onClose }) => {
  const handleModalClose = () => {
    onClose();
  };
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <div className="popupBox p-3 p-md-5">
        <div style={{ display: visible ? "none" : "block" }}>
          <h2 className="text-center fpHd mb-3">Add Vendor</h2>
          <ul className="form p-0 m-0">
            <li>
              <div className="uploadVedorLogo d-flex align-items-center justify-content-center">
                <div>
                  <div className="text">
                    <span className="icon">
                      <FaCamera />
                    </span>{" "}
                    Logo or Image
                  </div>
                  <input type="file" className="file" />
                </div>
              </div>
            </li>
            <li>
              <label className="filedHd">Name</label>
              <input type="text" className="formInput" />
            </li>
            <li>
              <label className="filedHd">Email Address</label>
              <input type="text" className="formInput" />
            </li>
            <li>
              <label className="filedHd">Phone Number</label>
              <input type="text" className="formInput" />
            </li>
            <li className="d-flex">
              <div className="col-6 pe-2 pt-2">
                <Button
                  className="smallBtn full cancel"
                  onClick={handleModalClose}
                >
                  Cancel
                </Button>
              </div>
              <div className="col-6 ps-2 pt-2">
                <Button
                  className="smallBtn full"
                  onClick={() => setVisible(!visible)}
                >
                  Submit
                </Button>
              </div>
            </li>
          </ul>
        </div>
        <div style={{ display: visible ? "block" : "none" }}>
          <div className="text-center mb-3 checkIcon">
            <IoMdCheckboxOutline />
          </div>
          <div className="text-center mb-3 deleteHd">
            Vendor Added Successfully!
          </div>
          <div className="text-center mb-4">
            New vendor details have been added.
          </div>
          <div className="text-center">
            <Button className="smallBtn cancel me-3" onClick={handleModalClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddVendor;
