import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import SchoolMenu from "../../component/SchoolMenu";
import SchoolTopRight from "../../component/SchoolTopRight";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { MdPayments } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import PaymentModal from "../../component/PaymentModal";
import Modal from "react-bootstrap/Modal";
import { getSchoolPaymentHistoryData, setSchoolPaymentHistoryData } from "../../redux/action/schoolAction";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import moment from "moment";

const initialState = {
  page: 1,
};
const PaymentHistory = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [perPage, SetcurrentPage] = useState(10);
  const handleClose = () => setShow(false);
  const [iState, updateState] = useState(initialState);
  const { page } = iState;
  const dispatch = useDispatch()
  const detailData = useSelector((state) => state.school);
  const { schoolPaymentHistoryData, loader } = detailData;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let obj = { page: page };
        await dispatch(getSchoolPaymentHistoryData(obj));
      } catch (error) {
        console.error("Error fetching vehicle lists:", error);
      }
    };
    fetchData();
    SetcurrentPage(10 * Number(page));
  }, [dispatch, show]);

  const handlePageChange = async ({ selected }) => {
    let selectedPage = selected + 1;
    SetcurrentPage(10 * Number(selectedPage));
    let obj = { page: selectedPage };
    await dispatch(getSchoolPaymentHistoryData(obj));
    updateState({
      page: selectedPage,
    });
  };

  const handleShow = (data) => {
    setShow(true);
    dispatch(setSchoolPaymentHistoryData(data))
  }


  return (
    <>
      <div className="dbCon">
        <SchoolMenu />
        <div className="dbRight">
          <div>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="dbSubHd mb-2">Invoices: {schoolPaymentHistoryData?.pagination_data?.total}</div>
              <div className="mb-2 filterBox">
                <ul className="d-flex flex-wrap align-items-center p-0 transactionsFilter">
                  {/* <li className="date">
                    <div className="smallDatepicker position-relative">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />{" "}
                      <FaCalendarAlt className="icon" />
                    </div>
                  </li>
                  <li>
                    <select className="smallSelect">
                      <option>Transfer</option>
                    </select>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="table m-0">
              <table>
                <tr>
                  <th>Sr.No.</th>
                  <th>Vendors</th>
                  <th>Type</th>
                  <th>Transfer Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Balance</th>
                  <th>Action</th>
                </tr>

                {loader !== false ?
                  <tr className="text-center">
                    <td colSpan="12">
                      <div class="spinner-border" role="status">
                        <span class="sr-only p-0"></span>
                      </div>
                    </td>
                  </tr> : schoolPaymentHistoryData?.data?.length > 0
                    ? schoolPaymentHistoryData?.data?.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1 + (perPage - 10)}</td>
                        <td class="text-nowrap">
                          <div>{`${item?.vehicle?.vehicle?.user?.full_name}`}</div>
                          <div class="mt-1 call">
                            Call: <span>{item?.vehicle?.vehicle?.user?.contact_number}</span>
                          </div>
                        </td>
                        <td className="text-nowrap">Offline</td>
                        <td className="text-nowrap">{moment(item.created_at
                        ).format("MMMM-DD-YYYY HH:mm")}</td>
                        <td>${item?.total_rate}</td>
                        <td>${!!item?.payment_status ? item?.payment_status : 0}</td>
                        <td>${!!item?.balance_payment ? item?.balance_payment : item.total_rate === item?.payment_status ? item?.balance_payment : item.total_rate}</td>
                        <td>
                          <div className="actionBtn d-flex">
                            <Button title="Payment" onClick={() => handleShow({ paymentStatus: !!item?.payment_status ? item?.payment_status : 0, pendingBalance: item?.balance_payment, totalRate: item?.total_rate, id: item?.id })} className="rounded-circle ms-2">
                              <MdPayments />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))
                    : <tr className="text-center">
                      <td colSpan="12">
                        <div>
                          <span>No payment history found</span>
                        </div>
                      </td>
                    </tr>}



              </table>
            </div>
            <ReactPaginate
              pageCount={Math.ceil(
                schoolPaymentHistoryData?.pagination_data?.total / 10
              )}
              onPageChange={handlePageChange}
              containerClassName={"paging m-0 p-0 pt-3 d-flex"}
              activeClassName={"active"}
              previousLabel={"Previous"}
              nextLabel={"Next"}
            />
          </div>
        </div>
      </div>
      <Modal show={show}>
        <Button className='closePopup' onClick={handleClose}><IoCloseSharp /></Button>
        <PaymentModal onClose={handleClose} />
      </Modal>
    </>
  );
};
export default PaymentHistory;
