import React, { useState } from "react";
import "./BankDetails.scss";
import VendorMenu from "../component/VendorMenu";
import Button from "react-bootstrap/Button";
import DashboardTopRight from "../component/DashboardTopRight";
import AddBankDetails from "../component/AddBankDetails";
import { IoCloseSharp } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import Empty from "../assets/images/empty.svg";
import USbank from "../assets/images/us-bank.png";
import CanadaBank from "../assets/images/bank-of-canada.png";
import CIBCBank from "../assets/images/cibc-bank.png";
import { AiFillBank } from "react-icons/ai";

const BankDetails = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="dbCon">
        <VendorMenu />
        <div className="dbRight">
          {/* <header className="dbHeader d-flex align-items-center justify-content-between">
            <div className="dbHd">Bank Details</div>
            <DashboardTopRight />
          </header> */}
          <div style={{ display: "none" }}>
            <div className="emptyBox d-flex align-items-center justify-content-center">
              <div>
                <div className="text-center mb-4">
                  <img src={Empty} alt="" />
                </div>
                <div className="text-center dbSubHd mb-3">No Bank Added!</div>
                <div className="text-center mb-4">
                  You have not added any bank details yet.
                </div>
                <div className="text-center">
                  <Button className="smallBtn" onClick={handleShow}>
                    Add Bank
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="outerBox">
            <ul className="bankList m-0 p-0">
              <li className="d-flex align-items-center justify-content-between">
                <div className="d-block d-sm-flex align-items-center">
                  <div className="bankImg d-flex align-items-center">
                    <img src={USbank} alt="" />
                  </div>
                  <div className="cardDet ms-0 ms-sm-3 mt-1 ms-sm-0">
                    <span className="d-block mb-1">xxxxxxxx1234</span> US Bank,
                    Chicago
                  </div>
                </div>
                <div>
                  <Button className="removeBtn" disabled>
                    Inactive
                  </Button>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between">
                <div className="d-block d-sm-flex align-items-center">
                  <div className="bankImg d-flex align-items-center">
                    <img src={CanadaBank} alt="" />
                  </div>
                  <div className="cardDet ms-0 ms-sm-3 mt-1 ms-sm-0">
                    <span className="d-block mb-1">xxxxxxxx5678</span> National
                    Bank of Canada
                  </div>
                </div>
                <div>
                  <Button className="removeBtn">Remove Bank</Button>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between">
                <div className="d-block d-sm-flex align-items-center">
                  <div className="bankImg d-flex align-items-center">
                    <img src={CIBCBank} alt="" />
                  </div>
                  <div className="cardDet ms-0 ms-sm-3 mt-1 ms-sm-0">
                    <span className="d-block mb-1">xxxxxxxx9542</span> canadian
                    imperial bank of commerce
                  </div>
                </div>
                <div>
                  <Button className="removeBtn">Remove Bank</Button>
                </div>
              </li>
              <li className="d-flex align-items-center justify-content-between last">
                <div className="d-flex align-items-center addBank">
                  <AiFillBank className="icon me-3" /> New Bank Account
                </div>
                <div>
                  <Button className="smallBtn" onClick={handleShow}>
                    Add Bank
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Modal className="addbankDetails" show={show}>
        <Button className="closePopup" onClick={handleClose}>
          <IoCloseSharp />
        </Button>
        <AddBankDetails onClose={handleClose} />
      </Modal>
    </>
  );
};
export default BankDetails;
