module.exports = {
  Url: (() => {
    const hosts = process.env.REACT_APP_HOST;
    if (hosts === "staging") {
      return process.env.REACT_APP_API_URL;
    } else if (hosts === "production") {
      return process.env.REACT_APP_API_URL;
    } else {
      return process.env.REACT_APP_API_URL_DEV;
    }
  })(),
};
