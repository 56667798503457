import React, { useState, useEffect } from 'react';
import SchoolMenu from '../../component/SchoolMenu';
import { AiFillDollarCircle } from "react-icons/ai";
import { FaBus } from "react-icons/fa6";
import Button from 'react-bootstrap/Button';
import { IoSearchSharp } from "react-icons/io5";
import Calendar from 'react-awesome-calendar';
import Modal from 'react-bootstrap/Modal';
import DeletePopup from '../../component/DeletePopup';
import { IoCloseSharp } from "react-icons/io5";
import EditVendor2 from '../../component/EditVendor2';
import { useDispatch, useSelector } from "react-redux";
import { getSchoolDashboardData } from "../../redux/action/schoolAction";
import ReactPaginate from "react-paginate";
import { useDebounce } from 'use-debounce';

const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth() + 1

const initialState = {
    page: 1,
    search: "",
    status: "",
    year: currentYear,
    month: currentMonth,
};
const SchoolDashboard = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const [perPage, SetcurrentPage] = useState(10);
    const dispatch = useDispatch();
    const detailData = useSelector((state) => state.school);
    const { schoolDashboardData, loader } = detailData
    const [paramState, setParamState] = useState(initialState);
    const { page, search, status, year, month } = paramState;
    const [value] = useDebounce(search, 300);


    useEffect(() => {

        const getSchoolDashboardDatas = async () => {
            try {
                await dispatch(getSchoolDashboardData(paramState));
            } catch (error) {
                console.error("Error fetching user profile details:", error);
            }
        };

        getSchoolDashboardDatas();
    }, [dispatch, getSchoolDashboardData]);


    useEffect(() => {
        let obj = { page: page, search: search, status: status, year: year, month: month };
        dispatch(getSchoolDashboardData(obj));
    }, [value])

    // const events = schoolDashboardData?.data?.map((data) => {
    //     return data = {
    //         id: data.id,
    //         color: '#363D4D',
    //         from: data.start_date,
    //         to: data.start_date,
    //         title: 'Trip Reserved'
    //     }
    // })

    function getDateObjectsInRange(startDate, endDate) {
        let dates = [];
        let currentDate = new Date(startDate);

        // Ensure the endDate is also a Date object
        endDate = new Date(endDate);

        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        while (currentDate <= endDate) {
            // Get the day of the week
            let dayOfWeek = dayNames[currentDate.getDay()];

            // Create an object with the date and the day of the week
            let dateObject = {
                date: new Date(currentDate).toISOString().split('T')[0],
                day: dayOfWeek
            };

            dates.push(dateObject);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    }

    function filterDatesByDays(dateObjects, days) {
        // Filter the dates to include only those with days in the provided list
        return dateObjects.filter(dateObject => days.includes(dateObject.day));
    }

    // function getNextDay(day) {
    //     const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    //     const index = daysOfWeek.indexOf(day);
    //     return daysOfWeek[(index + 1) % 7];
    // }

    const calendarEvents = []
    const eventsForCalendar = schoolDashboardData?.booking_data?.map((data) => {
        if (data.booking_type === 'recurring') {
            const dateObjectWithDays = getDateObjectsInRange(data.date, data.end_date)
            const daysFromApi = data.weeks
            const startTime = data.start_time
            const endTime = data.end_time

            //when booking is for selected days (recurring)
            if (daysFromApi.length != 0) {
                //const daysToMatchCorrected = daysFromApi.map(day => getNextDay(day))
                const filterDates = filterDatesByDays(dateObjectWithDays, daysFromApi)
                filterDates.forEach(element => {
                    data = {
                        id: data.id,
                        color: '#363D4D',
                        from: `${element.date}T${startTime}:00+00:00`,
                        to: `${element.date}T${endTime}:00+00:00`,
                        title: 'Trip Reserved (Recurring-Weekly)'
                    }
                    calendarEvents.push(data)
                });
            }
            else{
                // when booking is for everyday (recurring)
                console.log("dateObject",dateObjectWithDays, data)
                dateObjectWithDays.forEach(element => {
                    data = {
                        id: data.id,
                        color: '#363D1A',
                        from: `${element.date}T00:00:00+00:00`,
                        to: `${element.date}T00:00:00+00:00`,
                        title: 'Trip Reserved (Recurring-Daily)'
                    }
                    calendarEvents.push(data)
                });
            }

        }
        else {
            // whnen booking is for one-time
            data = {
                id: data.id,
                color: '#363D4D',
                from: `${data.date}T${data.start_time}:00+00:00`,
                to: `${data.date}T${data.end_time}:00+00:00`,
                title: `Trip Reserved (One Time)`
            }
            calendarEvents.push(data)

        }
    })

    const events = calendarEvents

    const handleSearch = (e) => {
        const { name, value } = e.target;
        setParamState({
            page,
            search: value,
            status,
            year,
            month,
        });
    };

    const handleChangeStatus = (e) => {
        const { value } = e.target;
        let obj = { page: page, search: search, status: value, year: year, month: month };
        setParamState({
            page,
            search,
            status: value,
            year,
            month,
        });
        dispatch(getSchoolDashboardData(obj));
    };

    const handleCalender = (data) => {
        const { year, month } = data
        let obj = { page: page, search: search, status: status, year: year, month: month + 1 };
        setParamState({
            page,
            search,
            status,
            year: year,
            month: month + 1,
        });
        dispatch(getSchoolDashboardData(obj));
    }

    

    const handlePageChange = async ({ selected }) => {
        let selectedPage = selected + 1;
        SetcurrentPage(10 * Number(selectedPage));
        let obj = { page: selectedPage, search: search, status: status, year: year, month: month };
        await dispatch(getSchoolDashboardData(obj));
        setParamState({
            page: selectedPage,
            search,
            status,
            year,
            month
        })
    };

    return (
        <>
            <div className='dbCon'>
                <SchoolMenu />
                <div className='dbRight'>
                    <div>
                        <ul className='dbTop p-0 d-flex flex-wrap'>
                            <li className='col-12 col-sm-6 col-lg-3'>
                                <div className='inner' style={{ background: "#FBF7F3" }}>
                                    <div className='hd d-flex align-items-center mb-3'><span><svg xmlns="http://www.w3.org/2000/svg" width="21.644" height="27.069" className='me-2' viewBox="0 0 21.644 27.069">
                                        <g id="noun-driver-3917676" transform="translate(-11.014 -4.751)">
                                            <g id="Group_2965" data-name="Group 2965" transform="translate(11.014 4.751)">
                                                <path id="Path_1610" data-name="Path 1610" d="M43.7,48.718a.319.319,0,0,1,.026.272.325.325,0,0,1-.242.2l-1.428.27.775,1.516a.325.325,0,0,1-.15.441A28.35,28.35,0,0,0,38.486,53.9v2.8a21.308,21.308,0,0,0,9.745-3.386C47.757,50.99,46.014,49.8,43.7,48.718Z" transform="translate(-26.587 -29.675)" fill="#727782" />
                                                <path id="Path_1611" data-name="Path 1611" d="M31.053,50.128a38.09,38.09,0,0,0,1.675,3.592l.225.429c.1.2.181.346.242.465a46.415,46.415,0,0,0,2.137-4.476A3.649,3.649,0,0,1,31.053,50.128Z" transform="translate(-22.374 -30.474)" fill="#727782" />
                                                <path id="Path_1612" data-name="Path 1612" d="M35.723,62.012a.325.325,0,0,1-.117.022h-.343a.324.324,0,0,1-.117-.022.328.328,0,0,1-.1-.062l-.039-.034v2.307l.11,0q.32,0,.622,0l.125,0v-2.3l-.04.035A.327.327,0,0,1,35.723,62.012Z" transform="translate(-24.615 -37.156)" fill="#727782" />
                                                <path id="Path_1613" data-name="Path 1613" d="M16.4,51.231a.325.325,0,0,1,.018-.252l.772-1.517-1.425-.27a.324.324,0,0,1-.242-.2.32.32,0,0,1,.026-.272c-2.323,1.079-4.068,2.274-4.536,4.6l.027.018c.132.085.263.17.395.252s.234.144.351.211a19.568,19.568,0,0,0,8.969,2.917V53.9a28.4,28.4,0,0,0-4.187-2.481A.326.326,0,0,1,16.4,51.231Z" transform="translate(-11.014 -29.675)" fill="#727782" />
                                                <path id="Path_1614" data-name="Path 1614" d="M32.7,24.565V24.5a1.276,1.276,0,0,0,0-2.538V21.48l.069-.029a.318.318,0,0,0,.191-.295V19.965a.324.324,0,0,0-.325-.325H21.084a.324.324,0,0,0-.325.325v1.191a.318.318,0,0,0,.191.295l.074.031v.48a1.276,1.276,0,0,0,0,2.538v.221A5.935,5.935,0,0,0,22.854,29c.108.1.221.2.335.29v.945A18.349,18.349,0,0,0,21.9,31.607l-.007.008s-.006.011-.01.015l-.006,0c-.169.2-.32.385-.441.536l1.326.252a.326.326,0,0,1,.229.467L22.171,34.5a29.33,29.33,0,0,1,3.561,2.081,33.164,33.164,0,0,1-1.894-4.247V29.754a5.742,5.742,0,0,0,3.306.844A5.56,5.56,0,0,0,30,29.639v2.345a31.666,31.666,0,0,1-2.009,4.589A29.31,29.31,0,0,1,31.548,34.5l-.821-1.608a.325.325,0,0,1,.229-.467l1.33-.252c-.127-.159-.285-.352-.464-.564-.422-.489-.813-.905-1.173-1.253V29.143s0,0,0,0A6.152,6.152,0,0,0,32.7,24.565Zm-5.586,5.384A5.108,5.108,0,0,1,23.3,28.532a5.212,5.212,0,0,1-1.629-3.811V21.758a13.657,13.657,0,0,0,10.378,0v2.81A5.344,5.344,0,0,1,27.114,29.949Z" transform="translate(-16.037 -13.191)" fill="#727782" />
                                                <path id="Path_1615" data-name="Path 1615" d="M30,29.522a.324.324,0,0,0,.121-.443,1.073,1.073,0,0,0-1.864,0,.325.325,0,0,0,.564.321.438.438,0,0,1,.736,0A.325.325,0,0,0,30,29.522Z" transform="translate(-20.762 -18.235)" fill="#727782" />
                                                <path id="Path_1616" data-name="Path 1616" d="M39.333,29.08a.325.325,0,1,0,.564.321.437.437,0,0,1,.736,0,.325.325,0,0,0,.564-.321A1.072,1.072,0,0,0,39.333,29.08Z" transform="translate(-27.044 -18.235)" fill="#727782" />
                                                <path id="Path_1617" data-name="Path 1617" d="M35.68,35.18a.325.325,0,1,0-.459-.459.256.256,0,0,1-.354,0,.325.325,0,1,0-.46.459A.9.9,0,0,0,35.68,35.18Z" transform="translate(-24.221 -21.686)" fill="#727782" />
                                                <path id="Path_1618" data-name="Path 1618" d="M35.256,37.332a.325.325,0,0,0-.429.163,1.412,1.412,0,0,1-2.353,0,.325.325,0,0,0-.592.266,2.049,2.049,0,0,0,3.537,0A.325.325,0,0,0,35.256,37.332Z" transform="translate(-22.827 -23.204)" fill="#727782" />
                                                <path id="Path_1619" data-name="Path 1619" d="M19.045,11.169a.972.972,0,0,1,.905-.619H31.5a.972.972,0,0,1,.905.619,2.083,2.083,0,0,0,1.169-1.681c0-1.828-3.591-4.738-7.848-4.738s-7.852,2.911-7.852,4.738A2.072,2.072,0,0,0,19.045,11.169Zm3.946-4.5H23.9v.91h.909v-.91h.914v.91h.91v-.91h.914v.91h.91v.914h-.91V7.579h-.914v.914h-.91V7.579h-.914v.914H23.9V7.579h-.914Z" transform="translate(-14.904 -4.751)" fill="#727782" />
                                            </g>
                                        </g>
                                    </svg></span> Listed Vendors</div>
                                    <div className='d-flex align-items-center'>
                                        <div className='price'><span className='d-block mb-1'>{schoolDashboardData?.listed_vendor}</span></div>
                                    </div>
                                </div>
                            </li>
                            <li className='col-12 col-sm-6 col-lg-3'>
                                <div className='inner' style={{ background: "#F8F9FB" }}>
                                    <div className='hd d-flex align-items-center mb-3'><span><FaBus className='icon me-2' /></span> Registered Vehicles</div>
                                    <div className='d-flex align-items-center'>
                                        <div className='price'><span className='d-block mb-1'>{schoolDashboardData?.registered_vehicle}</span></div>
                                    </div>
                                </div>
                            </li>
                            {/* <li className='col-12 col-sm-6 col-lg-3'>
                                <div className='inner' style={{ background: "#FBF7F3" }}>
                                    <div className='hd d-flex align-items-center mb-3'><span><FaRoute className='icon me-2' /></span> Total Routes</div>
                                    <div className='d-flex align-items-center'>
                                        <div className='price'><span className='d-block mb-1'>55</span></div>
                                    </div>
                                </div>
                            </li> */}
                            <li className='col-12 col-sm-6 col-lg-3'>
                                <div className='inner' style={{ background: "#F8F9FB" }}>
                                    <div className='hd d-flex align-items-center mb-3'><span><AiFillDollarCircle className='icon me-2' /></span> Total Balance</div>
                                    <div className='d-flex align-items-center'>
                                        <div className='price'><span className='d-block mb-1'>$0.00</span></div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className='pt-4'>
                            <div className='d-flex flex-wrap align-items-center justify-content-between'>
                                <div className='dbSubHd mb-2'>Vehicles On Board</div>
                                <div className='mb-2 filterBox'>
                                    <ul className='d-flex flex-wrap align-items-center p-0 transactionsFilter'>
                                        {/* <li><div className='searchBox'><input type='text' className='input' placeholder='Search..' name="search"
                                            value={search}
                                            onChange={handleSearch} /> <Button className='searchBtn'><IoSearchSharp /></Button></div></li> */}
                                        <li>
                                            <select className='smallSelect' onChange={handleChangeStatus}>
                                                <option value="">Status</option>
                                                <option value="On Route">On Route</option>
                                                <option value="Available">Available</option>
                                            </select>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='table m-0'>
                                <table>
                                    <tr>
                                        <th>Sr.No.</th>
                                        <th>Vendor</th>
                                        <th>Vehicle License Plate</th>
                                        {/* <th>Route</th> */}
                                        {/* <th>Riders</th> */}
                                        <th>Status</th>
                                        {/* <th>Action</th> */}
                                    </tr>
                                    {loader !== false ?
                                        <tr className="text-center">
                                            <td colSpan="6">
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only p-0"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        : schoolDashboardData?.data?.length > 0 ? schoolDashboardData.data.map((data, index) => (
                                            <tr key={index}>
                                                <td>{index + 1 + (perPage - 10)}</td>
                                                <td>{`${data.vehicle.vehicle.user.full_name}`}</td>
                                                <td className='text-nowrap'>{data.vehicle.vehicle.license_number}</td>
                                                {/* <td className='text-nowrap'>Blue Bird Corporation</td> */}
                                                {/* <td>20</td> */}
                                                <td><span className='status onRoute'>{data.vehicle.duty_status}</span></td>
                                                {/* <td>
                                                <div className='actionBtn d-flex'>
                                                    <Button title='Edit' className='rounded-circle me-2' onClick={handleShow2}><FiEdit /></Button>
                                                    <Button title='Delete' className='rounded-circle' onClick={handleShow}><RiDeleteBin6Fill /></Button>
                                                </div>
                                            </td> */}
                                            </tr>
                                        )) : <tr className="text-center">
                                            <td colSpan="12">
                                                <div>
                                                    <span>No vehicle found</span>
                                                </div>
                                            </td>
                                        </tr>}
                                </table>
                            </div>
                            <ReactPaginate
                                pageCount={Math.ceil(
                                    schoolDashboardData?.pagination_data?.total / 10
                                )}
                                onPageChange={handlePageChange}
                                containerClassName={"paging m-0 p-0 pt-3 d-flex"}
                                activeClassName={"active"}
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                            />
                        </div>
                        <div className='mt-4 outerBox'>
                            <div className='dbSubHd mb-3'>Reservations Calendar</div>
                            <div className='calenderBox'>
                                <Calendar onChange={handleCalender} events={events} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show}>
                <Button className='closePopup' onClick={handleClose}><IoCloseSharp /></Button>
                <DeletePopup onClose={handleClose} />
            </Modal>
            <Modal className='addbankDetails' show={show2}>
                <Button className='closePopup' onClick={handleClose2}><IoCloseSharp /></Button>
                <EditVendor2 onClose={handleClose2} />
            </Modal>
        </>

    )

}
export default SchoolDashboard;