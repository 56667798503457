import { loginAction } from "../actionType";
import config from "../../config/config";
import axios from "axios";

//-----------------------------------Login User ---------------------------------------//

export const loginInitiate = () => ({
  type: loginAction.LOGIN_INITIATE,
});

export const loginSuccess = (data) => ({
  type: loginAction.LOGIN_SUCCESS,
  payload: data,
});

export const loginFailure = (data) => ({
  type: loginAction.LOGIN_FAILURE,
});

export function loginUser(payload) {
  console.log("dff",config.Url)
  return (dispatch) => {
    dispatch(loginInitiate());
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}login`, payload, {
          headers: {},
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(loginSuccess(data));
          } else {
            dispatch(loginFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(loginFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Forgot Passord ---------------------------------------//

export const forgetPasswordInitiate = () => ({
  type: loginAction.FORGET_PASSWORD_INITIATE,
});

export const forgetPasswordSuccess = (data) => ({
  type: loginAction.FORGET_PASSWORD_SUCCESS,
  payload: data,
});

export const forgetPasswordFailure = (data) => ({
  type: loginAction.FORGET_PASSWORD_FAILURE,
});

export function forgetPassword(payload) {
  return (dispatch) => {
    dispatch(forgetPasswordInitiate());
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}forgot-password`, payload, {
          headers: {},
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(forgetPasswordSuccess(data));
          } else {
            dispatch(forgetPasswordFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(forgetPasswordFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Reset Passord ---------------------------------------//

export const resetPasswordInitiate = () => ({
  type: loginAction.RESET_PASSWORD_INITIATE,
});

export const resetPasswordSuccess = (data) => ({
  type: loginAction.RESET_PASSWORD_SUCCESS,
  payload: data,
});

export const resetPasswordFailure = (data) => ({
  type: loginAction.RESET_PASSWORD_FAILURE,
});

export function resetPassword(payload) {
  return (dispatch) => {
    dispatch(resetPasswordInitiate());
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}reset-password`, payload, {
          headers: {},
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(resetPasswordSuccess(data));
          } else {
            dispatch(resetPasswordFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(resetPasswordFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------change Passord ---------------------------------------//

export const changePasswordInitiate = () => ({
  type: loginAction.CHANGE_PASSWORD_INITIATE,
});

export const changePasswordSuccess = (data) => ({
  type: loginAction.CHANGE_PASSWORD_SUCCESS,
  payload: data,
});

export const changePasswordFailure = (data) => ({
  type: loginAction.CHANGE_PASSWORD_FAILURE,
});

export function changePassword(payload) {
  return (dispatch) => {
    dispatch(changePasswordInitiate());
    return new Promise((resolve, reject) =>
      axios
        .put(`${config.Url}change-password`, payload, {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(changePasswordSuccess(data));
          } else {
            dispatch(changePasswordFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(changePasswordFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Logout User ---------------------------------------//

export const logoutInitiate = () => ({
  type: loginAction.LOGOUT_INITIATE,
});

export const logoutSuccess = (data) => ({
  type: loginAction.LOGOUT_SUCCESS,
  payload: data,
});

export const logoutFailure = (data) => ({
  type: loginAction.LOGOUT_FAILURE,
});

export function logout() {
  return (dispatch) => {
    dispatch(logoutInitiate());
    return new Promise((resolve, reject) =>
      axios
        .post(
          `${config.Url}logout`,
          {},
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(logoutSuccess(data));
          } else {
            dispatch(logoutFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(logoutFailure(err));
          reject(err);
        })
    );
  };
}
