import { vendorAction } from "../actionType";
import config from "../../config/config";
import axios from "axios";
import { multiPartData } from "../../utils";

//-----------------------------------Get Vendor Profile Details ---------------------------------------//

export const vendorProfileDetailInitiate = () => ({
  type: vendorAction.GET_VENDOR_PROFILE_INITIATE,
});

export const vendorProfileDetailSuccess = (data) => ({
  type: vendorAction.GET_VENDOR_PROFILE_SUCCESS,
  payload: data,
});

export const vendorProfileDetailFailure = () => ({
  type: vendorAction.GET_VENDOR_PROFILE_FAILURE,
});

export function vendorProfileDetails(id) {
  return (dispatch) => {
    dispatch(vendorProfileDetailInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}vendor/get-vendor-profiles`, {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(vendorProfileDetailSuccess(data));
          } else {
            dispatch(vendorProfileDetailFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(vendorProfileDetailFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Update Vendor  Profile---------------------------------------//

export const updateVendorProfileInitiate = () => ({
  type: vendorAction.UPDATE_VENDOR_PROFILE_INITIATE,
});

export const updateVendorProfileSuccess = (data) => ({
  type: vendorAction.UPDATE_VENDOR_PROFILE_SUCCESS,
  payload: data,
});

export const updateVendorProfileFailure = () => ({
  type: vendorAction.UPDATE_VENDOR_PROFILE_FAILURE,
});

export function updateVendorProfiles(payload) {
  const formData = multiPartData(payload);
  return (dispatch) => {
    dispatch(updateVendorProfileInitiate());
    return new Promise((resolve, reject) =>
      axios
        .patch(`${config.Url}vendor/get-vendor-profiles`, formData, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.code == 200) {
            dispatch(updateVendorProfileSuccess(data));
          } else {
            dispatch(updateVendorProfileFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(updateVendorProfileFailure(err));
          reject(err);
        })
    );
  };
}


//-----------------------------------Get Vendor  Dashboard Data---------------------------------------//

export const getVendorDashboardDataInitiate = () => ({
  type: vendorAction.GET_VENDOR_DASHBOARD_DATA_INITIATE,
});

export const getVendorDashboardDataSuccess = (data) => ({
  type: vendorAction.GET_VENDOR_DASHBOARD_DATA_SUCCESS,
  payload: data,
});

export const getVendorDashboardDataFailure = () => ({
  type: vendorAction.GET_VENDOR_DASHBOARD_DATA_FAILURE,
});

export function getVendorDashboardData(obj) {
  const {page, year} = obj
  const month = obj.month < 10 ? `0${obj.month}` : obj.month.toString();

  return (dispatch) => {
    dispatch(getVendorDashboardDataInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}vendor/dashboard?page=${page}&year=${year.toString()}&month=${month}`, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const data = response.data
          if (response.status && response.status == 200) {
            dispatch(getVendorDashboardDataSuccess(data));
          } else {
            dispatch(getVendorDashboardDataFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(getVendorDashboardDataFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Notification Data---------------------------------------//

export const getVendorNotificationDataInitiate = () => ({
  type: vendorAction.GET_VENDOR_NOTIFICATION_DATA_INITIATE,
});

export const getVendorNotificationDataSuccess = (data) => ({
  type: vendorAction.GET_VENDOR_NOTIFICATION_DATA_SUCCESS,
  payload: data,
});

export const getVendorNotificationDataFailure = () => ({
  type: vendorAction.GET_VENDOR_NOTIFICATION_DATA_FAILURE,
});

export function getVendorNotificationData(obj) {
  const { page } = obj
  return (dispatch) => {
    dispatch(getVendorNotificationDataInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}vendor/notification?page=${page}`, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(getVendorNotificationDataSuccess(data));
          } else {
            dispatch(getVendorNotificationDataFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(getVendorNotificationDataFailure(err));
          reject(err);
        })
    );
  };
}


//-----------------------------------Booking vehicle List ---------------------------------------//

export const vendorBookingVehicleListInitiate = () => ({
  type: vendorAction.VENDOR_BOOKING_VEHICLE_LIST_INITIATE,
});

export const vendorBookingVehicleListSuccess = (data) => ({
  type: vendorAction.VENDOR_BOOKING_VEHICLE_LIST_SUCCESS,
  payload: data,
});

export const vendorBookingVehicleListFailure = () => ({
  type: vendorAction.VENDOR_BOOKING_VEHICLE_LIST_FAILURE,
});

export function vendorBookingVehicleLists(payload) {
  return (dispatch) => {
    dispatch(vendorBookingVehicleListInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(
          `${config.Url}vendor/get-bookings?page=${payload.page ? payload.page : 1
          }&search=${payload.search ? payload.search : ""}&book_date=${payload.date ? payload.date : ""}`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(vendorBookingVehicleListSuccess(data));
          } else {
            dispatch(vendorBookingVehicleListFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(vendorBookingVehicleListFailure(err));
          reject(err);
        })
    );
  };
}

//-----------------------------------Payment History Data---------------------------------------//

export const getVendorPaymentHistoryDataInitiate = () => ({
  type: vendorAction.GET_VENDOR_PAYMENT_HISTORY_DATA_INITIATE,
});

export const getVendorPaymentHistoryDataSuccess = (data) => ({
  type: vendorAction.GET_VENDOR_PAYMENT_HISTORY_DATA_SUCCESS,
  payload: data,
});

export const getVendorPaymentHistoryDataFailure = () => ({
  type: vendorAction.GET_VENDOR_PAYMENT_HISTORY_DATA_FAILURE,
});

export function getVendorPaymentHistoryData(obj) {
  const { page } = obj
  return (dispatch) => {
    dispatch(getVendorPaymentHistoryDataInitiate());
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}vendor/payment-history?page=${page}`, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (response.status && response.status == 200) {
            dispatch(getVendorPaymentHistoryDataSuccess(data));
          } else {
            dispatch(getVendorPaymentHistoryDataFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(getVendorPaymentHistoryDataFailure(err));
          reject(err);
        })
    );
  };
}