export function multiPartData(data) {
  let multiPart = new FormData();
  for (let key in data) {
    multiPart.append(key, data[key])
  }

  return multiPart
}

export function getWedThursDates(startDate, endDate, activeDays) {
  let result = 0;
  const current = new Date(startDate);
  let end = new Date(endDate);
  end.setMinutes(end.getMinutes() + 1)
  let length = activeDays.length
  for (let i = 0; current <= end; i++) {
    switch (length) {
      case 1:
        if (current.getDay() === activeDays[0]) {
          result++
        }
        break;
      case 2:
        if (current.getDay() === activeDays[0] || current.getDay() === activeDays[1]) {
          result++
        }
        break;
      case 3:
        if (current.getDay() === activeDays[0] ||
          current.getDay() === activeDays[1] ||
          current.getDay() === activeDays[2]) {
          result++
        }
        break;
      case 4:
        if (current.getDay() === activeDays[0] ||
          current.getDay() === activeDays[1] ||
          current.getDay() === activeDays[2] ||
          current.getDay() === activeDays[3]) {
          result++
        }
        break;
      case 5:
        if (current.getDay() === activeDays[0] ||
          current.getDay() === activeDays[1] ||
          current.getDay() === activeDays[2] ||
          current.getDay() === activeDays[3] ||
          current.getDay() === activeDays[4]) {
          result++
        }
        break;
      case 6:
        if (current.getDay() === activeDays[0] ||
          current.getDay() === activeDays[1] ||
          current.getDay() === activeDays[2] ||
          current.getDay() === activeDays[3] ||
          current.getDay() === activeDays[4] ||
          current.getDay() === activeDays[5]) {
          result++
        }
        break;
      case 7:
        if (current.getDay() === activeDays[0] ||
          current.getDay() === activeDays[1] ||
          current.getDay() === activeDays[2] ||
          current.getDay() === activeDays[3] ||
          current.getDay() === activeDays[4] ||
          current.getDay() === activeDays[5] ||
          current.getDay() === activeDays[6]) {
          result++
        }
        break;
      default:
        return 0
    }
    current.setDate(current.getDate() + 1);
  }

  return result;
}
