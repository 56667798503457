import React, { useState } from 'react'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { IoCloseSharp } from "react-icons/io5";
import { IoMdCloudDownload } from "react-icons/io";

const DocumentPreview = ({ preview, size }) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleDownload = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'document.pdf'; // Set the desired filename here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            {preview && <a className={`${size === "small" ? "addDriver" : "addVehicle"} me-2 d-flex align-items-center justify-content-center`} onClick={() => setIsOpen(true)}>Preview</a>}
            <Modal className="driverInfoPopup" show={isOpen}>
                <div className='closePopup downloadBtn'>
                    <button  onClick={() => handleDownload(preview)}><IoMdCloudDownload /></button>
                    <Button className="closePopup" onClick={() => setIsOpen(false)}>
                        <IoCloseSharp />
                    </Button>
                </div>
                <DocViewer
                    prefetchMethod='get'
                    documents={[
                        { uri: preview },
                    ]}
                    pluginRenderers={DocViewerRenderers}
                />
                {/* <DriverInfo id={id} /> */}
            </Modal>
        </>
    )
}

export default DocumentPreview