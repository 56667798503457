import React, { useState, useEffect } from "react";
import "./AddVehicle.scss";
import { NavLink } from "react-router-dom";
import VendorMenu from "../component/VendorMenu";
import Button from "react-bootstrap/Button";
import DashboardTopRight from "../component/DashboardTopRight";
import DriverUpdate from "../component/DriverUpdate";
import { IoCloseSharp } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  getCountrys,
  getStates,
  vehicleInformation,
  driverDetails,
  editDrivers,
  vehicleDropDown

} from "../redux/action/driverAction";

import { allBookingVehicleLists } from "../redux/action/vehicleAction";
const initialState = {
  name: "",
  email_address: "",
  street_address: "",
  country: "",
  state: "",
  city: "",
  phone: "",
  zip: "",
  vehicle_id: "",
  shift_type: "",
  shift_startime: "",
  shift_endtime: "",
  driver_assigned_id: "",
  // rate: "",
  errors: "",
};

const EditDriver = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iState, updateState] = useState(initialState);
  const listsData = useSelector((state) => state.driver);
  const vehicleData = useSelector((state) => state.vehicle);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [vehicleDetails, setVehicleDetails] = useState("")
  const { countryList, stateList, vehicleInformationList, driverdDetailData, vehicleDropdownList } =
    listsData;
  const { allBookingVehicleList } = vehicleData
  let driverId = localStorage.getItem("driverId");



  const {
    name,
    email_address,
    street_address,
    country,
    state,
    city,
    phone,
    zip,
    vehicle_id,
    shift_type,
    shift_startime,
    shift_endtime,
    driver_assigned_id,
    // rate,
    errors,
  } = iState;

  const handleClose = () => {
    setShow(false);
    navigate("/drivers");
  };

  useEffect(() => {
    const getDriverData = async () => {
      try {
        await dispatch(driverDetails(driverId));
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getDriverData();
  }, [dispatch, driverDetails]);


  useEffect(() => {
    if (!_.isEmpty(driverdDetailData)) {
      const updateData = _.cloneDeep(iState);
      updateData.driver_assigned_id =
        (driverdDetailData &&
          driverdDetailData.driver_assigned &&
          driverdDetailData.driver_assigned[0] &&
          driverdDetailData.driver_assigned[0]?.id) ||
        "";
      updateData.city = (driverdDetailData && driverdDetailData?.city) || "";

      updateData.email_address =
        (driverdDetailData && driverdDetailData?.email_address) || "";
      updateData.name = (driverdDetailData && driverdDetailData?.name) || "";
      updateData.phone = (driverdDetailData && driverdDetailData?.phone) || "";
      updateData.street_address =
        (driverdDetailData && driverdDetailData?.street_address) || "";

      updateData.country =
        (driverdDetailData && driverdDetailData?.country?.id) || "";
      updateData.state =
        (driverdDetailData && driverdDetailData?.state?.id) || "";

      updateData.zip = (driverdDetailData && driverdDetailData?.zip) || "";
      updateData.vehicle_id =
        (driverdDetailData &&
          driverdDetailData.driver_assigned &&
          driverdDetailData.driver_assigned[0] &&
          driverdDetailData.driver_assigned[0].vehicle &&
          driverdDetailData.driver_assigned[0].vehicle.id) ||
        "";
      updateData.shift_type =
        (driverdDetailData &&
          driverdDetailData.driver_assigned &&
          driverdDetailData.driver_assigned[0] &&
          driverdDetailData.driver_assigned[0]?.shift_type) ||
        "";
      updateData.shift_startime =
        (driverdDetailData &&
          driverdDetailData.driver_assigned &&
          driverdDetailData.driver_assigned[0] &&
          driverdDetailData.driver_assigned[0]?.shift_startime) ||
        "";
      updateData.shift_endtime =
        (driverdDetailData &&
          driverdDetailData.driver_assigned &&
          driverdDetailData.driver_assigned[0] &&
          driverdDetailData.driver_assigned[0]?.shift_endtime) ||
        "";
      // updateData.rate =
      //   (driverdDetailData &&
      //     driverdDetailData.driver_assigned &&
      //     driverdDetailData.driver_assigned[0] &&
      //     driverdDetailData.driver_assigned[0]?.rate) ||
      //   "";
      updateState(updateData);
      setVehicleDetails(driverdDetailData &&
        driverdDetailData.driver_assigned &&
        driverdDetailData.driver_assigned[0] &&
        driverdDetailData.driver_assigned[0].vehicle &&
        driverdDetailData.driver_assigned[0].vehicle.id)

    }
  }, [driverdDetailData]);

  useEffect(() => {
    const getCountryData = async () => {
      try {
        await dispatch(getCountrys());
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getCountryData();
  }, [dispatch, getCountrys]);

  useEffect(() => {
    const getInformationData = async () => {
      try {
        await dispatch(vehicleInformation("available"));
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getInformationData();
  }, [dispatch, vehicleInformation, allBookingVehicleLists]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  useEffect(() => {
    if (country) {
      dispatch(getStates(country));
    }
  }, [country]);

  const handleCountry = (e) => {
    const { name, value } = e.target;
    dispatch(getStates(value));
    updateState({
      ...iState,
      country: value,
    });
  };

  const handleInformtion = (e) => {
    const { name, value } = e.target;
    setVehicleDetails(value)
    updateState({
      ...iState,
      vehicle_id: value,
    });
  };

  useEffect(() => {
    const getInformationListData = async () => {
      try {
        await dispatch(vehicleDropDown("available"));
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getInformationListData();
  }, [dispatch, vehicleDropDown]);

  let handleValidation = () => {
    let nameEmpty = "";
    let email_addressEmpty = "";
    let street_addressEmpty = "";
    let countryEmpty = "";
    let stateEmpty = "";
    let cityEmpty = "";
    let phoneEmpty = "";
    let zipEmpty = "";
    // let vehicle_idEmpty = "";
    let shift_startimeEmpty = "";
    let shift_endtimeEmpty = "";
    let shift_typeEmpty = "";
    let formIsValid = true;

    if (!name.trim()) {
      nameEmpty = "Please enter name.";
      formIsValid = false;
    }

    if (!email_address.trim()) {
      email_addressEmpty = "Please enter email address.";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(email_address)) {
      email_addressEmpty = "Email format is not valid.";
      formIsValid = false;
    }
    if (!street_address.trim()) {
      street_addressEmpty = "Please enter address.";
      formIsValid = false;
    }
    if (typeof country === "string" && !country.trim()) {
      countryEmpty = "Please select country.";
      formIsValid = false;
    }

    if (typeof state === "string" && !state.trim()) {
      stateEmpty = "Please select state.";
      formIsValid = false;
    }
    if (!city.trim()) {
      cityEmpty = "Please enter city.";
      formIsValid = false;
    }
    if (!phone.trim()) {
      phoneEmpty = "Please enter phone number.";
      formIsValid = false;
    }
    if (!zip.trim()) {
      zipEmpty = "Please enter zip code.";
      formIsValid = false;
    }

    // if (typeof vehicle_id === "string" && !vehicle_id.trim()) {
    //   vehicle_idEmpty = "Please select name.";
    //   formIsValid = false;
    // }
    if (!shift_type.trim()) {
      shift_typeEmpty = "Please select sift type.";
      formIsValid = false;
    }
    if (!shift_startime.trim()) {
      shift_startimeEmpty = "Please select sift start time.";
      formIsValid = false;
    }
    if (!shift_endtime.trim()) {
      shift_endtimeEmpty = "Please select sift end time.";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: {
        nameEmpty,
        email_addressEmpty,
        street_addressEmpty,
        countryEmpty,
        stateEmpty,
        cityEmpty,
        phoneEmpty,
        zipEmpty,
        // vehicle_idEmpty,
        shift_startimeEmpty,
        shift_endtimeEmpty,
        shift_typeEmpty,
      },
    });
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    console.log("🚀 ~ handleSubmit ~ formIsValid:", formIsValid)
    if (formIsValid) {
      setIsSubmitLoading(true)
      const data = {
        name,
        email_address,
        street_address,
        country,
        state,
        city,
        phone,
        zip,
        vehicle_id,
        shift_type,
        shift_startime,
        shift_endtime,
        driver_assigned_id,
        // rate: Number(rate),
        id: driverId,
      };
      try {
        const res = await dispatch(editDrivers(data));
        if (res) {
          handleShow();
          setIsSubmitLoading(false)
        }
      } catch (err) {
        setIsSubmitLoading(false)
        if (err.response && err.response.status === 400) {
          toast.error(err?.response?.data?.messsage, {
            position: toast?.POSITION?.TOP_RIGHT,
          });
        } else {
          console.log(`error>>>> ${err}`);
        }
      }
    }
  };

  const handleReset = () => {
    dispatch(driverDetails(driverId));
  };

  useEffect(() => {
    const getVehicleData = async () => {
      try {
        await dispatch(allBookingVehicleLists(localStorage.getItem("assignedDriverId")))
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };



    getVehicleData();

  }, [dispatch, allBookingVehicleLists]);



  return (
    <>
      <div className="dbCon">
        <VendorMenu />
        <div className="dbRight">
          {/* <header className="dbHeader d-flex align-items-center justify-content-between">
            <div className="dbHd">
              <NavLink to="/drivers" className="me-1">
                <IoMdArrowRoundBack />
              </NavLink>{" "}
              Edit Driver
            </div>
            <DashboardTopRight />
          </header> */}
          <div className="outerBox">
            <div className="dbSubHd mb-3">Driver Info</div>
            <ul className="form d-flex flex-wrap p-0 pb-5">
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Name</label>
                <input
                  type="text"
                  className="formInput"
                  name="name"
                  value={name}
                  onChange={handleChange}
                />
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Email Address</label>
                <input
                  type="text"
                  className="formInput"
                  name="email_address"
                  value={email_address}
                  onChange={handleChange}
                />
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Phone</label>
                <input
                  type="text"
                  className="formInput"
                  name="phone"
                  value={phone}
                  onChange={handleChange}
                />
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Street Address</label>
                <input
                  type="text"
                  className="formInput"
                  name="street_address"
                  value={street_address}
                  onChange={handleChange}
                />
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Country</label>
                <select
                  className="formSelect"
                  name="country"
                  value={country}
                  onChange={handleCountry}
                >
                  <option>Select</option>
                  {countryList?.length > 0
                    ? countryList?.map((item, i) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                    : ""}
                </select>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">State/Province</label>
                <select
                  className="formSelect"
                  name="state"
                  value={state}
                  onChange={handleChange}
                >
                  {stateList?.length > 0
                    ? stateList?.map((item, i) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                    : ""}
                </select>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">City</label>
                <input
                  type="text"
                  className="formInput"
                  name="city"
                  value={city}
                  onChange={handleChange}
                />
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">ZIP/Postal Code</label>
                <input
                  type="text"
                  className="formInput"
                  name="zip"
                  value={zip}
                  onChange={handleChange}
                />
              </li>

              {/* <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Fare Rate</label>
                <input
                  type="number"
                  className="formInput"
                  name="rate"
                  value={rate}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors?.rateEmpty}
                </span>
              </li> */}
            </ul>
            <div className="dbSubHd mb-3">Duty Schedule Info</div>
            <ul className="form d-flex flex-wrap p-0 pb-2">
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">{"Vehicle (Licence number)"}</label>
                <select
                  className="formSelect"
                  name="vehicle_id"
                  value={vehicle_id}
                  onChange={handleInformtion}
                >
                  <option value="">Select</option>
                  {allBookingVehicleList?.length > 0
                    ? allBookingVehicleList?.map((item, i) => {

                      return (
                        <option value={item.id}>{item.license_number}</option>
                      )
                    }
                    )
                    : vehicleDropdownList?.length > 0 ? vehicleDropdownList?.map((item, i) => {

                      return (
                        <option value={item.id}>{item.license_number}</option>
                      )
                    }
                    ) : ""}
                </select>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Vehicle Type</label>
                {allBookingVehicleList?.length > 0
                  ? allBookingVehicleList?.filter((item) => item.id == vehicleDetails)
                    .map((item, i) => {
                      return (
                        <input
                          type="text"
                          className="formInput"
                          value={item.vehicle_type}
                          readOnly
                        // onChange={handleChange}
                        />
                      )
                    }
                    )
                  : vehicleDropdownList?.length > 0 ? vehicleDropdownList?.filter((item) => item.id == vehicleDetails)
                    .map((item, i) => {
                      return (
                        <input
                          type="text"
                          className="formInput"
                          value={item.vehicle_type}
                          readOnly
                        // onChange={handleChange}
                        />
                      )
                    }
                    ) : ""}
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Shift Type</label>
                <select
                  className="formSelect"
                  name="shift_type"
                  value={shift_type}
                  onChange={handleChange}
                >
                  <option value="Monthly">Monthly</option>
                  <option value="Biweekly">Biweekly</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Daily">Daily</option>
                </select>
              </li>
              <li className="col-12 col-md-4 col-lg-3 d-flex">
                <div className="col-6 pe-1">
                  <label className="filedHd">Shift Time (From)</label>
                  <div className="position-relative">
                    <input
                      type="time"
                      className="formInput"
                      name="shift_startime"
                      value={shift_startime}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-6 ps-1">
                  <label className="filedHd">Shift Time (To)</label>
                  <div className="position-relative">
                    <input
                      type="time"
                      className="formInput"
                      name="shift_endtime"
                      value={shift_endtime}
                      onChange={handleChange}
                    />{" "}
                  </div>
                </div>
              </li>
            </ul>
            <div className="pt-4">
              <Button className="smallBtn cancel me-3" onClick={handleReset}>
                Reset
              </Button>
              <Button className="smallBtn auto" onClick={handleSubmit}>
                {isSubmitLoading ? <div class="spinner-border" role="status">
                  <span class="sr-only p-0"></span>
                </div> : "Update"}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show}>
        <Button className="closePopup" onClick={handleClose}>
          <IoCloseSharp />
        </Button>
        <DriverUpdate onClose={handleClose} />
      </Modal>
    </>
  );
};
export default EditDriver;
