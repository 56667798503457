import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import SchoolMenu from "../../component/SchoolMenu";
import SchoolTopRight from "../../component/SchoolTopRight";
import Button from "react-bootstrap/Button";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoSearchSharp } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import { IoCloseSharp } from "react-icons/io5";
import { BiSolidError } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import DocumentPreview from "../../component/DocumentPreview";
import { useDebounce } from 'use-debounce';


import {
  bookingVehicleLists,
  deleteBookingVehicles,
  bookingDetails,
} from "../../redux/action/schoolAction";

const initialState = {
  search: "",
  page: 1,
  date: "",
  showingDate: ""
};

const Mybookingschool = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showDetail, setShowDetail] = useState(false);
  const handleCloseDetail = () => setShowDetail(false);
  const [perPage, SetcurrentPage] = useState(10);
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const listsData = useSelector((state) => state.vehicle);
  const { bookingVehicleList, loader } = listsData;
  const detailData = useSelector((state) => state.school);
  const { bookingDetailDatas } = detailData;
  const [iState, updateState] = useState(initialState);
  const { search, page, date, showingDate } = iState;
  const [value] = useDebounce(search, 300);

  useEffect(() => {
    let obj = { search: search, page: page, date: date };
    dispatch(bookingVehicleLists(obj));
  }, [value])
  const handleSearch = (e) => {
    const { name, value } = e.target;
    updateState({
      search: value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let obj = { search: search, page: page, date: date };
        await dispatch(bookingVehicleLists(obj));
      } catch (error) {
        console.error("Error fetching vehicle lists:", error);
      }
    };
    fetchData();
    SetcurrentPage(10 * Number(page));
  }, [dispatch]);

  const bokkingDetails = async (id) => {
    const res = await dispatch(bookingDetails(id));
    if (res) {
      setShowDetail(true);
    }
  };
  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };

  const handleDelte = async () => {
    try {
      const res = await dispatch(deleteBookingVehicles(id));
      if (res) {
        toast.success("Reserved Vehicle Deleted Successfully!", {
          position: toast?.POSITION?.TOP_RIGHT,
        });
        setShow(false);
        let obj = { search: search, page: page };
        dispatch(bookingVehicleLists(obj));
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(err?.response?.data?.messsage, {
          position: toast?.POSITION?.TOP_RIGHT,
        });
      } else {
        console.log(`error>>>> ${err}`);
      }
    }
  };

  const handlePageChange = async ({ selected }) => {
    let selectedPage = selected + 1;
    SetcurrentPage(10 * Number(selectedPage));
    let obj = { search: search, page: selectedPage, date: date };
    await dispatch(bookingVehicleLists(obj));
    updateState({
      page: selectedPage,
    });
  };

  const handleDateChange = async (date) => {
    const parseData = moment(date, 'DD-MM-YYYY');
    const formattedDate = parseData.format('YYYY-MM-DD');
    let obj = { search: search, page: page, date: formattedDate };
    if (formattedDate === "Invalid date") {
      let obj = { search: search, page: page, date: "" };
      await dispatch(bookingVehicleLists(obj));
      updateState({
        date: "",
        showingDate: date
      });
      return
    }
    await dispatch(bookingVehicleLists(obj));
    updateState({
      date: formattedDate,
      showingDate: date
    });
  };

  return (
    <>
      <div className="dbCon">
        <SchoolMenu />
        <div className="dbRight">
          <div>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="mb-2 total">
                Total Reservations: {bookingVehicleList?.pagination_data?.total}
              </div>

              <div className="mb-2 filterBox">
                <ul className="d-flex flex-wrap align-items-center p-0 transactionsFilter">

                  <li>
                    <div className="searchBox">
                      <input
                        type="text"
                        className="input"
                        placeholder="Search.."
                        name="search"
                        value={search}
                        onChange={handleSearch}
                      />{" "}
                      <Button className="searchBtn">
                        <IoSearchSharp />
                      </Button>
                    </div>
                  </li>

                  <li className="date">
                    <div className="smallDatepicker position-relative">
                      <DatePicker
                        selected={showingDate}
                        onChange={(date) => handleDateChange(date)}
                      />{" "}
                      <FaCalendarAlt className="icon" />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="table m-0">
              <table>
                <tr>
                  <th>Sr.No.</th>
                  <th>Vendor Name</th>
                  <th>Driver Name</th>
                  <th>Reservation Repeat</th>
                  <th>Reservation Type</th>
                  <th>Vehicle Type</th>
                  <th>Vehicle License Plate</th>
                  <th>Rate</th>
                  <th>Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Route Details</th>
                  <th>Action</th>
                </tr>
                {loader !== false ?
                  <tr className="text-center">
                    <td colSpan="12">
                      <div class="spinner-border" role="status">
                        <span class="sr-only p-0"></span>
                      </div>
                    </td>
                  </tr>
                  : bookingVehicleList?.data?.length > 0
                    ? bookingVehicleList?.data?.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1 + (perPage - 10)}</td>
                        <td className="text-nowrap">{`${item?.vehicle?.vehicle?.user?.company_name}`}</td>
                        <td>{item?.vehicle?.driver?.name}</td>
                        <td>{item.booking_repeat}</td>
                        <td>{item.booking_type}</td>
                        <td className="text-nowrap">
                          {item?.vehicle?.vehicle?.vechile_type?.type}
                        </td>
                        <td>{item?.vehicle?.vehicle?.license_number}</td>
                        <td className="text-nowrap">
                          ${item.total_rate ? item.total_rate : 0}
                        </td>
                        <td>
                          {moment(item.start_date).format("MMMM-DD-YYYY")}
                        </td>
                        <td>{item.shift_startime}</td>
                        <td>{item.shift_endtime}</td>
                        <td>{item.route_detail && <DocumentPreview size="small" preview={`https://admin.aeon-app.com/${item.route_detail}`} />}</td>
                        <td>
                          <div className="actionBtn d-flex">
                            <Button
                              title="Reservation Details"
                              className="rounded-circle iconBtn me-2"
                              onClick={() => bokkingDetails(item.id)}
                            >
                              <svg
                                id="Group_3073"
                                data-name="Group 3073"
                                xmlns="http://www.w3.org/2000/svg"
                                width="22.592"
                                height="22.592"
                                viewBox="0 0 22.592 22.592"
                              >
                                <path
                                  id="calendar-check"
                                  d="M17.886,1.883h-.941V.941a.941.941,0,0,0-1.883,0v.941H7.531V.941a.941.941,0,1,0-1.883,0v.941H4.707A4.712,4.712,0,0,0,0,6.589v11.3a4.712,4.712,0,0,0,4.707,4.707H17.886a4.712,4.712,0,0,0,4.707-4.707V6.589A4.712,4.712,0,0,0,17.886,1.883ZM4.707,3.765H17.886A2.827,2.827,0,0,1,20.71,6.589v.941H1.883V6.589A2.827,2.827,0,0,1,4.707,3.765ZM17.886,20.71H4.707a2.827,2.827,0,0,1-2.824-2.824V9.413H20.71v8.472A2.827,2.827,0,0,1,17.886,20.71Z"
                                  fill="#727782"
                                />
                                <g
                                  id="refresh"
                                  transform="translate(6.776 10.498)"
                                >
                                  <path
                                    id="Path_1637"
                                    data-name="Path 1637"
                                    d="M3.591,4.063a3.425,3.425,0,0,1,5.615-2.1L8.764,2.4a.379.379,0,0,0,.268.647H10.77a.379.379,0,0,0,.379-.379V.931A.379.379,0,0,0,10.5.663l-.493.493A4.557,4.557,0,0,0,2.469,3.9a.567.567,0,1,0,1.122.166Z"
                                    transform="translate(-2.461 0)"
                                    fill="#727782"
                                  />
                                  <path
                                    id="Path_1638"
                                    data-name="Path 1638"
                                    d="M30.356,256.891a.574.574,0,0,0-.563.5,3.425,3.425,0,0,1-5.615,2.1l.441-.441a.379.379,0,0,0-.268-.647H22.614a.379.379,0,0,0-.379.379v1.738a.379.379,0,0,0,.647.268l.493-.493a4.556,4.556,0,0,0,7.541-2.742.574.574,0,0,0-.474-.659A.555.555,0,0,0,30.356,256.891Z"
                                    transform="translate(-21.884 -252.328)"
                                    fill="#727782"
                                  />
                                </g>
                              </svg>
                            </Button>
                            <Button
                              title="Delete"
                              className="rounded-circle"
                              onClick={() => handleShow(item.id)}
                            >
                              <RiDeleteBin6Fill />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))
                    : <tr className="text-center">
                      <td colSpan="12">
                        <div>
                          <span>No vehicle found</span>
                        </div>
                      </td>
                    </tr>}
              </table>
            </div>
            <ReactPaginate
              pageCount={Math.ceil(
                bookingVehicleList?.pagination_data?.total / 10
              )}
              onPageChange={handlePageChange}
              containerClassName={"paging m-0 p-0 pt-3 d-flex"}
              activeClassName={"active"}
              previousLabel={"Previous"}
              nextLabel={"Next"}
            />
          </div>
        </div>
      </div>
      <Modal show={showDetail}>
        <Button className="closePopup" onClick={handleCloseDetail}>
          <IoCloseSharp />
        </Button>
        <div className="popupBox p-3 p-md-5">
          <div>
            <div>
              <div className="bookingDetBox p-3 p-md-4 mb-4">
                <span className="hd pb-2">Reservation Details</span>
                <ul className="detInfo p-0 m-0">
                  <li>
                    <span>Vendor:</span>{" "}
                    {`${bookingDetailDatas &&
                      bookingDetailDatas?.vehicle?.vehicle?.user?.company_name
                      }`}
                  </li>
                  <li>
                    <span>Vehicle:</span>{" "}
                    {`${bookingDetailDatas &&
                      bookingDetailDatas?.vehicle?.vehicle?.make?.name
                      } ${bookingDetailDatas &&
                      bookingDetailDatas?.vehicle?.vehicle?.model?.name
                      }`}
                  </li>
                  <li>
                    <span>Vehicle License Plate:</span>
                    {bookingDetailDatas &&
                      bookingDetailDatas?.vehicle?.vehicle.license_number}
                  </li>
                  <li>
                    <span>Seats:</span>{" "}
                    {bookingDetailDatas &&
                      bookingDetailDatas?.vehicle?.vehicle.seat}
                  </li>
                  <li>
                    <span>Fare Rate:</span> $
                    {bookingDetailDatas && bookingDetailDatas?.total_rate}
                  </li>
                  <li>
                    <span>From:</span>  {`${bookingDetailDatas && bookingDetailDatas?.vehicle?.vehicle?.user?.address}`}
                  </li>
                  <li>
                    <span>Destination:</span>  {bookingDetailDatas && bookingDetailDatas?.destination_address ? bookingDetailDatas && bookingDetailDatas?.destination_address : bookingDetailDatas && bookingDetailDatas?.school?.address}
                  </li>
                  <li>
                    <span>
                      Date:
                      {moment(
                        bookingDetailDatas && bookingDetailDatas?.start_date
                      ).format("MMMM-DD-YYYY")}
                    </span>
                  </li>
                  <li>
                    <span>Time:</span>{" "}
                    {bookingDetailDatas && bookingDetailDatas?.shift_startime} -{" "}
                    {bookingDetailDatas && bookingDetailDatas?.shift_endtime}
                  </li>
                </ul>
              </div>
              <div className="text-center">
                <Button
                  className="smallBtn cancel me-3"
                  onClick={handleCloseDetail}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={show}>
        <Button className="closePopup" onClick={handleClose}>
          <IoCloseSharp />
        </Button>
        <div className="popupBox p-3 p-md-5">
          <div>
            <div className="text-center mb-3 errorIcon">
              <BiSolidError />
            </div>
            <div className="text-center mb-4 deleteHd">
              Are you sure you want to delete?
            </div>
            <div className="text-center">
              <Button className="smallBtn cancel me-3" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="smallBtn auto" onClick={handleDelte}>
                Delete
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Mybookingschool;
