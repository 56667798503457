import React, { useState, useEffect } from "react";
import "./VendorMenu.scss";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { IoSearchSharp } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getVendorPaymentHistoryData } from "../redux/action/vendorAction"
import ReactPaginate from "react-paginate";
import moment from "moment";

const initialState = {
  page: 1,
};

const WithdrawTransactions = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [iState, updateState] = useState(initialState);
  const [perPage, SetcurrentPage] = useState(10);
  const { page } = iState;
  const dispatch = useDispatch()
  const detailData = useSelector((state) => state.vendor);
  const { vendorPaymentHistoryData, loader } = detailData;


  useEffect(() => {
    const fetchData = async () => {
      try {
        let obj = { page: page };
        await dispatch(getVendorPaymentHistoryData(obj));
      } catch (error) {
        console.error("Error fetching vehicle lists:", error);
      }
    };
    fetchData();
    SetcurrentPage(10 * Number(page));
  }, [dispatch]);

  const handlePageChange = async ({ selected }) => {
    let selectedPage = selected + 1;
    SetcurrentPage(10 * Number(selectedPage));
    let obj = { page: selectedPage };
    await dispatch(getVendorPaymentHistoryData(obj));
    updateState({
      page: selectedPage,
    });
  };


  return (
    <>
      <div>
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="mb-2 total">Transactions: {vendorPaymentHistoryData?.pagination_data?.total}</div>
          {/* <div className="mb-2 filterBox">
            <ul className="d-flex flex-wrap align-items-center p-0 transactionsFilter">
              <li>
                <div className="searchBox">
                  <input type="text" className="input" placeholder="Search.." />{" "}
                  <Button className="searchBtn">
                    <IoSearchSharp />
                  </Button>
                </div>
              </li>
              <li>
                <div className="smallDatepicker position-relative">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />{" "}
                  <FaCalendarAlt className="icon" />
                </div>
              </li>
              <li>
                <select className="smallSelect">
                  <option>Driver</option>
                </select>
              </li>
            </ul>
          </div> */}
        </div>
        <div className="table m-0">
          <table>
            <tr>
              <th>Sr.No.</th>
              {/* <th>Transaction ID</th> */}
              <th>Client Name</th>
              <th>Vehicle License Plate</th>
              <th>Driver Name</th>
              <th>Payment Date</th>
              <th>Payment Type</th>
              <th>Amount</th>
            </tr>
            {loader !== false ?
              <tr className="text-center">
                <td colSpan="12">
                  <div class="spinner-border" role="status">
                    <span class="sr-only p-0"></span>
                  </div>
                </td>
              </tr>
              : vendorPaymentHistoryData?.data?.length > 0
                ? vendorPaymentHistoryData?.data?.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1 + (perPage - 10)}</td>
                    {/* <td>123AB456XYZ</td> */}
                    <td>{`${item.school.full_name}`}</td>
                    <td>{item.vehicle.vehicle.license_number}</td>
                    <td>{item.vehicle.driver.name}</td>
                    <td className="text-nowrap">{moment(item.payment_transfer_datatime

                    ).format("MMMM-DD-YYYY HH:mm")}</td>
                    <td>Offline</td>
                    <td>${!!item.payment_status ? item.payment_status : 0
                    }</td>
                  </tr>
                ))
                : <tr className="text-center">
                <td colSpan="12">
                  <div>
                    <span>No transaction found</span>
                  </div>
                </td>
              </tr>}

          </table>
        </div>
        <ReactPaginate
          pageCount={Math.ceil(
            vendorPaymentHistoryData?.pagination_data?.total / 10
          )}
          onPageChange={handlePageChange}
          containerClassName={"paging m-0 p-0 pt-3 d-flex"}
          activeClassName={"active"}
          previousLabel={"Previous"}
          nextLabel={"Next"}
        />
      </div>
    </>
  );
};
export default WithdrawTransactions;
