import { loginAction } from "../actionType";
const initialState = {
    loader: false,
    loginData: {}
};
export default function login(state = initialState, { type, payload }) {
    switch (type) {
        case loginAction.LOGIN_INITIATE: {
            return {
                ...state,
                loader: true,
                loginData: {},
            };
        }
        case loginAction.LOGIN_SUCCESS: {
            return {
                ...state,
                loader: false,
                loginData: payload,
            };
        }
        case loginAction.LOGIN_FAILURE: {
            return {
                ...state,
                loader: false,
                loginData: {},
            };
        }

        default:
            return state;
    }
}
