import React, { useState, useEffect } from "react";
import "./AddVehicle.scss";
import VendorMenu from "../component/VendorMenu";
import Button from "react-bootstrap/Button";
import DashboardTopRight from "../component/DashboardTopRight";
import { IoAddCircleOutline } from "react-icons/io5";
import Spacer from "../assets/images/spacer.png";
import { RiDeleteBin5Line } from "react-icons/ri";
import Modal from "react-bootstrap/Modal";
import { NavLink, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import VehicleUpdate from "../component/VehicleUpdate";
import { IoCloseSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  vehicleDetails,
  vehicleYear,
  vehicleMake,
  vehicleModel,
  vehicleType,
  vehicleColor,
  editVehicles,
  deleteVehicleImages,
} from "../redux/action/vehicleAction";
import DocumentPreview from "../component/DocumentPreview";

import _ from "lodash";

const initialState = {
  make: "",
  model: "",
  color: "",
  year: "",
  vechile_type: "",
  license_number: "",
  imagesReview: [],
  new_images: [],
  seat: "",
  vin: "",
  insurance_documents: "",
  permit_documents: "",
  curennt_mileage: "",
  errors: "",
  fare_rate: "",
  insurance_preview: "",
  insurance_preview_name: "",
  permit_preview: "",
  permit_preview_name: "",
};

const EditVehicle = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [InsuranceDocument, setInsuranceDocument] = useState("");
  const [Permit, setPermit] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [formData, setFormData] = useState({})

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iState, updateState] = useState(initialState);
  const detailData = useSelector((state) => state.vehicle);
  const {
    vehicledDetailData,
    vehicleYearList,
    vehicleMakeList,
    vehicleModelList,
    vehicleTypeList,
    vehicleColorList,
  } = detailData;
  let vehicleId = localStorage.getItem("vehicleId");
  const {
    make,
    model,
    color,
    year,
    vechile_type,
    license_number,
    imagesReview,
    seat,
    vin,
    insurance_documents,
    permit_documents,
    curennt_mileage,
    new_images,
    errors,
    fare_rate,
    insurance_preview,
    permit_preview,
    insurance_preview_name,
    permit_preview_name
  } = iState;

  useEffect(() => {
    dispatch(vehicleDetails(vehicleId));
  }, [vehicleDetails]);

  useEffect(() => {
    if (!_.isEmpty(vehicledDetailData)) {
      const updateData = _.cloneDeep(iState);
      updateData.imagesReview =
        (vehicledDetailData && vehicledDetailData?.images) || "";
      updateData.license_number =
        (vehicledDetailData && vehicledDetailData?.license_number) || "";

      updateData.year =
        (vehicledDetailData && vehicledDetailData?.year?.id) || "";
      updateData.make =
        (vehicledDetailData && vehicledDetailData?.make?.id) || "";
      updateData.model =
        (vehicledDetailData && vehicledDetailData?.model?.id) || "";
      updateData.color =
        (vehicledDetailData && vehicledDetailData?.color?.id) || "";
      updateData.vechile_type =
        (vehicledDetailData && vehicledDetailData?.vechile_type?.id) || "";
      updateData.seat = (vehicledDetailData && vehicledDetailData?.seat) || "";
      updateData.curennt_mileage =
        (vehicledDetailData && vehicledDetailData?.curennt_mileage) || "";
      updateData.vin = (vehicledDetailData && vehicledDetailData?.vin) || "";
      updateData.fare_rate =
        (vehicledDetailData && vehicledDetailData?.fare_rate) || "";
      updateData.insurance_documents =
        (vehicledDetailData && vehicledDetailData?.insurance_documents) || "";
      updateData.permit_documents =
        (vehicledDetailData && vehicledDetailData?.permit_documents) || "";
      updateState(updateData);
    }
  }, [vehicledDetailData]);

  useEffect(() => {
    dispatch(vehicleYear());
  }, [vehicleYear]);

  useEffect(() => {
    dispatch(vehicleMake());
  }, [vehicleMake]);
  useEffect(() => {
    dispatch(vehicleType());
  }, [vehicleType]);

  useEffect(() => {
    dispatch(vehicleColor());
  }, [vehicleColor]);

  const handleClose = () => {
    setShow(false);
    navigate("/vehicles");
  };

  const fileHandler = (e) => {
    const file = e.target.files;
    const test = e.target.files[0];
    if (test) {
      if (
        test.type === "image/jpeg" ||
        test.type === "image/jpg" ||
        test.type === "image/png"
      ) {
        const updatedImagesReview = [...imagesReview];

        for (let i = 0; i < file.length; i++) {
          updatedImagesReview.push(URL.createObjectURL(file[i]));
          new_images.push(file[i]);
        }

        updateState({
          ...iState,
          new_images: new_images,
          imagesReview: updatedImagesReview,
        });
      } else {
        toast.error("Only formats are allowed: jpeg, jpg, png files", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const insuranceFileHandler = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        const preview = URL.createObjectURL(file)
        updateState({
          ...iState,
          insurance_documents: file,
          insurance_preview: preview,
          insurance_preview_name: file.name
        });
        setFormData({ ...formData, insurance_documents: file })
      } else {
        toast.error("Only formats are allowed: jpeg, jpg, png, doc, docx files", {
          position: toast?.POSITION?.TOP_RIGHT,
        });
      }
    }
  };

  const permitFileHandler = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        const preview = URL.createObjectURL(file)
        updateState({
          ...iState,
          permit_documents: file,
          permit_preview: preview,
          permit_preview_name: file.name
        });
        setFormData({ ...formData, permit_documents: file })
      } else {
        toast.error("Only formats are allowed: jpeg, jpg, png, doc, docx files", {
          position: toast?.POSITION?.TOP_RIGHT,
        });
      }
    }
  };

  const clearInsurance = () => {
    updateState({
      ...iState,
      insurance_documents: "",
      insurance_preview: "",
      insurance_preview_name: "",
    });
  }

  const clearPermit = () => {
    updateState({
      ...iState,
      permit_documents: "",
      permit_preview: "",
      permit_preview_name: "",
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  useEffect(() => {
    if (make) {
      dispatch(vehicleModel(make));
    }
  }, [make]);

  const handleMakeChange = (e) => {
    const { name, value } = e.target;
    dispatch(vehicleModel(value));
    updateState({
      ...iState,
      make: value,
    });
  };

  let handleValidation = () => {
    let yearEmpty = "";
    let makeEmpty = "";
    let modelEmpty = "";
    let vechile_typeEmpty = "";
    let colorEmpty = "";
    let license_numberEmpty = "";
    let seatEmpty = "";
    let vinEmpty = "";
    let curennt_mileageEmpty = "";
    let fare_rateEmpty = ""
    let formIsValid = true;

    // Check if year is a string before calling trim
    if (typeof year === "string" && !year.trim()) {
      yearEmpty = "Please select year.";
      formIsValid = false;
    }

    if (typeof make === "string" && !make.trim()) {
      makeEmpty = "Please select make.";
      formIsValid = false;
    }
    if (typeof model === "string" && !model.trim()) {
      modelEmpty = "Please select model.";
      formIsValid = false;
    }
    if (typeof vechile_type === "string" && !vechile_type.trim()) {
      vechile_typeEmpty = "Please select vehicle type.";
      formIsValid = false;
    }

    if (typeof color === "string" && !color.trim()) {
      colorEmpty = "Please select color.";
      formIsValid = false;
    }
    if (!license_number.trim()) {
      license_numberEmpty = "Please enter license number.";
      formIsValid = false;
    }
    if (!seat.trim()) {
      seatEmpty = "Please enter seat number.";
      formIsValid = false;
    }
    if (!vin.trim()) {
      vinEmpty = "Please enter vin.";
      formIsValid = false;
    }

    if (!curennt_mileage.trim()) {
      curennt_mileageEmpty = "Please enter curennt mileage.";
      formIsValid = false;
    }
    
    const changeNumToString = fare_rate.toString()
    if (!changeNumToString.trim()) {
      curennt_mileageEmpty = "Please enter vehicle rate.";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: {
        yearEmpty,
        makeEmpty,
        modelEmpty,
        vechile_typeEmpty,
        colorEmpty,
        license_numberEmpty,
        seatEmpty,
        vinEmpty,
        curennt_mileageEmpty,
        fare_rateEmpty,
      },
    });
    return formIsValid;
  };

  const handleFormData = (e) => {
    const { name, value } = e.target
    if (name) {
      setFormData({ ...formData, [name]: value })
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitLoading(true)
    let formIsValid = handleValidation();
    if (formIsValid) {
      // const data = {
      //   make,
      //   model,
      //   color,
      //   year,
      //   vechile_type,
      //   license_number,
      //   images: vehicledDetailData && vehicledDetailData?.images,
      //   new_images: new_images,
      //   seat,
      //   vin,
      //   insurance_documents: insurance_documents && vehicledDetailData?.insurance_documents,
      //   permit_documents: permit_documents && vehicledDetailData?.permit_documents,
      //   curennt_mileage,
      //   id: vehicleId,
      // };
      try {
        const res = await dispatch(editVehicles(formData, vehicleId));
        if (res) {
          handleShow();
          setIsSubmitLoading(false)

          // toast.success("Vehicle Added Successfully!", {
          //   position: toast?.POSITION?.TOP_RIGHT,
          // });
        }
      } catch (err) {
        setIsSubmitLoading(false)
        if (err.response && err.response.status === 400) {
          toast.error(err?.response?.data?.messsage, {
            position: toast?.POSITION?.TOP_RIGHT,
          });
        } else {
          console.log(`error>>>> ${err}`);
        }
      }
    }
  };

  const deleteImage = async (image_id) => {
    try {
      const res = await dispatch(deleteVehicleImages(image_id));
      if (res) {
        toast.success("Vehicle Image Deleted Successfully!", {
          position: toast?.POSITION?.TOP_RIGHT,
        });
        dispatch(vehicleDetails(vehicleId));
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(err?.response?.data?.messsage, {
          position: toast?.POSITION?.TOP_RIGHT,
        });
      } else {
        console.log(`error>>>> ${err}`);
      }
    }
  };

  const deleteNewImage = async (i) => {
    new_images.splice(i, 1);
    imagesReview.splice(i, 1);
    updateState({
      ...iState,
      new_images,
      imagesReview,
    });
  };

  const handleReset = () => {
    dispatch(vehicleDetails(vehicleId));
  };

  return (
    <>
      <div className="dbCon">
        <VendorMenu />
        <div className="dbRight">
          <form onChange={handleFormData}>
            <div className="outerBox">
              <div className="dbSubHd mb-3">Edit Vehicle Details</div>
              <ul className="addImg d-flex flex-wrap p-0">
                {imagesReview && imagesReview.length !== 0 ? (
                  <>
                    {imagesReview.map((image, index) => (
                      <li key={index} className="col-12 col-sm-4">
                        <div className="upload d-flex align-items-center justify-content-center flex-column">
                          <span>
                            {image.vehicle_image ? (
                              <img
                                src={`${process.env.REACT_APP_SERVER_URL}${image.vehicle_image || image
                                  }`}
                                alt=""
                              />
                            ) : (
                              <img src={image} alt="" />
                            )}
                            {image?.image_id ? (
                              <Button
                                className="editBtn"
                                onClick={() => deleteImage(image?.image_id)}
                              >
                                <RiDeleteBin5Line />
                              </Button>
                            ) : (
                              <Button
                                className="editBtn"
                                onClick={() => deleteNewImage(index)}
                              >
                                <RiDeleteBin5Line />
                              </Button>
                            )}
                          </span>
                        </div>
                      </li>
                    ))}
                  </>
                ) : (
                  ""
                )}
                <li className="col-12 col-sm-4">
                  <div className="upload d-flex align-items-center justify-content-center flex-column">
                    <img src={Spacer} alt="" />
                    <div className="addBtn text-center">
                      <IoAddCircleOutline className="addicon mb-2" />
                      Add Vehicle Image
                    </div>
                    <input
                      type="file"
                      className="file"
                      onChange={fileHandler}
                      onClick={(e) => (e.target.value = null)}
                      accept=".png, .jpg, .jpeg"
                      multiple
                    />
                  </div>
                </li>
              </ul>
              <ul className="form d-flex flex-wrap p-0 pt-2">
                <li className="col-12 col-md-4 col-lg-3">
                  <label className="filedHd">License Plate</label>
                  <input
                    type="text"
                    className="formInput"
                    name="license_number"
                    value={license_number}
                    onChange={handleChange}
                  />
                  <span style={{ color: "red" }}>
                    {errors && errors?.license_numberEmpty}
                  </span>
                </li>
                <li className="col-12 col-md-4 col-lg-3">
                  <label className="filedHd">Year</label>
                  <select
                    className="formSelect"
                    name="year"
                    value={year}
                    onChange={handleChange}
                  >
                    {vehicleYearList?.length > 0
                      ? vehicleYearList?.map((item, i) => (
                        <option value={item.id}>{item.year}</option>
                      ))
                      : ""}
                  </select>
                  <span style={{ color: "red" }}>
                    {errors && errors?.yearEmpty}
                  </span>
                </li>
                <li className="col-12 col-md-4 col-lg-3">
                  <label className="filedHd">Make</label>
                  <select
                    className="formSelect"
                    name="make"
                    value={make}
                    onChange={handleMakeChange}
                  >
                    {vehicleMakeList?.length > 0
                      ? vehicleMakeList?.map((item, i) => (
                        <option value={item.id}>{item.name}</option>
                      ))
                      : ""}
                  </select>
                  <span style={{ color: "red" }}>
                    {errors && errors?.makeEmpty}
                  </span>
                </li>
                <li className="col-12 col-md-4 col-lg-3">
                  <label className="filedHd">Model</label>
                  <select
                    className="formSelect"
                    name="model"
                    value={model}
                    onChange={handleChange}
                  >
                    {vehicleMakeList?.length > 0
                      ? vehicleModelList?.map((item, i) => (
                        <option value={item.id}>{item.name}</option>
                      ))
                      : ""}
                  </select>
                  <span style={{ color: "red" }}>
                    {errors && errors?.modelEmpty}
                  </span>
                </li>
                <li className="col-12 col-md-4 col-lg-3">
                  <label className="filedHd">Color</label>
                  <select
                    className="formSelect"
                    name="color"
                    value={color}
                    onChange={handleChange}
                  >
                    {vehicleColorList?.length > 0
                      ? vehicleColorList?.map((item, i) => (
                        <option value={item.id}>{item.name}</option>
                      ))
                      : ""}
                  </select>
                  <span style={{ color: "red" }}>
                    {errors && errors?.colorEmpty}
                  </span>
                </li>
                <li className="col-12 col-md-4 col-lg-3">
                  <label className="filedHd">Seat</label>
                  <input
                    type="number"
                    className="formInput"
                    name="seat"
                    value={seat}
                    onChange={handleChange}
                  />
                  <span style={{ color: "red" }}>
                    {errors && errors?.seatEmpty}
                  </span>
                </li>
                <li className="col-12 col-md-4 col-lg-3">
                  <label className="filedHd">Current Mileage</label>
                  <input
                    type="text"
                    className="formInput"
                    name="curennt_mileage"
                    value={curennt_mileage}
                    onChange={handleChange}
                  />
                  <span style={{ color: "red" }}>
                    {errors && errors?.curennt_mileageEmpty}
                  </span>
                </li>
                <li className="col-12 col-md-4 col-lg-3">
                  <label className="filedHd">VIN</label>
                  <input
                    type="text"
                    className="formInput"
                    name="vin"
                    value={vin}
                    onChange={handleChange}
                  />
                  <span style={{ color: "red" }}>
                    {errors && errors?.vinEmpty}
                  </span>
                </li>
                <li className="col-12 col-md-4 col-lg-3">
                  <label className="filedHd">Vehicle Type</label>
                  <select
                    className="formSelect"
                    name="vechile_type"
                    value={vechile_type}
                    onChange={handleChange}
                  >
                    {vehicleTypeList?.length > 0
                      ? vehicleTypeList?.map((item, i) => (
                        <option value={item.id}>{item.type}</option>
                      ))
                      : ""}
                  </select>
                  <span style={{ color: "red" }}>
                    {errors && errors?.vechile_typeEmpty}
                  </span>
                </li>
                <li className="col-12 col-md-4 col-lg-3">
                  <label className="filedHd">Condition</label>
                  <input
                    type="text"
                    className="formInput"
                    value="Trade-In Value Excellent"
                  />
                </li>
                <li className="col-12 col-md-4 col-lg-3">
                  <label className="filedHd">Tyre Condition</label>
                  <input
                    type="text"
                    className="formInput"
                    value="Trade-In Value Excellent"
                  />
                </li>
                <li className="col-12 col-md-4 col-lg-3">
                  <label className="filedHd">Vehicle Fare_rate</label>
                  <input
                    type="number"
                    className="formInput"
                    name="fare_rate"
                    value={fare_rate}
                    onChange={handleChange}
                  />
                  <span style={{ color: "red" }}>
                    {errors && errors?.fare_rateEmpty}
                  </span>
                </li>
                <li className="col-12 col-md-4 col-lg-3">
                  <label className="filedHd">Insurance Document</label>
                  {insurance_documents &&
                    <span
                      className="m-2"
                      onClick={clearInsurance}
                    >
                      <RiDeleteBin5Line />
                    </span>}
                  {!insurance_documents &&
                    <div className="uplaodBox d-flex align-items-center">
                      <Button className="btn">
                        Upload
                        <input
                          type="file"
                          className="file"
                          onChange={insuranceFileHandler}
                          onClick={(e) => (e.target.value = null)}
                          accept=".png, .jpg, .jpeg .doc .docx"
                        />
                      </Button>
                      {InsuranceDocument !== "" && (
                        <div className="ms-2"> {InsuranceDocument}.</div>
                      )}
                    </div>
                  }
                  {insurance_documents &&
                    <DocumentPreview preview={insurance_preview ? insurance_preview : `${process.env.REACT_APP_SERVER_URL}${insurance_documents}`} />
                  }
                </li>
                <li className="col-12 col-md-4 col-lg-3">
                  <label className="filedHd">DOT #/Permit</label>
                  {permit_documents &&
                    <span
                      className="m-2"
                      onClick={clearPermit}
                    >
                      <RiDeleteBin5Line />
                    </span>}
                  {!permit_documents &&
                    <div className="uplaodBox d-flex align-items-center">
                      <Button className="btn">
                        Upload
                        <input
                          type="file"
                          className="file"
                          onChange={permitFileHandler}
                          onClick={(e) => (e.target.value = null)}
                          accept=".png, .jpg, .jpeg .doc .docx"
                        />
                      </Button>
                      {Permit !== "" && <div className="ms-2"> {Permit}.</div>}
                    </div>
                  }
                  {permit_documents &&
                    <DocumentPreview preview={permit_preview ? permit_preview : `${process.env.REACT_APP_SERVER_URL}${permit_documents}`} />
                  }
                </li>
              </ul>
              <div className="pt-4">
                <Button className="smallBtn cancel me-3" onClick={handleReset}>
                  Reset
                </Button>
                <Button className="smallBtn auto" onClick={handleSubmit}>
                  {isSubmitLoading ? <div class="spinner-border" role="status">
                    <span class="sr-only p-0"></span>
                  </div> : "Update"}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal className="h-50" show={show}>
        <Button className="closePopup" onClick={handleClose}>
          <IoCloseSharp />
        </Button>
        <VehicleUpdate onClose={handleClose} />
      </Modal>
    </>
  );
};
export default EditVehicle;
