import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./AddVehicle.scss";
import VendorMenu from "../component/VendorMenu";
import Button from "react-bootstrap/Button";
import DashboardTopRight from "../component/DashboardTopRight";
import { IoAddCircleOutline } from "react-icons/io5";
import Spacer from "../assets/images/spacer.png";
import VehicleAdded from "../component/VehicleAdded";
import { IoCloseSharp } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  vehicleYear,
  vehicleMake,
  vehicleModel,
  vehicleType,
  vehicleColor,
  addVehicles,
} from "../redux/action/vehicleAction";
import DocumentPreview from "../component/DocumentPreview";

const initialState = {
  make: "",
  model: "",
  color: "",
  year: "",
  vechile_type: "",
  license_number: "",
  images: [],
  imagesReview: [],
  seat: "",
  vin: "",
  insurance_documents: "",
  permit_documents: "",
  curennt_mileage: "",
  errors: "",
  rate:"",
  insurance_preview: "",
  insurance_preview_name: "",
  permit_preview: "",
  permit_preview_name: "",
};

const AddVehicle = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [InsuranceDocument, setInsuranceDocument] = useState("");
  const [Permit, setPermit] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iState, updateState] = useState(initialState);
  const listsData = useSelector((state) => state.vehicle);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const {
    vehicleYearList,
    vehicleMakeList,
    vehicleModelList,
    vehicleTypeList,
    vehicleColorList,
  } = listsData;
  const {
    make,
    model,
    color,
    year,
    vechile_type,
    license_number,
    images,
    imagesReview,
    seat,
    vin,
    insurance_documents,
    permit_documents,
    curennt_mileage,
    errors,
    rate,
    insurance_preview,
    permit_preview,
    insurance_preview_name,
    permit_preview_name
  } = iState;

  useEffect(() => {
    dispatch(vehicleYear());
  }, [vehicleYear]);

  useEffect(() => {
    dispatch(vehicleMake());
  }, [vehicleMake]);
  useEffect(() => {
    dispatch(vehicleType());
  }, [vehicleType]);

  useEffect(() => {
    dispatch(vehicleColor());
  }, [vehicleColor]);

  const handleClose = () => {
    setShow(false);
    navigate("/vehicles");
  };

  const fileHandler = (e) => {
    const file = e.target.files;
    const test = e.target.files[0];
    if (test) {
      if (
        test.type === "image/jpeg" ||
        test.type === "image/jpg" ||
        test.type === "image/png"
      ) {
        for (let i = 0; i < file.length; i++) {
          imagesReview.push(URL.createObjectURL(file[i]));
          images.push(file[i]);
        }
        updateState({
          ...iState,
          imagesReview,
          images: images,
        });
      } else {
        toast.error("Only formats are allowed: jpeg, jpg, png, pdf, doc, docx files", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const insuranceFileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        const preview = URL.createObjectURL(file)
        updateState({
          ...iState,
          insurance_documents: file,
          insurance_preview: preview,
          insurance_preview_name: file.name
        });
      } else {
        toast.error("Only formats are allowed: jpeg, jpg, png, pdf, doc, docx files", {
          position: toast?.POSITION?.TOP_RIGHT,
        });
      }
    }
  };

  const permitFileHandler = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        const preview = URL.createObjectURL(file)
        updateState({
          ...iState,
          permit_documents: file,
          permit_preview: preview,
          permit_preview_name: file.name
        });
      } else {
        toast.error("Only formats are allowed: jpeg, jpg, png, pdf, doc, docx files", {
          position: toast?.POSITION?.TOP_RIGHT,
        });
      }
    }
  };

  const clearInsurance = () => {
    updateState({
      ...iState,
      insurance_documents: "",
      insurance_preview: "",
      insurance_preview_name: "",
    });
  }

  const clearPermit = () => {
    updateState({
      ...iState,
      permit_documents: "",
      permit_preview: "",
      permit_preview_name: "",
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleMakeChange = (e) => {
    const { name, value } = e.target;
    dispatch(vehicleModel(value));
    updateState({
      ...iState,
      make: value,
    });
  };

  let handleValidation = () => {
    let yearEmpty = "";
    let makeEmpty = "";
    let modelEmpty = "";
    let vechile_typeEmpty = "";
    let colorEmpty = "";
    let license_numberEmpty = "";
    let seatEmpty = "";
    let vinEmpty = "";
    let curennt_mileageEmpty = "";
    let formIsValid = true;

    if (!year.trim()) {
      yearEmpty = "Please select year.";
      formIsValid = false;
    }
    if (!make.trim()) {
      makeEmpty = "Please select make.";
      formIsValid = false;
    }
    if (!model.trim()) {
      modelEmpty = "Please select model.";
      formIsValid = false;
    }
    if (!vechile_type.trim()) {
      vechile_typeEmpty = "Please select vechile type.";
      formIsValid = false;
    }

    if (!color.trim()) {
      colorEmpty = "Please select color.";
      formIsValid = false;
    }
    if (!license_number.trim()) {
      license_numberEmpty = "Please enter license number.";
      formIsValid = false;
    }

    if (!seat.trim()) {
      seatEmpty = "Please enter seat number.";
      formIsValid = false;
    }
    if (!vin.trim()) {
      vinEmpty = "Please enter vin.";
      formIsValid = false;
    }

    if (!curennt_mileage.trim()) {
      curennt_mileageEmpty = "Please enter curennt mileage.";
      formIsValid = false;
    }

    if (!rate.trim()) {
      curennt_mileageEmpty = "Please enter vehicle rate.";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: {
        yearEmpty,
        makeEmpty,
        modelEmpty,
        vechile_typeEmpty,
        colorEmpty,
        license_numberEmpty,
        seatEmpty,
        vinEmpty,
        curennt_mileageEmpty,
        rate,
      },
    });
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      setIsSubmitLoading(true)
      const data = {
        make,
        model,
        color,
        year,
        vechile_type,
        license_number,
        images: images,
        seat,
        vin,
        fare_rate:rate,
        insurance_documents,
        permit_documents,
        curennt_mileage,
      };
      try {
        const res = await dispatch(addVehicles(data));
        if (res) {
          handleShow();
          setIsSubmitLoading(false)
          // toast.success("Vehicle Added Successfully!", {
          //   position: toast?.POSITION?.TOP_RIGHT,
          // });
        }
      } catch (err) {
        setIsSubmitLoading(false)
        if (err.response && err.response.status === 400) {
          toast.error(err?.response?.data?.messsage, {
            position: toast?.POSITION?.TOP_RIGHT,
          });
        } else {
          console.log(`error>>>> ${err}`);
        }
      }
    }
  };

  const deleteImage = (i) => {
    images.splice(i, 1);
    imagesReview.splice(i, 1);
    updateState({
      ...iState,
      images,
      imagesReview,
    });
  };

  const handleReset = () => {
    updateState({
      make: "",
      model: "",
      color: "",
      year: "",
      vechile_type: "",
      license_number: "",
      images: [],
      imagesReview: [],
      seat: "",
      vin: "",
      rate:"",
      insurance_documents: "",
      permit_documents: "",
      curennt_mileage: "",
    });
  };
  return (
    <>
      <div className="dbCon">
        <VendorMenu />
        <div className="dbRight">
          {/* <header className="dbHeader d-flex align-items-center justify-content-between">
            <div className="dbHd">Add Vehicle</div>
            <DashboardTopRight />
          </header> */}
          <div className="outerBox">
            <div className="dbSubHd mb-3">Add Vehicle Details</div>
            <ul className="addImg d-flex flex-wrap p-0">
              {imagesReview && imagesReview.length !== 0 ? (
                <>
                  {imagesReview.map((image, index) => (
                    <li key={index} className="col-12 col-sm-4">
                      <div className="upload d-flex align-items-center justify-content-center flex-column">
                        <span>
                          <img src={image} alt="" />
                          <Button
                            className="editBtn"
                            onClick={() => deleteImage(index)}
                          >
                            <RiDeleteBin5Line />
                          </Button>
                        </span>
                      </div>
                    </li>
                  ))}
                </>
              ) : (
                ""
              )}
              <li className="col-12 col-sm-4">
                <div className="upload d-flex align-items-center justify-content-center flex-column">
                  <img src={Spacer} alt="" />
                  <div className="addBtn text-center">
                    <IoAddCircleOutline className="addicon mb-2" />
                    Add Vehicle Image
                  </div>
                  <input
                    type="file"
                    className="file"
                    onChange={fileHandler}
                    onClick={(e) => (e.target.value = null)}
                    accept=".png, .jpg, .jpeg"
                    multiple
                  />
                </div>
              </li>
            </ul>
            <ul className="form d-flex flex-wrap p-0 pt-2">
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">License Plate</label>
                <input
                  type="text"
                  className="formInput"
                  name="license_number"
                  value={license_number}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors?.license_numberEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Year</label>
                <select
                  className="formSelect"
                  name="year"
                  value={year}
                  onChange={handleChange}
                >
                  <option>Select</option>
                  {vehicleYearList?.length > 0
                    ? vehicleYearList?.map((item, i) => (
                      <option value={item.id}>{item.year}</option>
                    ))
                    : ""}
                </select>
                <span style={{ color: "red" }}>
                  {errors && errors?.yearEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Make</label>
                <select
                  className="formSelect"
                  name="make"
                  value={make}
                  onChange={handleMakeChange}
                >
                  <option>Select</option>
                  {vehicleMakeList?.length > 0
                    ? vehicleMakeList?.map((item, i) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                    : ""}
                </select>
                <span style={{ color: "red" }}>
                  {errors && errors?.makeEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Model</label>
                <select
                  className="formSelect"
                  name="model"
                  value={model}
                  onChange={handleChange}
                >
                  <option>Select</option>
                  {vehicleMakeList?.length > 0
                    ? vehicleModelList?.map((item, i) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                    : ""}
                </select>
                <span style={{ color: "red" }}>
                  {errors && errors?.modelEmpty}
                </span>
              </li>
              {/* <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Trim</label>
                <select className="formSelect">
                  <option>Select</option>
                </select>
              </li> */}
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Color</label>
                <select
                  className="formSelect"
                  name="color"
                  value={color}
                  onChange={handleChange}
                >
                  <option>Select</option>
                  {vehicleColorList?.length > 0
                    ? vehicleColorList?.map((item, i) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                    : ""}
                </select>
                <span style={{ color: "red" }}>
                  {errors && errors?.colorEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Seat</label>
                <input
                  type="number"
                  className="formInput"
                  name="seat"
                  value={seat}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors?.seatEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Current Mileage</label>
                <input
                  type="text"
                  className="formInput"
                  name="curennt_mileage"
                  value={curennt_mileage}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors?.curennt_mileageEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">VIN</label>
                <input
                  type="text"
                  className="formInput"
                  name="vin"
                  value={vin}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors?.vinEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Vehicle Type</label>
                <select
                  className="formSelect"
                  name="vechile_type"
                  value={vechile_type}
                  onChange={handleChange}
                >
                  <option>Select</option>
                  {vehicleTypeList?.length > 0
                    ? vehicleTypeList?.map((item, i) => (
                      <option value={item.id}>{item.type}</option>
                    ))
                    : ""}
                </select>
                <span style={{ color: "red" }}>
                  {errors && errors?.vechile_typeEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Condition</label>
                <input type="text" className="formInput" />
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Tyre Condition</label>
                <input type="text" className="formInput" />
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Vehicle Rate</label>
                <input
                  type="number"
                  className="formInput"
                  name="rate"
                  value={rate}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors?.rateEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Insurance Document</label>
                {insurance_documents &&
                  <span
                    className="m-2"
                    onClick={clearInsurance}
                  >
                    <RiDeleteBin5Line />
                  </span>}
                {!insurance_documents &&
                  <div className="uplaodBox d-flex align-items-center">
                    <Button className="btn">
                      Upload
                      <input
                        type="file"
                        className="file"
                        onChange={insuranceFileHandler}
                        onClick={(e) => (e.target.value = null)}
                        accept=".png, .jpg, .jpeg .doc .docx"
                      />
                    </Button>
                    {InsuranceDocument !== "" && (
                      <div className="ms-2"> {InsuranceDocument}.</div>
                    )}
                  </div>
                }
                <DocumentPreview preview={insurance_preview} />
              </li>

              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">DOT #/Permit</label>
                {permit_documents &&
                  <span
                    className="m-2"
                    onClick={clearPermit}
                  >
                    <RiDeleteBin5Line />
                  </span>}
                {!permit_documents &&
                  <div className="uplaodBox d-flex align-items-center">
                    <Button className="btn">
                      Upload
                      <input
                        type="file"
                        className="file"
                        onChange={permitFileHandler}
                        onClick={(e) => (e.target.value = null)}
                        accept=".png, .jpg, .jpeg .doc .docx"
                      />
                    </Button>
                    {Permit !== "" && <div className="ms-2"> {Permit}.</div>}
                  </div>
                }
                <DocumentPreview preview={permit_preview} />

              </li>
              <li className="col-12">
                <label className="filedHd">Other Conditions</label>
                <textarea
                  rows="5"
                  cols="5"
                  className="formInput"
                  placeholder="Enter Description"
                ></textarea>
              </li>
            </ul>
            <div className="pt-4">
              <Button className="smallBtn cancel me-3" onClick={handleReset}>
                Reset
              </Button>
              <Button className="smallBtn auto" onClick={handleSubmit}>
                {isSubmitLoading ? <div class="spinner-border" role="status">
                  <span class="sr-only p-0"></span>
                </div> : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show}>
        <Button className="closePopup" onClick={handleClose}>
          <IoCloseSharp />
        </Button>
        <VehicleAdded onClose={handleClose} />
      </Modal>
    </>
  );
};
export default AddVehicle;
