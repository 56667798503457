import { combineReducers } from "redux";
import vehicle from "./reducer/vehicleReducer";
import school from "./reducer/schoolReducer";
import vendor from "./reducer/vendorReducer";
import driver from "./reducer/driverReducer";
import login from "./reducer/loginReducer";

const rootReducer = combineReducers({
  vehicle,
  school,
  vendor,
  driver,
  login
});

export default rootReducer;
