import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { forgetPassword } from "../redux/action/loginAction";

const initialState = {
  email: "",
  message: "",
  errors: {},
};

const ForgotPassword = () => {
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iState, updateState] = useState(initialState);
  const { email, message, errors } = iState;
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  let handleValidation = () => {
    let emailError = "";
    let formIsValid = true;

    if (!email) {
      emailError = "Please enter email ID.";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(email)) {
      emailError = "Email format is not valid";
      formIsValid = false;
    }
    updateState({
      ...iState,
      errors: {
        emailError,
      },
    });
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      setIsSubmitLoading(true)
      const data = { email, url: "https://aeon-app.com/reset-password" };
      try {
        const res = await dispatch(forgetPassword(data));
        if (res) {
          setIsSubmitLoading(false)

          updateState({
            ...iState,
            message: res?.message,
          });
          setVisible(!visible);

          toast.success(res.message, {
            position: toast?.POSITION?.TOP_RIGHT,
          });
          navigate("./");
        }
      } catch (err) {
        setIsSubmitLoading(false)

        if (err?.response && err?.response?.status === 400) {
          toast.error(err?.response?.data?.message, {
            position: toast?.POSITION?.TOP_RIGHT,
          });
        } else {
          console.log(`error>>>> ${err}`);
        }
      }
    }
  };

  return (
    <>
      <div className="popupBox p-3 p-md-5">
        <ToastContainer />
        <div
          id="forgotPassword"
          style={{ display: visible ? "none" : "block" }}
        >
          <h2 className="text-center fpHd mb-3">Forgot Password?</h2>
          <div className="text-center loginText fpText mb-4">
            Enter your account's associated email for a password reset link.
          </div>
          <ul className="loginForm p-0 m-0">
            <li className="mb-3">
              <input
                type="text"
                placeholder="Email Address*"
                className="formInput"
                name="email"
                value={email}
                onChange={handleChange}
              />
            </li>
            <span style={{ color: "red" }}>{errors && errors.emailError}</span>
            <li>
              <Button className="loginBtn" onClick={handleSubmit}>
                {isSubmitLoading ? <div class="spinner-border" role="status">
                  <span class="sr-only p-0"></span>
                </div> : "Submit"}
              </Button>
            </li>
          </ul>
        </div>
        <div id="LinkSent" style={{ display: visible ? "block" : "none" }}>
          <h2 className="text-center fpHd mb-3">Link Sent</h2>
          <div className="text-center loginText fpText mb-4">{message}</div>
          <div className="text-center mb-4">
            <Button className="smallBtn" onClick={() => setVisible(!visible)}>
              Change Email
            </Button>
          </div>
          <div className="text-center loginText">
            Didn't receive? <a href="#">Resend</a>
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
