import React, { useState } from "react";
import "./Login.scss";
import Logo from "../assets/images/logo.png";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { resetPassword } from "../redux/action/loginAction";

const initialState = {
  uuid: "",
  password: "",
  cpassword: "",
  errors: {},
};
const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [iState, updateState] = useState(initialState);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const { password, cpassword, errors } = iState;
  const searchParams = new URLSearchParams(location.search);

  // Get the value of the 'uuid' parameter
  const uuids = searchParams.get("uuid");
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  let handleValidation = () => {
    let passwordError = "";
    let cpasswordError = "";
    let formIsValid = true;

    if (!password) {
      passwordError = "Please enter password.";
      formIsValid = false;
    }

    if (!cpassword) {
      cpasswordError = "Please enter confirm password.";
      formIsValid = false;
    } else if (password !== cpassword) {
      cpasswordError = "Password and confirm password does not matched.";
      formIsValid = false;
    }
    updateState({
      ...iState,
      errors: {
        passwordError,
        cpasswordError,
      },
    });
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      setIsSubmitLoading(true)
      const data = { password, uuid: uuids };
      try {
        const res = await dispatch(resetPassword(data));
        if (res) {
          setIsSubmitLoading(false)
          updateState({
            ...iState,
            message: res?.message,
          });
          toast.success(res.message, {
            position: toast?.POSITION?.TOP_RIGHT,
          });
          navigate("/");
        }
      } catch (err) {
        setIsSubmitLoading(false)

        if (err?.response && err?.response?.status === 400) {
          toast.error(err?.response?.data?.message, {
            position: toast?.POSITION?.TOP_RIGHT,
          });
        } else {
          console.log(`error>>>> ${err}`);
        }
      }
    }
  };

  return (
    <>
      <div className="d-flex flex-wrap">
        <div className="loginLeft">
          <div className="loginTop d-flex align-items-center justify-content-center">
            <div className="logininner">
              <div className="loginLogo mb-4">
                <img src={Logo} alt="" />
              </div>
              <h1 className="loginHd mb-2">Reset Password</h1>
              <div className="loginText mb-4">
                Please enter your new password here
              </div>
              <ul className="loginForm p-0 m-0">
                <li className="mb-3">
                  <input
                    type="password"
                    placeholder="Password*"
                    className="formInput"
                    name="password"
                    value={password}
                    onChange={handleChange}
                  />
                </li>
                <span style={{ color: "red" }}>
                  {errors && errors.passwordError}
                </span>
                <li className="mb-3">
                  <input
                    type="password"
                    placeholder="Confirm Password*"
                    className="formInput"
                    name="cpassword"
                    value={cpassword}
                    onChange={handleChange}
                  />
                </li>
                <span style={{ color: "red" }}>
                  {errors && errors.cpasswordError}
                </span>
                <li>
                  <Button className="loginBtn" onClick={handleSubmit}>
                  {isSubmitLoading ? <div class="spinner-border" role="status">
                  <span class="sr-only p-0"></span>
                </div> : "Submit"}
                  </Button>
                </li>
              </ul>
            </div>
          </div>
          <div className="loginFooter text-center d-block d-sm-flex flex-wrap align-items-center justify-content-center">
            2024 Aeonian Technology Corporation. All rights reserved.{" "}
            <a href="#" className="ms-2">
              Privacy Policy
            </a>
          </div>
        </div>
        <div className="loginBg"></div>
      </div>
    </>
  );
};
export default ResetPassword;
