import axios from "axios"
import config from "../config/config";


export const notificationUpdate = () => {
    return new Promise((resolve, reject) =>
        axios
            .get(`${config.Url}/read-notification`, {
                headers: { Authorization: `Token ${localStorage.getItem("token")}` },
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            })
    );
}