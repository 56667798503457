import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { IoMdCheckboxOutline } from "react-icons/io";

const EditVendor2 = ({ onClose }) => {
  const handleModalClose = () => {
    onClose();
  };
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <div className="popupBox p-3 p-md-5">
        <div style={{ display: visible ? "none" : "block" }}>
          <h2 className="text-center fpHd mb-3">Edit Vendor</h2>
          <ul className="form p-0 m-0 d-flex flex-wrap">
            <li className="col-12 col-sm-6">
              <label className="filedHd">Vendor Name</label>
              <input
                type="text"
                className="formInput"
                value="Greyhound Bus"
                readOnly
              />
            </li>
            <li className="col-12 col-sm-6">
              <label className="filedHd">Vehicle License Plate</label>
              <input
                type="text"
                className="formInput"
                value="EUS 687"
                readOnly
              />
            </li>
          </ul>
          <div className="dbSubHd p-2">Route</div>
          <ul className="form p-0 m-0 d-flex flex-wrap">
            <li className="col-12 col-sm-6">
              <label className="filedHd">From</label>
              <input type="text" className="formInput" value="Greyhound Bus" />
            </li>
            <li className="col-12 col-sm-6">
              <label className="filedHd">To</label>
              <input type="text" className="formInput" value="EUS 687" />
            </li>
            <li className="col-12 col-sm-6">
              <label className="filedHd">Riders</label>
              <select className="formSelect">
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option selected>20</option>
              </select>
            </li>
            <li className="col-12 col-sm-6">
              <label className="filedHd">Status</label>
              <select className="formSelect">
                <option selected>Pickup Completed</option>
                <option>Drop Completed</option>
                <option>On Route</option>
              </select>
            </li>
            <li className="d-flex col-12">
              <div className="col-6 pe-2 pt-2">
                <Button
                  className="smallBtn full cancel"
                  onClick={handleModalClose}
                >
                  Cancel
                </Button>
              </div>
              <div className="col-6 ps-2 pt-2">
                <Button
                  className="smallBtn full"
                  onClick={() => setVisible(!visible)}
                >
                  Submit
                </Button>
              </div>
            </li>
          </ul>
        </div>
        <div style={{ display: visible ? "block" : "none" }}>
          <div className="text-center mb-3 checkIcon">
            <IoMdCheckboxOutline />
          </div>
          <div className="text-center mb-3 deleteHd">
            Vendor Details Updated!
          </div>
          <div className="text-center mb-4">
            Vendor details have been updated successfully!
          </div>
          <div className="text-center">
            <Button className="smallBtn cancel me-3" onClick={handleModalClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditVendor2;
